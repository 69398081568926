import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  LoyaltyResource,
  type LoyaltyLevel,
} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {getLevelName} from '@youtoken/ui.loyalty-miner-utils';

type AccountLevelProgressBarHeaderProps = {
  value: number;
  targetVolume?: number;
  levels: LoyaltyLevel[];
};

export const AccountLevelProgressBarHeader: React.FC<
  AccountLevelProgressBarHeaderProps & BoxProps
> = observer(({value, targetVolume, levels, ...boxProps}) => {
  const {t} = useTranslation();

  const {getProgressBarForecastLevel, currentLevel, lastLevelData, getLevel} =
    LoyaltyResource.use({});

  const subtitle = React.useMemo(() => {
    // at max level
    if (currentLevel === lastLevelData.level) {
      return t('surface.loyalty.progress_bar.max_level_reached');
    }

    if (!targetVolume) {
      return t('surface.loyalty.progress_bar.enter_amount');
    }

    const forecastLevel = getProgressBarForecastLevel(targetVolume);

    // if the user keeps their current level
    if (forecastLevel.level === currentLevel) {
      // reason: maximum level has been already reached
      if (currentLevel === lastLevelData.level) {
        return t('surface.loyalty.progress_bar.max_level_reached');
      }

      const nextLevel = getLevel(currentLevel + 1)!.level;

      // reason: insuffecient targetVolume
      return t('surface.loyalty.progress_bar.will_get_closer', {
        level: getLevelName(nextLevel),
      });
    }

    // if the user upgrades to a new level
    return t('surface.loyalty.progress_bar.will_reach', {
      level: getLevelName(forecastLevel.level),
    });
  }, [
    targetVolume,
    currentLevel,
    lastLevelData,
    getProgressBarForecastLevel,
    getLevel,
    getLevelName,
  ]);

  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_HEADER"
      alignItems="center"
      {...boxProps}
    >
      {levels.length ? (
        <Box
          testID="ACCOUNT_LEVEL_FORECAST_LEVEL_UP"
          flexDirection="row"
          alignItems="center"
        >
          <Text variant="$body-03" color="$success-01" textAlign="center">
            {subtitle}
          </Text>
          <Box flexDirection="row">
            {levels.map(({name}, index) => {
              return (
                <Icon
                  key={name}
                  name="sorting"
                  style={{
                    transform: [{rotate: '180deg'}],
                    marginLeft: index > 0 ? -4 : 0,
                  }}
                  size={12}
                  color="$success-01"
                />
              );
            })}
          </Box>
        </Box>
      ) : (
        <Text variant="$body-03" color="$text-02" textAlign="center">
          {subtitle}
        </Text>
      )}
    </Box>
  );
});
