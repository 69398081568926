import * as React from 'react';
import {G} from 'react-native-svg';
import {type HexGridItemProps} from '../../types';
import {HexUtils, useLayoutContext} from '../../../../lib';

export const HexGridItemWrap: React.FC<HexGridItemProps> = ({
  hexagon: {
    block: {id, coordinates},
  },
  children,
}) => {
  const {layout} = useLayoutContext();

  const {x, y} = React.useMemo(() => {
    return HexUtils.hexToPixel(coordinates, layout);
  }, [coordinates, layout]);

  return (
    <G id={id} transform={`translate(${x}, ${y})`}>
      {children}
    </G>
  );
};
