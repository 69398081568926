import * as React from 'react';
import {Svg, G, Use} from 'react-native-svg';
import {cell} from '@youtoken/ui.cell';
import {MinerHexagon} from '@youtoken/ui.resources-miner';
import {Box, type BoxProps, useResponsive} from '@youtoken/ui.primitives';
import {Layout} from './lib';
import {MinerOverviewResourceContext} from '../../context';
import {HexGridItem} from './components';

export const HexGrid: React.FC<BoxProps> = cell(() => {
  const {
    hexagonalGrid: {hexagons, foreground, clear},
  } = React.useContext(MinerOverviewResourceContext).use({});

  const hexagonRadius = useResponsive({
    default: 31,
    phone: 36,
    tablet: 41,
  })!;
  const hexagonSize = {x: hexagonRadius, y: hexagonRadius};

  const hexagonalGridSpacing = 1.05;
  const hexagonalGridSize = hexagonRadius * 10;
  const hexagonalGridOrigin = {
    x: hexagonalGridSize / 2,
    y: hexagonalGridSize / 2,
  };

  const renderHex = React.useCallback((hexagon: MinerHexagon) => {
    return <HexGridItem key={hexagon.block.id} hexagon={hexagon} />;
  }, []);

  const renderForeground = React.useCallback((id: string) => {
    return <Use key={id} xlinkHref={`#${id}`} />;
  }, []);

  return (
    <Box testID="HEXAGONS">
      <Layout
        flat={false}
        // the size of underlying hexagons which are used to calculate the layout
        size={hexagonSize}
        spacing={hexagonalGridSpacing}
        origin={hexagonalGridOrigin}
      >
        <Svg
          width={hexagonalGridSize}
          height={hexagonalGridSize}
          viewBox={`0 0 ${hexagonalGridSize} ${hexagonalGridSize}`}
        >
          <G>{hexagons.map(renderHex)}</G>
          <G>{foreground.map(renderForeground)}</G>
        </Svg>
      </Layout>
    </Box>
  );
});
