import * as React from 'react';
import Svg, {Path, Defs, LinearGradient, Stop} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const LaptopIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <Path
          fill="#425266"
          d="M0 52h64a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2M4 17a6 6 0 0 1 6-6h44a6 6 0 0 1 6 6v34H4z"
        />
        <Path fill="url(#a)" d="M58 49V17a4 4 0 0 0-4-4H10a4 4 0 0 0-4 4v32z" />
        <Defs>
          <LinearGradient
            id="a"
            x1={32}
            x2={32}
            y1={11.667}
            y2={50.333}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6B7A94" />
            <Stop offset={0} stopColor="#6B7A94" />
            <Stop offset={1} stopColor="#353E4F" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
