import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FacebookIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          fill={color}
          fillRule="evenodd"
          d="M6.255 10.522h1.736v7.22c0 .142.115.258.256.258h2.943a.257.257 0 0 0 .256-.258v-7.186h1.995c.13 0 .24-.099.254-.229l.303-2.658a.26.26 0 0 0-.063-.201.25.25 0 0 0-.19-.087h-2.3V5.715c0-.502.269-.757.797-.757h1.503A.257.257 0 0 0 14 4.7V2.26a.257.257 0 0 0-.255-.258h-2.072L11.578 2c-.359 0-1.608.071-2.595.988-1.093 1.017-.941 2.234-.905 2.445V7.38H6.255A.257.257 0 0 0 6 7.64v2.624c0 .143.114.259.255.259z"
        />
      </Svg>
    );
  }
);
