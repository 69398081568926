import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TelegramIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          fill={color}
          d="m2.283 10.692 3.686 1.482 1.427 4.942c.092.316.451.433.69.223l2.055-1.804a.58.58 0 0 1 .747-.022l3.707 2.897c.255.2.616.05.68-.283l2.716-14.063c.07-.363-.262-.665-.582-.532L2.279 9.817c-.374.155-.37.724.004.875m4.883.693 7.206-4.778c.13-.086.262.103.151.214l-5.946 5.952c-.21.21-.344.49-.382.794l-.203 1.616c-.027.216-.308.237-.364.029l-.779-2.948a.81.81 0 0 1 .317-.879"
        />
      </Svg>
    );
  }
);
