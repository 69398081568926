import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Web3Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Circle cx={10} cy={10} r={7.25} stroke={color} strokeWidth={1.5} />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M15.252 4.9a2 2 0 0 0-.14-.284c-.25-.435-.632-.768-1.112-1.001 2.69-.619 4.806-.362 5.526.885 1.224 2.12-2.048 6.302-7.31 9.34C6.957 16.876 1.698 17.62.473 15.5c-.752-1.304.195-3.387 2.26-5.505-.065.582.027 1.126.298 1.596q.054.091.115.178-.14.165-.269.33c-.59.755-.933 1.403-1.079 1.897-.144.49-.061.692-.025.754s.169.234.665.354c.501.122 1.233.15 2.183.015 1.892-.268 4.339-1.131 6.845-2.579 2.507-1.447 4.479-3.134 5.656-4.639.591-.755.934-1.403 1.08-1.897.143-.49.06-.692.025-.754s-.17-.234-.666-.354c-.5-.122-1.232-.15-2.183-.015z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
