import {sampleSize, range} from 'lodash';

const AVAILABLE_BLOCKS_COUNT = 7;
const AVAILABLE_BLOCKS_MINING_PRICE = 3;

const COORDINATES = [
  {
    q: 0,
    r: 0,
    s: 0,
  },
  {
    q: 0,
    r: 1,
    s: -1,
  },
  {
    q: 0,
    r: -1,
    s: 1,
  },
  {
    q: 0,
    r: 2,
    s: -2,
  },
  {
    q: 0,
    r: -2,
    s: 2,
  },
  {
    q: 1,
    r: 0,
    s: -1,
  },
  {
    q: 1,
    r: -1,
    s: 0,
  },
  {
    q: -1,
    r: 0,
    s: 1,
  },
  {
    q: -1,
    r: 1,
    s: 0,
  },
  {
    q: 1,
    r: 1,
    s: -2,
  },
  {
    q: 1,
    r: -2,
    s: 1,
  },
  {
    q: -1,
    r: -1,
    s: 2,
  },
  {
    q: -1,
    r: 2,
    s: -1,
  },
  {
    q: 2,
    r: 0,
    s: -2,
  },
  {
    q: 2,
    r: -2,
    s: 0,
  },
  {
    q: 2,
    r: -1,
    s: -1,
  },
  {
    q: -2,
    r: 0,
    s: 2,
  },
  {
    q: -2,
    r: 2,
    s: 0,
  },
  {
    q: -2,
    r: 1,
    s: 1,
  },
];

const AVAILABLE_BLOCKS_INDEXES = sampleSize(
  range(0, COORDINATES.length, 1),
  AVAILABLE_BLOCKS_COUNT
);

export const generateBlocks = () => {
  return COORDINATES.map((coordinates, index) => {
    if (
      AVAILABLE_BLOCKS_INDEXES.some(
        availableBlockIndex => availableBlockIndex === index
      )
    ) {
      return {
        status: 'AVAILABLE',
        miningPrice: AVAILABLE_BLOCKS_MINING_PRICE,
        coordinates,
      };
    }

    return {
      status: 'DISABLED',
      coordinates,
    };
  });
};
