import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const BoostIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M16.149 9c.47 0 .851.448.851 1v6c0 .552-.381 1-.851 1H13.85c-.47 0-.851-.448-.851-1v-6c0-.552.381-1 .851-1zm-10 5c.47 0 .851.448.851 1v1c0 .552-.381 1-.851 1H3.85C3.381 17 3 16.552 3 16v-1c0-.552.381-1 .851-1zm5-2c.47 0 .851.448.851 1v3c0 .552-.381 1-.851 1H8.85C8.381 17 8 16.552 8 16v-3c0-.552.381-1 .851-1zM16.5 3a.5.5 0 0 1 .5.5v3.793a.5.5 0 0 1-.854.353L14.7 6.2C12.11 9.212 6.994 11.75 3.5 11.75a.75.75 0 0 1 0-1.5c3.093 0 7.884-2.416 10.138-5.114l-1.284-1.282A.5.5 0 0 1 12.707 3z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
