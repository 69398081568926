import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const GoogleIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 21 20"
        {...props}
      >
        <G clipPath="url(#a)">
          <Path
            fill="#4285F4"
            d="M18.833 10.191c0-.566-.046-1.136-.146-1.693h-8.02v3.21h4.592a3.85 3.85 0 0 1-1.699 2.531v2.083h2.74c1.609-1.452 2.533-3.595 2.533-6.13"
          />
          <Path
            fill="#34A853"
            d="M10.667 18.333c2.293 0 4.227-.738 5.636-2.011l-2.74-2.083c-.762.509-1.746.797-2.893.797-2.218 0-4.099-1.467-4.773-3.44H3.069v2.147c1.444 2.814 4.383 4.59 7.598 4.59"
          />
          <Path
            fill="#FBBC04"
            d="M5.894 11.597a4.9 4.9 0 0 1 0-3.19V6.26H3.069a8.2 8.2 0 0 0 0 7.483z"
          />
          <Path
            fill="#EA4335"
            d="M10.667 4.964a4.67 4.67 0 0 1 3.262 1.25l2.427-2.38a8.26 8.26 0 0 0-5.689-2.167c-3.215 0-6.154 1.776-7.598 4.593l2.825 2.146c.671-1.975 2.555-3.442 4.773-3.442"
          />
        </G>
        <Defs>
          <ClipPath id="a">
            <Path fill="#fff" d="M2.167 1.667h16.667v16.667H2.167z" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
);
