import * as React from 'react';
import {observer} from 'mobx-react';
import {type HexGridItemProps} from '../../../../types';
import {useHexGridItemStateClaimingStartingAnimation} from './hooks';
import {
  AnimatedGroup,
  FilledGroup,
  Background,
  Border,
  Amount,
} from '../../elements';

export const HexGridItemStateClaimingAnimated: React.FC<HexGridItemProps> =
  observer(({hexagon}) => {
    const {
      block: {earnAmountFormatted, earnAmountTickerFormatted},
    } = hexagon;

    return (
      <AnimatedGroup
        testID="HEX_GRID_ITEM_STATE_CLAIMING"
        animatedProps={useHexGridItemStateClaimingStartingAnimation(hexagon)}
      >
        <FilledGroup>
          <Background color="$attention-01" />
          <Border color="$ui-background" />
        </FilledGroup>
        <Amount
          color="$text-04"
          amountFormatted={earnAmountFormatted}
          tickerFormatted={earnAmountTickerFormatted}
        />
      </AnimatedGroup>
    );
  });
