import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {AmountButton} from './AmountButton';
import {type PredefinedAmountButtonData} from '../getPredefinedAmounts';

interface PredefinedAmountButtonsProps {
  data: PredefinedAmountButtonData[];
  currentAmount: string;
  buttonsSize?: 'large' | 'medium' | 'small' | 'tiny';
  onPressAmount: (amount: string, index?: number) => void;
}

export const PredefinedAmountButtons: React.FC<
  PredefinedAmountButtonsProps & BoxProps
> = observer(
  ({data, currentAmount, onPressAmount, buttonsSize, ...boxProps}) => {
    const handlePress = (label: string) => {
      const item = data.find(item => item.label === label);
      onPressAmount(item!.amountEquivalent);
    };

    return (
      <Box flexDirection="row" flexGrow={1} gap={8} {...boxProps}>
        {data.map(({label, amountEquivalent}, index) => {
          return (
            <AmountButton
              key={label}
              index={index}
              label={label}
              onPress={handlePress}
              type={
                Number(currentAmount) === Number(amountEquivalent)
                  ? 'primary'
                  : 'secondary'
              }
              size={buttonsSize}
              testID={`PREDEFINED_AMOUNT_BUTTON_${label}`}
            />
          );
        })}
      </Box>
    );
  }
);
