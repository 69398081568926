import * as React from 'react';
import {observer} from 'mobx-react';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Clipboard} from '@youtoken/ui.utils';
import {Icon} from '@youtoken/ui.icons';
import {UmaInfoFeature} from '../../feature';

export const UmaInfo: React.FC = observer(() => {
  const {t} = useTranslation();
  const {umaFormatted} = UmaInfoFeature.use({});

  const handleCopyIconPress = React.useCallback(() => {
    Clipboard.setString(umaFormatted);
    LOCAL_NOTIFICATIONS.info({
      text: t('surface.bank_cards.deposit.external.detail_copied_message', {
        text: t('surface.wallets.uma.edit.current_uma.label'),
      }),
    });
  }, [umaFormatted, t]);

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      p={24}
    >
      <Box testID="UMA_SETTINGS_ADDRESS_INFO">
        <Text color="$text-02" variant="$body-02" mb={4}>
          {t('surface.wallets.uma.edit.current_uma.label')}
        </Text>
        <Text>{umaFormatted}</Text>
      </Box>
      <TouchableBox onPress={handleCopyIconPress}>
        <Icon name="copy" color="$interactive-01" size={24} />
      </TouchableBox>
    </Box>
  );
});
