import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const RemoveIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 12 12"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2.97 2.97a.75.75 0 0 1 .976-.073l.084.073L6 4.939l1.97-1.97a.75.75 0 0 1 1.133.977l-.073.084L7.061 6l1.97 1.97.072.084a.75.75 0 0 1-1.05 1.049L7.97 9.03 6 7.061l-1.97 1.97a.75.75 0 0 1-1.133-.977l.073-.084L4.939 6l-1.97-1.97-.072-.084a.75.75 0 0 1 .073-.976"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
