import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level7GrayscaleIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#B4BECA"
          d="M5.333 3.933a1 1 0 0 0-.557.322L2.068 7.351a1 1 0 0 0-.01 1.306l7.18 8.447a1 1 0 0 0 1.524 0l7.18-8.447a1 1 0 0 0-.01-1.306l-2.708-3.096a1 1 0 0 0-.556-.322l-4.276-.855a2 2 0 0 0-.784 0z"
        />
        <Path
          fill="#CBD2DA"
          d="M9.813 17.438 7 9l-5.178-.942a1 1 0 0 0 .236.599l7.18 8.447c.155.182.36.294.575.334"
        />
        <Path
          fill="#8E9FB3"
          d="M18.178 8.058 13 9l-2.813 8.438a1 1 0 0 0 .575-.334l7.18-8.447a1 1 0 0 0 .236-.599"
        />
        <Path fill="#D9DEE3" d="m7 9 3-4 3 4z" />
        <Path
          fill="#CBD2DA"
          d="M14.979 4.053 13 9l5.178-.942a1 1 0 0 0-.246-.707l-2.708-3.096a1 1 0 0 0-.245-.202"
        />
        <Path
          fill="#D9DEE3"
          d="m2.068 7.351 2.708-3.096a1 1 0 0 1 .245-.202L7 9l-5.178-.942a1 1 0 0 1 .246-.707M5.333 3.933a1 1 0 0 0-.247.084L10 5l4.914-.983a1 1 0 0 0-.246-.083l-4.276-.856a2 2 0 0 0-.784 0z"
        />
        <Path
          fill="#8E9FB3"
          d="m3.157 5.03.153-.46a.2.2 0 0 1 .38 0l.153.46a.2.2 0 0 0 .127.127l.46.153a.2.2 0 0 1 0 .38l-.46.153a.2.2 0 0 0-.127.127l-.153.46a.2.2 0 0 1-.38 0l-.153-.46a.2.2 0 0 0-.127-.127l-.46-.153a.2.2 0 0 1 0-.38l.46-.153a.2.2 0 0 0 .127-.127"
        />
        <Path
          fill="#D9DEE3"
          d="m12.657 10.53.153-.46a.2.2 0 0 1 .38 0l.153.46a.2.2 0 0 0 .127.127l.46.153a.2.2 0 0 1 0 .38l-.46.153a.2.2 0 0 0-.127.127l-.153.46a.2.2 0 0 1-.38 0l-.153-.46a.2.2 0 0 0-.127-.127l-.46-.153a.2.2 0 0 1 0-.38l.46-.153a.2.2 0 0 0 .127-.127"
        />
        <Path
          fill="#8E9FB3"
          d="m15.532 2.405.278-.836a.2.2 0 0 1 .38 0l.278.836a.2.2 0 0 0 .127.127l.836.278a.2.2 0 0 1 0 .38l-.836.278a.2.2 0 0 0-.127.127l-.278.836a.2.2 0 0 1-.38 0l-.278-.836a.2.2 0 0 0-.127-.127l-.836-.278a.2.2 0 0 1 0-.38l.836-.278a.2.2 0 0 0 .127-.127"
        />
      </Svg>
    );
  }
);
