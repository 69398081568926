import {action, observable} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {
  type ProposedAction,
  type MinerOverviewArgs,
  MinerOverviewResponse,
} from './MinerOverviewResponse';
import {MinerOverviewHexagonalGridAuthorized} from './HexagonalGrid';
import Big from 'big.js';
import {MinerBlock} from './MinerBlock';

const mergeBlocks = (next: MinerBlock[], prev: MinerBlock[]) => {
  return prev.map(prevBlock => {
    const nextBlock = next.find(nextBlock => {
      return nextBlock.coordinatesHash === prevBlock.coordinatesHash;
    });

    if (nextBlock) {
      const hasUpdatedStatus =
        (nextBlock.updatedAt ?? 0) > (prevBlock.updatedAt ?? 0);

      const hasUpdatedTimeLeft = nextBlock.timeLeft < prevBlock.timeLeft;

      const hasUpdated = hasUpdatedStatus || hasUpdatedTimeLeft;

      const hasRemoved = nextBlock.id !== prevBlock.id;

      if (hasUpdated || hasRemoved) {
        return nextBlock;
      }
    }

    return prevBlock;
  });
};

export class MinerOverviewResource extends createResource<
  MinerOverviewArgs,
  MinerOverviewResponse
>({
  getKey: _args => 'minerOverview',
  getData: _args => {
    return TRANSPORT.API.get('/v1/miner/overview').then(response => {
      const prev = MinerOverviewResource.getInstanceSafely(_args)?.data;
      const next = deserialize(MinerOverviewResponse, response.data);

      if (prev) {
        next.blocks = mergeBlocks(next.blocks, prev.blocks);
      }

      return next;
    });
  },
  skipRefreshOnVisible: false,
  cacheTime: 0,
}) {
  @observable
  public hexagonalGrid!: MinerOverviewHexagonalGridAuthorized;

  @action
  public updateData = (response: MinerOverviewResponse) => {
    const data = deserialize(MinerOverviewResponse, response);

    data.blocks = mergeBlocks(data.blocks, this.data.blocks);

    this.data = data;
  };

  constructor(args: MinerOverviewArgs, data: MinerOverviewResponse) {
    super(args, data);

    this.hexagonalGrid = new MinerOverviewHexagonalGridAuthorized(this);

    this.subscribeToSocket();
  }

  onDestroy() {
    super.onDestroy();

    this.unsubscribeToSocket();
  }

  // PRIVATE SECTION

  @action subscribeToSocket = () => {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'miner',
    });
    TRANSPORT.SOCKET.on('minerSparkBalance', this.reactToMinerSparkBalance);
    TRANSPORT.SOCKET.on('minerBlocks', this.reactToMinerBlocks);
    TRANSPORT.SOCKET.on(
      'minerTransactionBalance',
      this.reactToTransactionBalance
    );
    TRANSPORT.SOCKET.on('minerProposedAction', this.reactToProposedAction);
  };

  @action unsubscribeToSocket = () => {
    TRANSPORT.SOCKET.emit('unsub', {
      name: 'miner',
    });
    TRANSPORT.SOCKET.off('minerSparkBalance', this.reactToMinerSparkBalance);
    TRANSPORT.SOCKET.off('minerBlocks', this.reactToMinerBlocks);
    TRANSPORT.SOCKET.off(
      'minerTransactionBalance',
      this.reactToTransactionBalance
    );
    TRANSPORT.SOCKET.off('minerProposedAction', this.reactToProposedAction);
  };

  @action reactToMinerSparkBalance = ({amount}: {amount: string}) => {
    this.data.sparkBalance = Number(amount);
  };

  @action reactToTransactionBalance = ({
    amount,
    amountUSD,
  }: {
    amount: string;
    amountUSD: string;
  }) => {
    this.data.totalMinedAmount = new Big(amount);
    this.data.totalMinedAmountUSD = new Big(amountUSD);
  };

  @action reactToProposedAction = ({
    proposedAction,
  }: {
    proposedAction: ProposedAction;
  }) => {
    this.data.proposedAction = proposedAction;
  };

  @action reactToMinerBlocks = (response: {blocks: MinerBlock[]}) => {
    const blocks = deserialize(MinerBlock, response.blocks);

    this.data.blocks = mergeBlocks(blocks, this.data.blocks);
  };
}
