import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level5Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <G clipPath="url(#a)">
          <Path
            fill="#FFCF39"
            d="M2.53 16a1 1 0 0 1-.97-1.242l1-4A1 1 0 0 1 3.53 10h4.44a1 1 0 0 1 .97.758l1 4A1 1 0 0 1 8.97 16z"
          />
          <Path
            fill="#F0A937"
            d="M6.75 15a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h.61a.5.5 0 0 1 .485.379l.75 3A.5.5 0 0 1 8.11 15z"
          />
          <Path
            fill="#FFCF39"
            d="M6.78 10a1 1 0 0 1-.97-1.243l1-4A1 1 0 0 1 7.78 4h4.44a1 1 0 0 1 .97.757l1 4A1 1 0 0 1 13.22 10z"
          />
          <Path
            fill="#F0A937"
            d="M11 9a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 11 5h.61a.5.5 0 0 1 .485.379l.75 3A.5.5 0 0 1 12.36 9z"
          />
          <Path
            fill="#FFCF39"
            d="M11.03 16a1 1 0 0 1-.97-1.242l1-4a1 1 0 0 1 .97-.758h4.44a1 1 0 0 1 .97.758l1 4A1 1 0 0 1 17.47 16z"
          />
          <Path
            fill="#F0A937"
            d="M15.25 15a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h.61a.5.5 0 0 1 .485.379l.75 3a.5.5 0 0 1-.485.621zM4.157 7.03l.153-.46a.2.2 0 0 1 .38 0l.153.46a.2.2 0 0 0 .127.127l.46.153a.2.2 0 0 1 0 .38l-.46.153a.2.2 0 0 0-.127.127l-.153.46a.2.2 0 0 1-.38 0l-.153-.46a.2.2 0 0 0-.127-.127l-.46-.153a.2.2 0 0 1 0-.38l.46-.153a.2.2 0 0 0 .127-.127M14.532 3.405l.278-.836a.2.2 0 0 1 .38 0l.278.836a.2.2 0 0 0 .127.127l.836.278a.2.2 0 0 1 0 .38l-.836.278a.2.2 0 0 0-.127.127l-.278.836a.2.2 0 0 1-.38 0l-.278-.836a.2.2 0 0 0-.127-.127l-.836-.278a.2.2 0 0 1 0-.38l.836-.278a.2.2 0 0 0 .127-.127"
          />
        </G>
        <Defs>
          <ClipPath id="a">
            <Path fill="#fff" d="M0 0h20v20H0z" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
);
