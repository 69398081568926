import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const BlockexplorerIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16m-.75 8.75-2.726.001c.184 2.805 1.379 4.976 2.727 5.58zm4.226.001-2.726-.001v5.58c1.348-.604 2.542-2.775 2.726-5.579m-8.454 0h-1.48a6.5 6.5 0 0 0 2.725 4.571c-.69-1.238-1.144-2.823-1.245-4.571m11.435 0h-1.479c-.101 1.748-.554 3.333-1.245 4.571a6.5 6.5 0 0 0 2.724-4.572M6.267 4.677l-.035.025a6.5 6.5 0 0 0-2.69 4.548h1.48c.101-1.749.554-3.334 1.245-4.573m.257 4.572H9.25V3.669c-1.347.604-2.542 2.776-2.726 5.58m4.226-5.58v5.58h2.726c-.184-2.804-1.378-4.976-2.726-5.58m2.983 1.008.08.146c.645 1.216 1.068 2.746 1.165 4.427h1.48a6.5 6.5 0 0 0-2.725-4.573"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
