import * as React from 'react';
import {
  Animated,
  Easing,
  type GestureResponderEvent,
  Platform,
  type TextProps as NativeTextProps,
} from 'react-native';
import {TextProps, createText} from '../Text';

const AnimatedText = createText(Animated.Text);

export const TouchableText: React.FC<
  TextProps & {
    onPress?: (event: GestureResponderEvent) => void;
    underlined?: boolean;
  } & Pick<NativeTextProps, 'accessibilityRole' | 'testID'>
> = ({onPress, underlined, children, ...props}) => {
  const [hover, setHover] = React.useState(false);

  const animated = React.useRef(new Animated.Value(1));
  const style = React.useMemo(() => {
    return {
      opacity: animated.current,
      ...Platform.select({
        web: {
          userSelect: 'none',
          textDecoration: underlined ? 'underline' : 'none',
        },
        default: {
          textDecorationLine: underlined ? 'underline' : 'none',
        },
      }),
    };
  }, [underlined, hover]);

  const handlePress = React.useCallback(
    (event: GestureResponderEvent) => {
      setHover(true);
      onPress?.(event);

      Animated.sequence([
        Animated.timing(animated.current, {
          toValue: 0.6,
          duration: 100,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(animated.current, {
          toValue: 1,
          duration: 100,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ]).start();
    },
    [onPress, setHover]
  );

  return (
    <AnimatedText
      accessible
      // @ts-ignore not understanding 'underline' | 'none' in style memo
      style={style}
      onPress={handlePress}
      suppressHighlighting
      accessibilityRole="button"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    >
      {children}
    </AnimatedText>
  );
};
