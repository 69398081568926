import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const OsWindowsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M10 9.467v-6.4L18 2v7.467zM2 9.467V4.133L9 3.2v6.267zM10 10.533v6.4L18 18v-7.467zM2 15.867v-5.334h7V16.8z"
        />
      </Svg>
    );
  }
);
