import * as React from 'react';
import {observer} from 'mobx-react';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {TextInput} from '@youtoken/ui.inputs';
import {UmaWithdrawalForm} from '../../form';

export const AmountInputs: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    form: {setSendAmount, setGetAmount, sendAmount, getAmount, isUmaValid},
    view: {
      sendAmountError,
      getAmountError,
      tickerFormatted,
      receiveTickerFormatted,
      minReceiveAmountFormatted,
      maxReceiveAmountFormatted,
      minSendAmountFormatted,
      maxSendAmountFormatted,
    },
  } = useForm<UmaWithdrawalForm>();

  return (
    <Box
      flexDirection={{default: 'column', tablet: 'row'}}
      mb={{default: 0, tablet: 24}}
      {...boxProps}
    >
      <Box
        mr={{default: 0, tablet: 8}}
        mb={{default: 24, tablet: 0}}
        flex={1}
        flexGrow={1}
      >
        <Label mr={4} mb={8} lineHeight={20}>
          {t('surface.wallets.uma.withdrawal.send_input.title')}
        </Label>
        <TextInput
          disabled={!isUmaValid}
          value={sendAmount}
          onChangeText={setSendAmount}
          placeholder="0.00"
          keyboardType="decimal-pad"
          RightPartComponent={
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              ml={3}
              pr={15}
            >
              <Text color="$text-02">{tickerFormatted}</Text>
            </Box>
          }
          hasError={Boolean(sendAmountError)}
          testID="UMA_WITHDRAW_SEND_AMOUNT_INPUT"
        />
        <FieldErrorMessage visible={Boolean(sendAmountError)}>
          {sendAmountError}
        </FieldErrorMessage>
        {Boolean(
          minSendAmountFormatted && maxSendAmountFormatted && tickerFormatted
        ) && (
          <Text
            color="$text-02"
            variant="$body-02"
            mt={8}
            testID="UMA_WITHDRAW_SEND_AMOUNT_INPUT_REQUIREMENTS"
          >
            {t('surface.wallets.uma.withdrawal.amount_input.requirements', {
              minAmount: minSendAmountFormatted,
              maxAmount: maxSendAmountFormatted,
              ticker: tickerFormatted,
            })}
          </Text>
        )}
      </Box>
      <Box
        ml={{default: 0, tablet: 8}}
        mb={{default: 24, tablet: 0}}
        flex={1}
        flexGrow={1}
      >
        <Box flexDirection="row" alignItems="center" mb={8}>
          <Label mr={4} lineHeight={20}>
            {t('surface.wallets.uma.withdrawal.get_input.title')}
          </Label>
          <QuestionTooltip
            content={t('surface.wallets.uma.withdrawal.get_input.tooltip')}
            bodyHorizontalOffset={{default: 55, tablet: 0}}
          />
        </Box>
        <TextInput
          disabled={!isUmaValid}
          value={getAmount}
          onChangeText={setGetAmount}
          placeholder="0.00"
          keyboardType="decimal-pad"
          RightPartComponent={
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              ml={3}
              pr={15}
            >
              <Text color="$text-02">{receiveTickerFormatted}</Text>
            </Box>
          }
          hasError={Boolean(getAmountError)}
          testID="UMA_WITHDRAW_GET_AMOUNT_INPUT"
        />
        <FieldErrorMessage visible={Boolean(getAmountError)}>
          {getAmountError}
        </FieldErrorMessage>
        {Boolean(
          minReceiveAmountFormatted &&
            maxReceiveAmountFormatted &&
            receiveTickerFormatted
        ) && (
          <Text
            color="$text-02"
            variant="$body-02"
            mt={8}
            testID="UMA_WITHDRAW_GET_AMOUNT_INPUT_REQUIREMENTS"
          >
            {t('surface.wallets.uma.withdrawal.amount_input.requirements', {
              minAmount: minReceiveAmountFormatted,
              maxAmount: maxReceiveAmountFormatted,
              ticker: receiveTickerFormatted,
            })}
          </Text>
        )}
      </Box>
    </Box>
  );
});
