import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const IdCardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M11.75 7a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5zM12 10.75a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M9 8.5c0 .76-.34 1.441-.875 1.9A3.5 3.5 0 0 1 10 13.5a.75.75 0 0 1-1.5 0 2 2 0 0 0-4 0 .75.75 0 0 1-1.5 0 3.5 3.5 0 0 1 1.875-3.1A2.5 2.5 0 1 1 9 8.5m-2.5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M0 5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-.5h16a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V5a.5.5 0 0 1 .5-.5"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
