import * as React from 'react';
import {
  withRepeat,
  withSequence,
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

export const useHexGridItemStateScaleAnimation = (value: number) => {
  const scale = useSharedValue(1);

  React.useEffect(() => {
    scale.value = withRepeat(
      withSequence(
        withTiming(value, {
          duration: 600,
        }),
        withTiming(1, {
          duration: 600,
        })
      ),
      Infinity
    );
  }, [value]);

  return useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: scale.value,
        },
      ],
    };
  });
};
