import * as React from 'react';
import {Platform} from 'react-native';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {
  type OnboardingStep,
  OnboardingStepPerksResource,
} from '@youtoken/ui.resource-onboarding-step-perks';
import {cell} from '@youtoken/ui.cell';
import {PerksSection} from './components';
import {ActivityIndicator} from '@youtoken/ui.elements';

type OnboardingStepPerksSurfaceProps = {
  steps: OnboardingStep[];
  footerButtonLabel?: string;
  onFooterButtonPress?: () => void;
};

export const OnboardingStepPerksSurface: React.FC<
  OnboardingStepPerksSurfaceProps & BoxProps
> = cell(
  ({steps, footerButtonLabel, onFooterButtonPress, ...boxProps}) => {
    const {
      data: {verificationPass, firstDeposit, firstTrade},
      refetch,
    } = OnboardingStepPerksResource.use({steps});

    React.useEffect(() => {
      refetch();
    }, []);

    return (
      <Box gap={24} {...boxProps}>
        {verificationPass && (
          <PerksSection variant="verification" items={verificationPass} />
        )}
        {firstDeposit && (
          <PerksSection variant="deposit" items={firstDeposit} />
        )}
        {firstTrade && <PerksSection variant="trade" items={firstTrade} />}

        {footerButtonLabel && onFooterButtonPress && (
          <Button
            type="primary"
            size="large"
            onPress={onFooterButtonPress}
            testID="FIRST_ACTION_PERKS_FOOTER_BUTTON"
          >
            {footerButtonLabel}
          </Button>
        )}
      </Box>
    );
  },
  {
    SuspenseFallback: () => (
      <Box
        flex={1}
        justifyContent="center"
        height={Platform.OS !== 'web' ? 400 : undefined}
      >
        <ActivityIndicator />
      </Box>
    ),
  }
);
