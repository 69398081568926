import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Timer} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const NextFreeSparkIn: React.FC = observer(() => {
  const {
    hexagonalGrid: {timeLeftUntilNextFreeSparksDrop},
    refetch,
  } = MinerOverviewResource.use({});

  const {
    currentLevelData: {
      miner: {freeSparksAmount},
    },
  } = LoyaltyResource.use({});

  const handleTimerEnd = React.useCallback(() => {
    refetch();
  }, [refetch]);

  const {t} = useTranslation();

  if (!timeLeftUntilNextFreeSparksDrop || !freeSparksAmount) {
    return null;
  }

  return (
    <Box
      testID="TIME_COUNTER_FIELD"
      flexDirection="row"
      alignItems="center"
      mt={8}
    >
      <Text variant="$body-01" color="$text-02" mr={4}>
        {t('surface.miner.overview.next')}
      </Text>
      <Box width={8} height={16} justifyContent="center" alignItems="center">
        <Icon name="spark" size={16} color="$text-02" />
      </Box>
      <Text variant="$body-01-high-accent" color="$text-02" ml={2}>
        {freeSparksAmount}
      </Text>
      <Text variant="$body-01" color="$text-02" ml={2}>
        {t('surface.miner.overview.next_in')}
      </Text>
      <Timer
        variant="$body-01"
        color="$text-02"
        timeLeft={timeLeftUntilNextFreeSparksDrop}
        onTimerEnd={handleTimerEnd}
        ml={4}
      />
    </Box>
  );
});
