import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Label} from '@youtoken/ui.form-elements';

export interface SliderLabelProps {
  text: string;
  secondaryText?: string;
  value: number;
}

export const SliderLabel: React.FC<SliderLabelProps> = ({
  text,
  secondaryText,
  value,
}) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Label>{text}</Label>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={12}
      >
        {secondaryText && (
          <Text variant="$body-01" color="$text-03">
            {secondaryText}
          </Text>
        )}
        <Text variant="$body-01" color="$text-02">
          x{value}
        </Text>
      </Box>
    </Box>
  );
};
