import Big from 'big.js';
import {computed, observable} from 'mobx';
import {serializable, alias, primitive, object, date} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';

export type MinerBlockStatus =
  | 'DISABLED'
  | 'AVAILABLE'
  | 'MINING'
  | 'READY'
  | 'CLAIMED';

export type MinerBlockState =
  | 'INSUFFICIENT_SPARKS'
  | 'MINING_STARTING'
  | 'CLAIMING_STARTING'
  | 'CLAIMING'
  | 'CLAIMING_ENDING'
  | 'CLAIMED_INFO'
  | MinerBlockStatus;

export class MinerBlockCoords {
  @serializable(primitive())
  @observable
  q!: number;

  @serializable(primitive())
  @observable
  r!: number;

  @serializable(primitive())
  @observable
  s!: number;
}

export class MinerBlock {
  @serializable(primitive())
  @observable
  status!: MinerBlockStatus;

  @serializable(object(MinerBlockCoords))
  @observable
  coordinates!: MinerBlockCoords;

  @computed
  get coordinatesHash(): string {
    return getCoordinatesHash(this.coordinates);
  }

  @serializable(alias('id', primitive()))
  @observable
  _id?: string;

  @computed
  get id(): string {
    return this._id ?? this.coordinatesHash;
  }

  @serializable(primitive())
  @observable
  timeLeft: number = 0;

  @serializable(primitive())
  @observable
  miningPrice: number = 0;

  @computed get miningPriceFormatted() {
    return this.miningPrice.toString();
  }

  @serializable(bigNumber())
  @observable
  earnAmount: Big = Big(0);

  @computed get earnAmountFormatted() {
    return formatByTicker(this.earnAmount, this.earnAmountTicker);
  }

  @serializable(primitive())
  @observable
  earnAmountTicker: string = 'btc';

  @computed get earnAmountTickerFormatted() {
    return this.earnAmountTicker?.toUpperCase();
  }

  @serializable(date())
  @observable
  updatedAt?: Date;
}

const getCoordinatesHash = ({q, r, s}: MinerBlockCoords) => {
  return `${q}${r}${s}`;
};
