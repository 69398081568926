import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const MoneyIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M9.25 2.758a3.763 3.763 0 0 0-1.227 7.21l3.363 1.442a2.26 2.26 0 0 1 1.364 2.077 2.26 2.26 0 0 1-2.25 2.263H10a2.75 2.75 0 0 1-2.705-2.252c-.05-.272-.269-.498-.545-.498h-.5c-.276 0-.503.225-.471.5a4.25 4.25 0 0 0 3.471 3.684V19a.5.5 0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-1.758a3.763 3.763 0 0 0 1.227-7.21L8.614 8.59A2.26 2.26 0 0 1 7.25 6.513 2.26 2.26 0 0 1 9.5 4.25h.5a2.75 2.75 0 0 1 2.705 2.253c.05.271.269.497.545.497h.5c.276 0 .503-.225.471-.5a4.25 4.25 0 0 0-3.471-3.684V1a.5.5 0 0 0-.5-.5h-.5a.5.5 0 0 0-.5.5z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
