import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SuccessContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2.8 2.6a.6.6 0 0 0 .6-.6.6.6 0 0 0 .6.6.6.6 0 0 0-.6.6.6.6 0 0 0-.6-.6M16 2.2a1.4 1.4 0 0 1-1.4 1.4A1.4 1.4 0 0 1 16 5a1.4 1.4 0 0 1 1.4-1.4A1.4 1.4 0 0 1 16 2.2M1.4 9.6A1.4 1.4 0 0 1 0 11a1.4 1.4 0 0 1 1.4 1.4A1.4 1.4 0 0 1 2.8 11a1.4 1.4 0 0 1-1.4-1.4M17 15.2a1 1 0 0 1-1 1 1 1 0 0 1 1 1 1 1 0 0 1 1-1 1 1 0 0 1-1-1m-.6-5.2a6.4 6.4 0 1 1-12.8 0 6.4 6.4 0 0 1 12.8 0M9.742 2.604a7.4 7.4 0 0 1 3.151.585l.078-.184a7.6 7.6 0 0 0-10.377 5.29l.194.046a7.4 7.4 0 0 1 6.954-5.736zm7.629 6.74a7.4 7.4 0 0 0-.815-2.776l.177-.093a7.598 7.598 0 0 1-6.087 11.098 7.6 7.6 0 0 1-7.404-4.097l.177-.091a7.4 7.4 0 0 0 13.952-4.04zm-7.888 2.939 4-4-.566-.566L9.2 11.434 7.283 9.517l-.566.566 2.2 2.2.283.283z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
