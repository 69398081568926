import * as React from 'react';
import Svg, {
  Rect,
  Path,
  Circle,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const PhoneIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <Rect width={32} height={60} x={16} y={2} fill="#425266" rx={6} />
        <Path
          fill="url(#a)"
          d="M18 8a4 4 0 0 1 4-4h1.56c.92 0 1.801.374 2.44 1.037a3.4 3.4 0 0 0 2.44 1.037h7.12A3.4 3.4 0 0 0 38 5.037 3.4 3.4 0 0 1 40.44 4H42a4 4 0 0 1 4 4v48a4 4 0 0 1-4 4H22a4 4 0 0 1-4-4z"
        />
        <Path
          fill="#425266"
          fillRule="evenodd"
          d="M16.5 11a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h.5v-6zM47 11h.5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H47zm0 8h.5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H47z"
          clipRule="evenodd"
        />
        <Circle cx={32} cy={56} r={2} fill="#425266" />
        <Defs>
          <LinearGradient
            id="a"
            x1={32}
            x2={32}
            y1={1.926}
            y2={62.074}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6B7A94" />
            <Stop offset={0} stopColor="#6B7A94" />
            <Stop offset={1} stopColor="#353E4F" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
