import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const InterestIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <G fill={color}>
          <Path
            fillRule="evenodd"
            d="M12 5.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13M8.5 12a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
            clipRule="evenodd"
          />
          <Path d="M33.06 6.94a1.5 1.5 0 0 1 0 2.12l-24 24a1.5 1.5 0 0 1-2.12-2.12l24-24a1.5 1.5 0 0 1 2.12 0" />
          <Path
            fillRule="evenodd"
            d="M21.5 28a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0m6.5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7"
            clipRule="evenodd"
          />
        </G>
      </Svg>
    );
  }
);
