import * as React from 'react';
import {Text} from 'react-native-svg';
import {formatMilliseconds} from '@youtoken/ui.formatting-utils';
import {useCountdown} from '@youtoken/ui.hooks';
import {type TColorTokens, useTheme} from '@youtoken/ui.primitives';

const FONT_SIZE = 12;

type TimerProps = {
  color: keyof TColorTokens;
  timeLeft: number;
  onFinish?: () => void;
};

export const Timer: React.FC<TimerProps> = ({color, timeLeft, onFinish}) => {
  const {colors, fonts} = useTheme();

  const [number, {start}] = useCountdown(timeLeft, 1000, onFinish);

  React.useEffect(() => {
    start(timeLeft);
  }, [timeLeft, start]);

  return (
    <Text
      x={0}
      y={FONT_SIZE / 3.3}
      fill={colors[color]}
      textAnchor="middle"
      fontSize={FONT_SIZE}
      fontFamily={fonts.normal.regular}
      fontWeight={400}
    >
      {formatMilliseconds(number)}
    </Text>
  );
};
