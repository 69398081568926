import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const RemoveIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M15.5 3.5a2 2 0 0 1 2 2V6a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2v-.5a2 2 0 0 1 2-2zm0 1.5h-11a.5.5 0 0 0-.492.41L4 5.5V6a.5.5 0 0 0 .41.492l.09.008h11a.5.5 0 0 0 .492-.41L16 6v-.5a.5.5 0 0 0-.41-.492zm-.75 4a.75.75 0 0 1 .743.648l.007.102V16a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2V9.75l.007-.102a.75.75 0 0 1 1.486 0L6 9.75V16a.5.5 0 0 0 .41.492l.09.008h7a.5.5 0 0 0 .492-.41L14 16V9.75l.007-.102A.75.75 0 0 1 14.75 9m-6.5 0a.75.75 0 0 1 .75.75v4.75a.75.75 0 0 1-1.5 0V9.75A.75.75 0 0 1 8.25 9m3.5 0a.75.75 0 0 1 .75.75v4.75a.75.75 0 0 1-1.5 0V9.75a.75.75 0 0 1 .75-.75m0-8a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
