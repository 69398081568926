import * as React from 'react';
import Svg, {Path, Rect} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SpinnerIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M13.905 1.727a.833.833 0 1 1 1.443.833l-1.666 2.887a.833.833 0 1 1-1.443-.833z"
          clipRule="evenodd"
          opacity={0.65}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M9.214.833a.833.833 0 0 1 1.667 0v3.334a.833.833 0 0 1-1.667 0z"
          clipRule="evenodd"
          opacity={0.6}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M17.604 4.738a.833.833 0 0 1 .833 1.443L15.55 7.848a.833.833 0 1 1-.833-1.443z"
          clipRule="evenodd"
          opacity={0.1}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M19.167 9.214a.833.833 0 0 1 0 1.667h-3.334a.833.833 0 0 1 0-1.667z"
          clipRule="evenodd"
          opacity={0.15}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M18.437 13.905a.833.833 0 0 1-.833 1.443l-2.887-1.666a.833.833 0 1 1 .833-1.443z"
          clipRule="evenodd"
          opacity={0.2}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M15.348 17.604a.833.833 0 0 1-1.443.833l-1.667-2.887a.833.833 0 1 1 1.443-.833z"
          clipRule="evenodd"
          opacity={0.25}
        />
        <Rect
          width={1.667}
          height={5}
          x={10.881}
          y={20}
          fill={color}
          opacity={0.3}
          rx={0.833}
          transform="rotate(180 10.881 20)"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M6.182 18.437a.833.833 0 0 1-1.443-.833l1.666-2.887a.833.833 0 1 1 1.443.833z"
          clipRule="evenodd"
          opacity={0.35}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2.56 15.348a.833.833 0 1 1-.833-1.443l2.886-1.667a.833.833 0 0 1 .833 1.443z"
          clipRule="evenodd"
          opacity={0.4}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M.833 10.881a.833.833 0 1 1 0-1.667h3.334a.833.833 0 0 1 0 1.667z"
          clipRule="evenodd"
          opacity={0.45}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M1.727 6.181a.833.833 0 1 1 .833-1.443l2.887 1.667a.833.833 0 0 1-.833 1.443z"
          clipRule="evenodd"
          opacity={0.5}
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M4.738 2.56a.833.833 0 1 1 1.443-.833l1.667 2.886a.833.833 0 0 1-1.443.833z"
          clipRule="evenodd"
          opacity={0.55}
        />
      </Svg>
    );
  }
);
