import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FaceIdIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M.75 13.75a.75.75 0 0 1 .743.648l.007.102v3a1 1 0 0 0 .883.993l.117.007h3a.75.75 0 0 1 0 1.5H2a2 2 0 0 1-2-2v-3.5a.75.75 0 0 1 .75-.75m18.5 0a.75.75 0 0 1 .743.648L20 14.5V18a2 2 0 0 1-2 2h-3.5a.75.75 0 0 1 0-1.5h3a1 1 0 0 0 .993-.883l.007-.117v-3a.75.75 0 0 1 .75-.75m-4.43-.988a.75.75 0 0 1-.082 1.057C13.432 14.94 11.842 15.5 10 15.5s-3.432-.561-4.738-1.68a.75.75 0 1 1 .976-1.14C7.266 13.562 8.508 14 10 14s2.734-.439 3.762-1.32a.75.75 0 0 1 1.057.082M5.75 6a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 5.75 6m8.5 0a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 .75-.75M5.5 0a.75.75 0 0 1 .102 1.493L5.5 1.5h-3a1 1 0 0 0-.993.883L1.5 2.5v3a.75.75 0 0 1-1.5 0V2a2 2 0 0 1 2-2zM18 0a2 2 0 0 1 2 2v3.5a.75.75 0 0 1-1.5 0v-3a1 1 0 0 0-1-1h-3a.75.75 0 0 1 0-1.5z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
