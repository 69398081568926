import * as React from 'react';
import {observer} from 'mobx-react';
import {type HexGridItemProps} from '../../../../types';
import {useHexGridItemStateScaleAnimation} from '../../hooks';
import {AnimatedGroup} from '../../elements';
import {HexGridItemStateReady} from '../Ready';

export const HexGridItemStateClaimingStartingAnimated: React.FC<HexGridItemProps> =
  observer(({hexagon}) => {
    return (
      <AnimatedGroup
        testID="HEX_GRID_ITEM_STATE_CLAIMING_STARTING"
        animatedProps={useHexGridItemStateScaleAnimation(1.1)}
      >
        <HexGridItemStateReady hexagon={hexagon} />
      </AnimatedGroup>
    );
  });
