import * as React from 'react';
import {BoxProps} from '@youtoken/ui.primitives';
import {Button, IButtonProps} from '@youtoken/ui.buttons';

interface AmountButtonsProps extends IButtonProps {
  label: string;
  index?: number;
  onPress: (label: string) => void;
}

export const AmountButton: React.FC<AmountButtonsProps & BoxProps> = ({
  label,
  index,
  onPress,
  ...buttonProps
}) => {
  const handlePress = React.useCallback(() => {
    onPress(label);
  }, [label, onPress]);

  return (
    <Button flex={1} onPress={handlePress} {...buttonProps}>
      {label}
    </Button>
  );
};
