import * as React from 'react';
import {type MinerHexagon} from '@youtoken/ui.resources-miner';
import {useHexGridItemStateBounceAnimation} from '../../../hooks';
import {MinerOverviewResourceContext} from '../../../../../../../../../context';

export const useHexGridItemStateClaimingStartingAnimation = ({
  block: {id},
}: MinerHexagon) => {
  const {
    hexagonalGrid: {claiming},
  } = React.useContext(MinerOverviewResourceContext).use({});

  const handleFinish = React.useCallback(() => {
    claiming(id);
  }, [id, claiming]);

  return useHexGridItemStateBounceAnimation(handleFinish);
};
