import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DownloadIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M5 19h10a2 2 0 0 0 2-2V5.828a2 2 0 0 0-.586-1.414l-2.828-2.828A2 2 0 0 0 12.172 1H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2m10-1.5H5a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h6.25V5c0 .966.784 1.75 1.75 1.75h2.5V17a.5.5 0 0 1-.5.5m.129-12.25L12.75 2.871V5c0 .138.112.25.25.25z"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10.207 7.5a.75.75 0 0 1 .75.75v4.5l1.25-1.25a.71.71 0 0 1 .912-.075l.088.075a.707.707 0 0 1 0 1l-2.47 2.47a.75.75 0 0 1-1.06 0l-2.47-2.47a.707.707 0 0 1 .912-1.075l.088.075 1.25 1.25v-4.5a.75.75 0 0 1 .75-.75"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
