import * as React from 'react';
import Svg, {Path, Defs, LinearGradient, Stop} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TabletIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <Path
          fill="#425266"
          fillRule="evenodd"
          d="M10.5 11a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h.5v-6zM53 11h.5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H53zm0 8h.5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H53z"
          clipRule="evenodd"
        />
        <Path
          fill="#425266"
          d="M10 9a6 6 0 0 1 6-6h32a6 6 0 0 1 6 6v46a6 6 0 0 1-6 6H16a6 6 0 0 1-6-6z"
        />
        <Path
          fill="url(#a)"
          d="M52 55V9a4 4 0 0 0-4-4H16a4 4 0 0 0-4 4v46a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4"
        />
        <Defs>
          <LinearGradient
            id="a"
            x1={32}
            x2={32}
            y1={3}
            y2={61}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6B7A94" />
            <Stop offset={0} stopColor="#6B7A94" />
            <Stop offset={1} stopColor="#353E4F" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
