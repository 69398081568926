import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CopyIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M8.5 4.5A2.5 2.5 0 0 1 11 2h3.5A2.5 2.5 0 0 1 17 4.5v6a2.5 2.5 0 0 1-2.5 2.5h-1a.75.75 0 0 1 0-1.5h1a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H11a1 1 0 0 0-1 1V5a.75.75 0 0 1-1.5 0z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M3 9.5A2.5 2.5 0 0 1 5.5 7H9a2.5 2.5 0 0 1 2.5 2.5v6A2.5 2.5 0 0 1 9 18H5.5A2.5 2.5 0 0 1 3 15.5zm2.5-1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1H9a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
