import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const IdDriverIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M9 7a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1zm1.5 5.5v-5h5v5z"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M2.75 8a.75.75 0 0 1 .75-.75H7a.75.75 0 0 1 0 1.5H3.5A.75.75 0 0 1 2.75 8M3.5 10.25a.75.75 0 0 0 0 1.5H6a.75.75 0 0 0 0-1.5z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M0 5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-.5h16a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V5a.5.5 0 0 1 .5-.5"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
