import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const HistoryIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M13.472 15.197A6.25 6.25 0 0 1 10 16.25a.75.75 0 0 0 0 1.5A7.75 7.75 0 1 0 2.25 10H1.207a.5.5 0 0 0-.353.854l1.792 1.793a.5.5 0 0 0 .708 0l1.792-1.793A.5.5 0 0 0 4.793 10H3.75a6.25 6.25 0 1 1 9.722 5.197"
        />
        <Path
          fill={color}
          d="M9.742 11a.75.75 0 0 1-.75-.75l-.003-3.5a.75.75 0 0 1 .648-.743L9.74 6a.75.75 0 0 1 .75.75l.002 2.75h2.257a.75.75 0 1 1 0 1.5z"
        />
      </Svg>
    );
  }
);
