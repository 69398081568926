import {generateBlocks} from '.';

const SPARK_BALANCE = 10;

const HODL_SPARK_AMOUNT = 1;
const HODL_SPARKS_REQUIRED_VOLUME = 1000;

export const generateData = () => {
  return {
    sparkBalance: SPARK_BALANCE,
    settings: {
      hodlSparksAmount: HODL_SPARK_AMOUNT,
      hodlSparksRequiredVolume: HODL_SPARKS_REQUIRED_VOLUME,
    },
    blocks: generateBlocks(),
  };
};
