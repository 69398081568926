import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ShowIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <G fill={color}>
          <Path d="M18.416 17.452A3 3 0 0 1 20 17a1.5 1.5 0 0 0 0-3 6 6 0 1 0 5.796 7.553 1.5 1.5 0 0 0-2.898-.777 3 3 0 1 1-4.482-3.324" />
          <Path
            fillRule="evenodd"
            d="M37.523 19.383Q28.607 8.001 20 8 11.654 8 3.017 18.704l-.54.68-.104.163a1 1 0 0 0 .104 1.07Q11.393 32 20 32q8.346 0 16.983-10.704l.54-.68.104-.163a1 1 0 0 0-.104-1.07M6 20q7.373-9 14-9t14 9q-7.373 9-14 9T6 20"
            clipRule="evenodd"
          />
        </G>
      </Svg>
    );
  }
);
