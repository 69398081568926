import * as React from 'react';
import {observer} from 'mobx-react';
import {cell} from '@youtoken/ui.cell';
import {
  TouchableBanner,
  type TouchableBannerProps,
  TouchableBannerVariant,
} from '@youtoken/ui.elements';
import {
  verificationCodeActions,
  VerificationItemStatus,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface VerificationIssueWidgetProps
  extends Omit<
    TouchableBannerProps,
    'iconName' | 'variant' | 'text' | 'onPress' | 'type'
  > {}

export const VerificationIssueWidget: React.FC<VerificationIssueWidgetProps> =
  cell(
    props => {
      const {accountVerificationCurrent} = VerificationResource.use({});

      if (!accountVerificationCurrent) {
        return null;
      }

      return <VerificationIssueWidgetContent {...props} />;
    },
    {
      SuspenseFallback: () => null,
    }
  );

export const VerificationIssueWidgetContent: React.FC<VerificationIssueWidgetProps> =
  observer(props => {
    const {t} = useTranslation();
    const {getVerificationStatusByCode, accountVerificationCurrent} =
      VerificationResource.use({});
    const status = getVerificationStatusByCode(accountVerificationCurrent!);

    const issue = React.useMemo(() => {
      if (
        [
          VerificationItemStatus.SEND,
          VerificationItemStatus.MANUAL_REVIEW,
          VerificationItemStatus.IN_PROGRESS,
          VerificationItemStatus.RESUBMIT_ITA,
        ].includes(status)
      ) {
        return {
          variant: 'attentionSecondary',
          text: t('surface.onboarding_widget.action.docs_under_review'),
        };
      }
      if (
        [
          VerificationItemStatus.DENY,
          VerificationItemStatus.KYC_RESTRICTION,
          VerificationItemStatus.CHANGE_CITIZENSHIP_REQUIRED,
        ].includes(status)
      ) {
        return {
          variant: 'dangerSecondary',
          text: t('surface.onboarding_widget.action.verification_failed'),
        };
      }
      if ([VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED].includes(status)) {
        return {
          variant: 'interactive',
          text: t('verification_issue.text.need_additional_verification'),
        };
      }

      return undefined;
    }, [status]);

    const testID = `VERIFICATION_ISSUE_${accountVerificationCurrent!.toUpperCase()}_${status.toUpperCase()}`;

    if (!issue) {
      return null;
    }

    return (
      <TouchableBanner
        type="icon"
        testID={testID}
        variant={issue.variant as TouchableBannerVariant}
        iconName="doc_address"
        text={issue.text}
        onPress={verificationCodeActions[accountVerificationCurrent!]}
        {...props}
      />
    );
  });
