import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level4Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#B5DAEB"
          d="M4.421 16a1.5 1.5 0 0 1-1.455-1.864l1.75-7A1.5 1.5 0 0 1 6.171 6h7.658a1.5 1.5 0 0 1 1.455 1.136l1.75 7A1.5 1.5 0 0 1 15.579 16z"
        />
        <Path
          fill="#86B2CC"
          d="M11.5 14.5a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 .5-.5h1.596a.5.5 0 0 1 .489.395l1.285 6a.5.5 0 0 1-.488.605z"
        />
        <Path
          fill="#94B9CF"
          d="m2.54 4.881.223-.67a.25.25 0 0 1 .474 0l.223.67a.25.25 0 0 0 .159.159l.67.223a.25.25 0 0 1 0 .474l-.67.223a.25.25 0 0 0-.159.159l-.223.67a.25.25 0 0 1-.474 0l-.223-.67a.25.25 0 0 0-.159-.159l-.67-.223a.25.25 0 0 1 0-.474l.67-.223a.25.25 0 0 0 .159-.159"
        />
        <Path
          fill="#E1F1F9"
          d="m6.54 11.381.223-.67a.25.25 0 0 1 .474 0l.223.67a.25.25 0 0 0 .159.159l.67.223a.25.25 0 0 1 0 .474l-.67.223a.25.25 0 0 0-.159.159l-.223.67a.25.25 0 0 1-.474 0l-.223-.67a.25.25 0 0 0-.159-.159l-.67-.223a.25.25 0 0 1 0-.474l.67-.223a.25.25 0 0 0 .159-.159"
        />
        <Path
          fill="#B5DAEB"
          d="m15.29 3.131.473-1.42a.25.25 0 0 1 .474 0l.474 1.42a.25.25 0 0 0 .158.159l1.42.473a.25.25 0 0 1 0 .474l-1.42.473a.25.25 0 0 0-.159.159l-.473 1.42a.25.25 0 0 1-.474 0l-.473-1.42a.25.25 0 0 0-.159-.159l-1.42-.473a.25.25 0 0 1 0-.474l1.42-.473a.25.25 0 0 0 .159-.159"
        />
      </Svg>
    );
  }
);
