import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const VerificationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M1.75 11a.75.75 0 0 1-.75-.75V3a2 2 0 0 1 2-2h7.25a.75.75 0 0 1 0 1.5H3.5a1 1 0 0 0-1 1v6.75a.75.75 0 0 1-.75.75M22 12a6 6 0 1 0-8.282 5.55A8.754 8.754 0 0 0 7.25 26a.75.75 0 0 0 1.5 0 7.25 7.25 0 1 1 14.5 0 .75.75 0 0 0 1.5 0 8.754 8.754 0 0 0-6.468-8.45A6 6 0 0 0 22 12m-10.5 0a4.5 4.5 0 0 1 1.522-3.374c.885 1.35 2.006 2.28 3.356 2.769 1.263.456 2.636.533 4.107.24q.015.18.015.365a4.5 4.5 0 1 1-9 0M16 7.5c-.603 0-1.178.119-1.703.334.708 1.072 1.57 1.781 2.59 2.15.974.351 2.047.419 3.229.194A4.5 4.5 0 0 0 16 7.5m15 2.75a.75.75 0 0 1-1.5 0V3.5a1 1 0 0 0-1-1h-6.75a.75.75 0 0 1 0-1.5H29a2 2 0 0 1 2 2zM1.75 21a.75.75 0 0 0-.75.75V29a2 2 0 0 0 2 2h7.25a.75.75 0 0 0 0-1.5H3.5a1 1 0 0 1-1-1v-6.75a.75.75 0 0 0-.75-.75m29.25.75a.75.75 0 0 0-1.5 0v6.75a1 1 0 0 1-1 1h-6.75a.75.75 0 0 0 0 1.5H29a2 2 0 0 0 2-2z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
