import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const UmaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M15.202 11.081a.9.9 0 0 1-.411.085.7.7 0 0 1-.265-.046.4.4 0 0 1-.173-.13.37.37 0 0 1-.059-.21q0-.087.036-.16a.4.4 0 0 1 .118-.13 1 1 0 0 1 .212-.115q.114-.042.265-.078a7 7 0 0 1 .395-.079h.004c.102-.02.23-.043.373-.08q-.003.098-.011.224a.9.9 0 0 1-.079.334.8.8 0 0 1-.137.186.9.9 0 0 1-.268.2"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M1 9.391c0-1.173 0-1.76.228-2.208.201-.394.522-.714.916-.915.448-.228 1.035-.228 2.208-.228h11.296c1.174 0 1.76 0 2.208.228.394.2.715.521.916.915C19 7.631 19 8.218 19 9.391v1.217c0 1.174 0 1.76-.228 2.208-.201.395-.522.715-.916.916-.448.228-1.035.228-2.208.228H4.352c-1.173 0-1.76 0-2.208-.228a2.1 2.1 0 0 1-.916-.915C1 12.368 1 11.782 1 10.608zm12.958 2.33q.264.144.643.144.458 0 .755-.157a1.6 1.6 0 0 0 .448-.374v.433h.777V9.611q0-.242-.02-.438a1.1 1.1 0 0 0-.123-.392 1.04 1.04 0 0 0-.55-.497 2.2 2.2 0 0 0-.83-.144q-.636 0-1.006.275-.37.274-.503.712l.804.255a.55.55 0 0 1 .297-.336q.213-.096.409-.095.353 0 .503.147.126.123.146.362c-.11.063-.23.108-.388.136a27 27 0 0 1-.51.085h-.003l-.172.028c-.15.025-.385.08-.511.118-.127.039-.283.11-.396.215a.986.986 0 0 0-.303.752q0 .294.134.539.136.24.399.389m-2.075-2.038v2.084h.888v-2.32q0-.578-.31-.934-.307-.36-.912-.36-.353 0-.643.157a1.2 1.2 0 0 0-.435.391 1.05 1.05 0 0 0-.346-.371q-.265-.177-.68-.177-.362 0-.657.16a1.3 1.3 0 0 0-.375.312v-.387h-.784v3.53h.895V9.63a.72.72 0 0 1 .166-.493.57.57 0 0 1 .448-.193q.29 0 .454.196.167.194.167.543v2.084h.889V9.63a.8.8 0 0 1 .075-.356.58.58 0 0 1 .539-.33q.29 0 .454.196.167.194.167.543m-7.089 2.189q-.415 0-.676-.14a1.1 1.1 0 0 1-.409-.357 1.5 1.5 0 0 1-.205-.448 2.3 2.3 0 0 1-.076-.428 5 5 0 0 1-.013-.28v-1.98h.902v1.666q0 .12.013.31.013.187.082.376.069.19.222.317.158.127.441.127A1 1 0 0 0 5.32 11a.6.6 0 0 0 .245-.137.75.75 0 0 0 .186-.304 1.6 1.6 0 0 0 .076-.53v-1.79h.895v3.53h-.784v-.365a1.5 1.5 0 0 1-.285.243q-.34.226-.859.226"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
