import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const BellIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M6.348 32h27.304c1.708 0 2.63-2.004 1.518-3.302l-2.36-2.753a3.36 3.36 0 0 1-.81-2.19V15.51a9.62 9.62 0 0 0-7.285-9.33 1.6 1.6 0 0 1-1.045-.838l-.776-1.552a3.236 3.236 0 0 0-5.788 0L16.33 5.34c-.21.418-.591.724-1.045.838A9.62 9.62 0 0 0 8 15.509v8.247c0 .803-.287 1.58-.81 2.189l-2.36 2.753C3.718 29.996 4.64 32 6.348 32m24.184-4.103L31.477 29H8.523l.945-1.103A6.36 6.36 0 0 0 11 23.756v-8.247a6.62 6.62 0 0 1 5.012-6.42 4.6 4.6 0 0 0 3-2.407l.777-1.552a.236.236 0 0 1 .422 0l.776 1.552a4.6 4.6 0 0 0 3 2.407A6.62 6.62 0 0 1 29 15.51v8.247c0 1.519.543 2.988 1.532 4.141"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M20.299 37.989A4 4 0 0 0 24 34h-8a4 4 0 0 0 4 4z"
        />
      </Svg>
    );
  }
);
