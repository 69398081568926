import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const NewReferralIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M10.335 12.567h-3.3c-1.024 0-1.536 0-1.953.127a2.93 2.93 0 0 0-1.956 1.955C3 15.066 3 15.579 3 16.602m12.47 0v-4.4m-2.201 2.2h4.401M12.169 6.7a3.3 3.3 0 1 1-6.602 0 3.3 3.3 0 0 1 6.602 0"
        />
      </Svg>
    );
  }
);
