import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const EditIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          stroke={color}
          strokeWidth={1.5}
          d="m13 7-7.707 7.707a1 1 0 0 1-.707.293H1.5a.5.5 0 0 1-.5-.5v-3.086a1 1 0 0 1 .293-.707L9 3m4 4 1.44-1.44a1.5 1.5 0 0 0 0-2.12l-1.88-1.88a1.5 1.5 0 0 0-2.12 0L9 3m4 4L9 3"
        />
      </Svg>
    );
  }
);
