import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CaptureIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M11.586 2.5a1 1 0 0 1 .707.293L14 4.5h2.5a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2H6l1.707-1.707a1 1 0 0 1 .707-.293zM16.5 6h-13a.5.5 0 0 0-.492.41L3 6.5v9a.5.5 0 0 0 .41.492L3.5 16h13a.5.5 0 0 0 .492-.41L17 15.5v-9a.5.5 0 0 0-.41-.492zm-6.5.5a4 4 0 1 1 0 8 4 4 0 0 1 0-8M10 8a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.75 7a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5m6.336-3H8.914a1 1 0 0 0-.608.206l-.099.087L6.5 6h7l-1.707-1.707a1 1 0 0 0-.576-.284z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
