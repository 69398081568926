import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level1Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#66A6A4"
          d="m8.073 7.78 1.453-4.357a.5.5 0 0 1 .948 0l1.453 4.357a.5.5 0 0 0 .288.306l3.624 1.45a.5.5 0 0 1 0 .928l-3.624 1.45a.5.5 0 0 0-.288.306l-1.453 4.357a.5.5 0 0 1-.948 0L8.073 12.22a.5.5 0 0 0-.288-.306l-3.624-1.45a.5.5 0 0 1 0-.928l3.624-1.45a.5.5 0 0 0 .288-.306"
        />
        <Path
          fill="#97DAD8"
          fillRule="evenodd"
          d="M10 3.081c-.2 0-.398.114-.474.342L8.073 7.78a.5.5 0 0 1-.288.306L4.16 9.536a.5.5 0 0 0 0 .928l3.624 1.45a.5.5 0 0 1 .288.306l1.453 4.357a.49.49 0 0 0 .474.342z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
