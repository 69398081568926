import * as React from 'react';
import {
  interpolate,
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {type MinerHexagon} from '@youtoken/ui.resources-miner';

export const useHexGridItemStatePressingAnimation = ({
  pressing,
}: MinerHexagon) => {
  const scale = useSharedValue(1);
  const opacity = useSharedValue(1);

  React.useEffect(() => {
    scale.value = withTiming(interpolate(pressing, [0, 1], [1, 0.95]), {
      duration: 300,
    });
    opacity.value = withTiming(interpolate(pressing, [0, 1], [1, 0.7]), {
      duration: 300,
    });
  }, [pressing]);

  return useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: scale.value,
        },
      ],
      opacity: opacity.value,
    };
  }, []);
};
