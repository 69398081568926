import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const PhoneIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M5.282 2.544a1.75 1.75 0 0 1 2.095.886l1.06 2.12a1.75 1.75 0 0 1-.328 2.02l-.665.666c-.08.08-.08.171-.054.222a9.8 9.8 0 0 0 1.704 2.408 9.9 9.9 0 0 0 2.42 1.728c.052.026.143.027.224-.052l.685-.677a1.75 1.75 0 0 1 2.008-.323l2.133 1.059a1.75 1.75 0 0 1 .889 2.1l-.1.309a3.91 3.91 0 0 1-4.503 2.663l-.008-.002-.008-.001A13.82 13.82 0 0 1 2.332 7.15l-.002-.01a3.9 3.9 0 0 1 2.63-4.494h.003zM6.035 4.1a.25.25 0 0 0-.3-.126l-.316.1a2.4 2.4 0 0 0-1.62 2.76 12.32 12.32 0 0 0 9.353 9.37 2.41 2.41 0 0 0 2.77-1.643l.002-.005.1-.312a.25.25 0 0 0-.127-.3l-2.133-1.058a.25.25 0 0 0-.287.046l-.685.677c-.499.493-1.28.666-1.955.323a11.4 11.4 0 0 1-2.798-2l-.008-.008-.008-.008A11.3 11.3 0 0 1 6.05 9.13c-.34-.678-.163-1.459.333-1.956l.665-.665a.25.25 0 0 0 .047-.289z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
