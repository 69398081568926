import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {
  Box,
  Text,
  type BoxProps,
  type TColorTokens,
} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

const TEXT_COLORS: {[key: string]: keyof TColorTokens} = {
  TAP_TO_COLLECT: '$attention-01',
  TAP_TO_MINE: '$attention-01',
  WAIT_FOR_MINE: '$success-01',
  GET_SPARKS_TO_MINE: '$text-01',
  GET_SPARKS_TO_UNBLOCK: '$text-01',
  UNBLOCK_BLOCKS: '$attention-01',
};

export const NextAction: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    hexagonalGrid: {
      proposedAction,
      proposedActionAmount,
      navigateToGetMoreSparks,
      navigateToResetBlocks,
    },
  } = MinerOverviewResource.use({});

  const handlePressGetMore = React.useCallback(() => {
    navigateToGetMoreSparks('board');
  }, [navigateToGetMoreSparks]);

  const handlePressUnlockBlocks = React.useCallback(() => {
    navigateToResetBlocks('board');
  }, [navigateToResetBlocks]);

  if (!proposedAction) {
    return null;
  }

  return (
    <Box alignItems="center" alignSelf="stretch" px={20} {...boxProps}>
      <Text
        variant="$heading-02"
        color={TEXT_COLORS[proposedAction]}
        textAlign="center"
        testID="MINER_NEXT_ACTION"
      >
        {t(`surface.miner.action.${proposedAction}`, {
          amount: proposedActionAmount,
        })}
      </Text>
      {proposedAction === 'UNBLOCK_BLOCKS' && (
        <Button
          icon="spark"
          mt={12}
          type="primary"
          color="attention"
          onPress={handlePressUnlockBlocks}
          px={24}
          alignSelf={{default: 'stretch', tablet: 'center'}}
          testID="MINER_UNBLOCK_BLOCKS_BUTTON"
        >
          {t('surface.miner.unblock_blocks.action', {
            amount: proposedActionAmount,
          })}
        </Button>
      )}
      {['GET_SPARKS_TO_UNBLOCK', 'GET_SPARKS_TO_MINE'].includes(
        proposedAction
      ) && (
        <Button
          icon="spark"
          mt={12}
          type="primary"
          onPress={handlePressGetMore}
          px={24}
          alignSelf={{default: 'stretch', tablet: 'center'}}
          testID="MINER_GET_SPARKS_BUTTON"
        >
          {t('surface.upgrade_modal.sparks.title')}
        </Button>
      )}
    </Box>
  );
});
