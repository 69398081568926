import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {FeaturesResource} from '@youtoken/ui.resource-features';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {NotificationsResource} from '@youtoken/ui.resource-notifications';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {
  LocaliseResource,
  LocaliseResourceNamespace,
} from '@youtoken/ui.resource-lokalise';
import {OnboardingWidgetResource} from '@youtoken/ui.resource-onboarding-widget';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {
  SavingsWidgetResource,
  SavingsOverviewResource,
} from '@youtoken/ui.resource-savings';
import {IncentivesResource} from '@youtoken/ui.resource-incentives';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {CoindropResource} from '@youtoken/ui.coindrop-resource';
import {BankCardsResource} from '@youtoken/ui.resource-bank-cards';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';

export type FeatureToRefetch = {
  isLoadingManually: boolean;
  refetchManually: () => Promise<any>;
};

export const getWalletsListResources = () => {
  const authMeResource = AuthMeResource.getInstanceSafely({})!;
  const storiesResource = InstaStoriesResource.getInstanceSafely({});

  const resources = [
    authMeResource,
    storiesResource,
    LocaliseResource.getInstanceSafely({
      namespace: LocaliseResourceNamespace.NOTIFICATION,
    }),
    NotificationsResource.getInstanceSafely({}),
    FeaturesResource.getInstanceSafely({}),
    RatesResource.getInstanceSafely({product: 'default'}),
    WalletsResource.getInstanceSafely({}),
    HodlsTariffsResource.getInstanceSafely({}),
    OnboardingWidgetResource.getInstanceSafely({}),
    SavingsWidgetResource.getInstanceSafely({}),
    IncentivesResource.getInstanceSafely({}),
    VerificationResource.getInstanceSafely({}),
    LoyaltyResource.getInstanceSafely({}),
    ExchangeTariffsResource.getInstanceSafely({}),
    CoindropResource.getInstanceSafely({}),
    BankCardsResource.getInstanceSafely({}),
    DocsResource.getInstanceSafely({
      country: authMeResource.residenceOrCountry,
    }),
    IBANAccountsResource.getInstanceSafely({}),
    SavingsOverviewResource.getInstanceSafely({}),
  ];

  return (resources.filter(resource => resource !== undefined) ??
    []) as unknown as FeatureToRefetch[];
};
