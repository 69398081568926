import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const MinerBlockCostIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          stroke={color}
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m4.407 4.507 4-2.25a3.25 3.25 0 0 1 3.186 0l4 2.25a3.25 3.25 0 0 1 1.657 2.832v5.322a3.25 3.25 0 0 1-1.657 2.832l-4 2.25a3.25 3.25 0 0 1-3.186 0l-4-2.25a3.25 3.25 0 0 1-1.657-2.832V7.339a3.25 3.25 0 0 1 1.657-2.832Z"
        />
        <Path
          fill={color}
          d="M9.078 15.5a.406.406 0 0 1-.4-.474l.521-3.067a.5.5 0 0 0-.493-.584H6.843q-.263 0-.324-.137-.062-.138.079-.379l3.984-6.173a.406.406 0 0 1 .74.288l-.521 3.067a.5.5 0 0 0 .493.584h1.863q.262 0 .324.137.062.138-.078.379l-3.985 6.173a.4.4 0 0 1-.34.186"
        />
      </Svg>
    );
  }
);
