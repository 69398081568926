import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

import {SparkPriceBasic} from '..';
import {NextFreeSparkIn} from './components';

export const RightSection: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    hexagonalGrid: {sparkBalance, navigateToGetMoreSparks},
  } = MinerOverviewResource.use({});

  const handlePress = React.useCallback(() => {
    navigateToGetMoreSparks('overview');
  }, [navigateToGetMoreSparks]);

  return (
    <Box alignItems="flex-end">
      <Text variant="$body-02" color="$text-02" px={4}>
        {t('surface.miner.overview.sparks')}
      </Text>
      <TouchableBox
        testID="ADD_SPARK_BUTTON"
        borderRadius={24}
        backgroundColor="$ui-overflow"
        flexDirection="row"
        alignItems="center"
        mt={8}
        p={4}
        pl={8}
        onPress={handlePress}
      >
        <SparkPriceBasic amount={sparkBalance} />
        <Box
          ml={8}
          width={24}
          height={24}
          borderRadius={12}
          backgroundColor="$attention-01"
          alignItems="center"
          justifyContent="center"
        >
          <Icon name="plus" size={16} color="$text-04" />
        </Box>
      </TouchableBox>

      <NextFreeSparkIn />
    </Box>
  );
});
