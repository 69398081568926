import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const WarningIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M10.75 1a.75.75 0 0 0-1.5 0v.5a.75.75 0 0 0 1.5 0zM15 9a5 5 0 0 1-3 4.584V15H8v-1.416A5.001 5.001 0 0 1 10 4a5 5 0 0 1 5 5M12 17v-1H8v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1M2 8.25a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5zM16.75 9a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75M3.97 2.97a.75.75 0 0 1 1.06 0l.5.5a.75.75 0 1 1-1.06 1.06l-.5-.5a.75.75 0 0 1 0-1.06M16.03 4.03a.75.75 0 0 0-1.06-1.06l-.5.5a.75.75 0 0 0 1.06 1.06z"
        />
      </Svg>
    );
  }
);
