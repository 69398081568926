import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const InstagramIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          fill={color}
          d="M4.054 2h11.893A2.06 2.06 0 0 1 18 4.054v11.893c0 1.13-.924 2.053-2.053 2.053H4.054A2.06 2.06 0 0 1 2 15.947V4.054A2.06 2.06 0 0 1 4.054 2m9.602 1.777a.723.723 0 0 0-.72.721v1.724c0 .397.323.72.72.72h1.808c.396 0 .72-.323.72-.72V4.498a.723.723 0 0 0-.72-.721zm2.535 4.99h-1.408c.134.435.206.895.206 1.373 0 2.66-2.227 4.819-4.974 4.819-2.746 0-4.974-2.158-4.974-4.819a4.7 4.7 0 0 1 .206-1.374H3.78v6.76c0 .35.285.635.635.635h11.142c.35 0 .635-.285.635-.635v-6.76zm-6.176-1.915c-1.774 0-3.213 1.394-3.213 3.114s1.44 3.114 3.213 3.114c1.775 0 3.214-1.395 3.214-3.114 0-1.72-1.44-3.114-3.214-3.114"
        />
      </Svg>
    );
  }
);
