import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level2GrayscaleIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#CBD2DA"
          d="M7.923 9.871 5.515 5.857c-.134-.223.12-.476.342-.343l4.014 2.409a.25.25 0 0 0 .258 0l4.014-2.408c.223-.134.476.12.342.342l-2.408 4.014a.25.25 0 0 0 0 .258l2.409 4.014c.133.223-.12.476-.344.342l-4.013-2.408a.25.25 0 0 0-.258 0l-4.014 2.409c-.223.133-.476-.12-.343-.344l2.409-4.013a.25.25 0 0 0 0-.258"
        />
        <Path
          fill="#8E9FB3"
          fillRule="evenodd"
          d="M10 7.958a.25.25 0 0 1-.129-.035L5.857 5.515c-.223-.134-.476.12-.343.343L7.924 9.87a.25.25 0 0 1 0 .258l-2.408 4.014c-.134.223.12.476.343.342l4.013-2.408a.25.25 0 0 1 .129-.035z"
          clipRule="evenodd"
        />
        <Path
          fill="#8E9FB3"
          d="m8.08 7.763 1.446-4.34a.5.5 0 0 1 .948 0l1.447 4.34a.5.5 0 0 0 .316.316l4.34 1.447a.5.5 0 0 1 0 .948l-4.34 1.447a.5.5 0 0 0-.316.316l-1.447 4.34a.5.5 0 0 1-.948 0l-1.447-4.34a.5.5 0 0 0-.316-.316l-4.34-1.447a.5.5 0 0 1 0-.948l4.34-1.447a.5.5 0 0 0 .316-.316"
        />
        <Path
          fill="#CBD2DA"
          fillRule="evenodd"
          d="M10 3.081c-.2 0-.398.114-.474.342l-1.447 4.34a.5.5 0 0 1-.316.316l-4.34 1.447a.5.5 0 0 0 0 .948l4.34 1.447a.5.5 0 0 1 .316.316l1.447 4.34a.49.49 0 0 0 .474.342z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
