import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {PromoBanner, PromoBannerImageName} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Notification} from '@youtoken/ui.resource-notifications';

export const PromoBannerContent: React.FC<
  {minerPromo: Notification; close: (id: string) => void} & BoxProps
> = observer(({minerPromo, close, ...boxProps}) => {
  const {
    notificationId,
    conditions: {canClose, url},
    texts: {title, body},
    design,
  } = minerPromo;

  if (!body) {
    return null;
  }

  const handlePressBanner = React.useCallback(() => {
    if (!url) {
      return;
    }
    const [name, params, query] = SHARED_ROUTER_SERVICE.urlToRoute(url);
    SHARED_ROUTER_SERVICE.navigate(name, params, query);
  }, [url]);

  const handlePressClose = React.useCallback(() => {
    close(notificationId);
  }, [close, notificationId]);

  return (
    <PromoBanner
      onPressBanner={handlePressBanner}
      onPressClose={canClose ? handlePressClose : undefined}
      secondaryText={title}
      text={body}
      imageName={design?.iconName as PromoBannerImageName}
      variant={
        design?.kind === 'secondary' ? 'interactiveSecondary' : 'interactive'
      }
      testID="MINER_PROMO_BANNER"
      {...boxProps}
    />
  );
});
