import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SendIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M35.359 4.342a1 1 0 0 1 .674 1.09l-.042.175-9.876 29.628a1 1 0 0 1-1.77.254l-.089-.157-5.862-12.896a1 1 0 0 0-.34-.408l-.156-.09L5 16.078a1 1 0 0 1-.068-1.787l.165-.072 29.628-9.876a1 1 0 0 1 .633 0m-6.245 4.755-17.33 6.077a.5.5 0 0 0-.04.928l7.153 3.211zm2.12 2.121L21.02 21.434l3.212 7.154a.5.5 0 0 0 .928-.04z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
