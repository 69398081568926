import * as React from 'react';
import {type HexGridItemProps} from '../../../../types';
import {useHexGridItemStateScaleAnimation} from '../../hooks';
import {AnimatedGroup, FilledGroup, Background, Ticker} from '../../elements';

export const HexGridItemStateReady: React.FC<HexGridItemProps> = () => {
  return (
    <FilledGroup>
      <Background color="$attention-01" />
      <Ticker color="$attention-01" />
    </FilledGroup>
  );
};

export const HexGridItemStateReadyAnimated: React.FC<HexGridItemProps> = ({
  hexagon,
}) => {
  return (
    <AnimatedGroup
      testID="HEX_GRID_ITEM_STATE_READY"
      animatedProps={useHexGridItemStateScaleAnimation(0.95)}
    >
      <HexGridItemStateReady hexagon={hexagon} />
    </AnimatedGroup>
  );
};
