import * as React from 'react';
import {type MinerHexagon} from '@youtoken/ui.resources-miner';
import {useHexGridItemStateBounceAnimation} from '../../../hooks';
import {MinerOverviewResourceContext} from '../../../../../../../../../context';

export const useHexGridItemStateClaimedInfoAnimation = ({
  block: {id},
}: MinerHexagon) => {
  const {
    hexagonalGrid: {showInfoEnd},
  } = React.useContext(MinerOverviewResourceContext).use({});

  const handleFinish = React.useCallback(() => {
    showInfoEnd(id);
  }, [id, showInfoEnd]);

  return useHexGridItemStateBounceAnimation(handleFinish);
};
