import * as React from 'react';
import {observer} from 'mobx-react';
import {G, Text, Path} from 'react-native-svg';
import {type TColorTokens, useTheme} from '@youtoken/ui.primitives';

const FONT_SIZE = 12;

type PriceProps = {
  color: keyof TColorTokens;
  priceFormatted: string;
};

export const Price: React.FC<PriceProps> = observer(
  ({color, priceFormatted}) => {
    const {fonts, colors} = useTheme();

    const textOffsetX = priceFormatted.length * 1.3 + 2;
    const sparkOffsetX = -4 - (priceFormatted.length * 2 + 3);
    const sparkOffsetY = -6;

    return (
      <G>
        <Text
          testID="HEX_GRID_ITEM_STATE_PRICE"
          x={textOffsetX}
          y={FONT_SIZE / 3.3}
          fill={colors[color]}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fontFamily={fonts.mono.bold}
          fontWeight={700}
        >
          {priceFormatted}
        </Text>
        <Path
          d="M3.07762 11.5C2.82639 11.5 2.63556 11.274 2.67768 11.0263L3.19935 7.95883C3.25127 7.65357 3.01607 7.375 2.70643 7.375H0.843307C0.667926 7.375 0.559657 7.32917 0.518501 7.2375C0.477813 7.14583 0.504237 7.01979 0.597773 6.85938L4.58185 0.685711C4.65656 0.569937 4.78493 0.5 4.92272 0.5C5.17395 0.5 5.36478 0.726025 5.32266 0.9737L4.80098 4.04117C4.74907 4.34643 4.98427 4.625 5.29391 4.625H7.15703C7.33241 4.625 7.44045 4.67083 7.48114 4.7625C7.52229 4.85417 7.4961 4.98021 7.40257 5.14062L3.41849 11.3143C3.34378 11.4301 3.21541 11.5 3.07762 11.5Z"
          fill={colors[color]}
          transform={`translate(${sparkOffsetX} ${sparkOffsetY})`}
        />
      </G>
    );
  }
);
