import {AxiosRequestConfig} from 'axios';
import {Platform} from 'react-native';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {i18n} from '@youtoken/ui.service-i18n';
import {assignHeader} from './utils';

export function requestHeadersInterceptor(config: AxiosRequestConfig) {
  if (!config.headers) {
    config.headers = {};
  }

  assignHeader(config.headers, 'x-skip-recaptcha', () =>
    ENVIRONMENT.SKIP_RECAPTCHA ? '1' : undefined
  );

  assignHeader(config.headers, 'x-system-locale', () => i18n.systemLang);
  assignHeader(config.headers, 'x-locale', () => i18n.language);

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    assignHeader(config.headers, 'x-a-id', () => ENVIRONMENT.ADVERTISING_ID);
    assignHeader(
      config.headers,
      'x-appsflyer-uid',
      () => ENVIRONMENT.APPSFLYER_ID
    );
  }

  assignHeader(
    config.headers,
    'x-sift-session-id',
    () => ENVIRONMENT['SESSION_ID']
  );

  if (Platform.OS === 'web') {
    assignHeader(
      config.headers,
      'x-web-app-env',
      () => ENVIRONMENT.WEB_APP_ENV
    );

    if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ramp-app']) {
      assignHeader(
        config.headers,
        'x-session-id',
        () => ENVIRONMENT['RAMP_SESSION_ID']
      );
    }
  }

  return config;
}
