import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored} from '@youtoken/ui.icons';

export const LeftSection: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    hexagonalGrid: {
      totalMinedAmountFormatted,
      totalMinedTickerFormatted,
      totalMinedAmountUSDFormatted,
    },
  } = MinerOverviewResource.use({});

  return (
    <Box>
      <Text variant="$body-02" color="$text-02" px={4}>
        {t('surface.miner.overview.total')}
      </Text>
      <Box
        testID="TOTAL_MINED_BTC"
        borderRadius={24}
        backgroundColor="$ui-overflow"
        flexDirection="row"
        alignItems="center"
        mt={8}
        p={4}
        pr={16}
      >
        <LogoColored name="btc" size={24} />
        <Text variant="$body-01-high-accent" color="$text-01" ml={6}>
          {totalMinedAmountFormatted}
        </Text>
        <Text variant="$body-01" color="$text-01" ml={2}>
          {totalMinedTickerFormatted}
        </Text>
      </Box>
      <Text mt={8} color="$text-02">
        ${totalMinedAmountUSDFormatted}
      </Text>
    </Box>
  );
});
