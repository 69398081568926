import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const QuestionOutlinedIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 3.5a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16m-.146 9.783c-.428 0-.662-.281-.662-.656 0-.692.275-1.084 1.066-1.705.569-.445.885-.727.885-1.242 0-.575-.51-.955-1.154-.955-.563 0-.95.216-1.23.697-.182.24-.323.351-.569.351-.37 0-.574-.263-.574-.585 0-.387.228-.797.58-1.108.428-.363 1.066-.58 1.898-.58 1.541 0 2.614.803 2.614 2.11 0 .908-.498 1.406-1.248 1.962-.569.434-.762.639-.897 1.131-.147.346-.299.58-.709.58m0 2.485c-.486 0-.885-.34-.885-.827s.399-.826.885-.826.89.34.89.826c0 .487-.404.827-.89.827"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
