import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DownIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M4.625 4.625a.884.884 0 0 1 1.25 0L14.25 13V7.125a.875.875 0 1 1 1.75 0v8.125a.75.75 0 0 1-.75.75H7.125a.875.875 0 1 1 0-1.75H13L4.625 5.875a.884.884 0 0 1 0-1.25"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
