import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SnowflakeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M10 1.5V5m0 13.5V15m0-10L8 3.5M10 5l2-1.5M10 5v10m0 0-2 1.5m2-1.5 2 1.5M2.639 5.75l3.03 1.75m11.692 6.75-3.03-1.75m-8.661-5-2.3.982m2.3-.982-.3-2.482m.3 2.482 8.66 5m0 0 .3 2.482m-.3-2.482 2.3-.982M17.361 5.75l-3.03 1.75M2.638 14.25l3.03-1.75m8.661-5 .3-2.482m-.3 2.482 2.3.982m-2.3-.982-8.66 5m0 0-2.3-.982m2.3.982-.3 2.482"
        />
      </Svg>
    );
  }
);
