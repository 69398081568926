import * as React from 'react';
import {observer} from 'mobx-react';
import {G, Path, Text} from 'react-native-svg';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type TColorTokens, useTheme} from '@youtoken/ui.primitives';

const FONT_SIZE = 10;

type AmountProps = {
  color: keyof TColorTokens;
  amountFormatted: string;
  tickerFormatted: string;
  showIcon?: boolean;
};

export const Amount: React.FC<AmountProps> = observer(
  ({color, amountFormatted, tickerFormatted, showIcon = false}) => {
    const {t} = useTranslation();

    const {fonts, colors} = useTheme();

    return (
      <G>
        {showIcon && (
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0303 6.46967C16.3232 6.76256 16.3232 7.23744 16.0303 7.53033L9.03033 14.5303C8.73744 14.8232 8.26256 14.8232 7.96967 14.5303L3.96967 10.5303C3.67678 10.2374 3.67678 9.76256 3.96967 9.46967C4.26256 9.17678 4.73744 9.17678 5.03033 9.46967L8.5 12.9393L14.9697 6.46967C15.2626 6.17678 15.7374 6.17678 16.0303 6.46967Z"
            fill={colors[color]}
            translateX={-8}
            translateY={-30}
          />
        )}
        <Text
          testID="HEX_GRID_ITEM_STATE_AMOUNT"
          x={0}
          y={0}
          fill={colors[color]}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fontFamily={fonts.normal.bold}
          fontWeight={700}
        >
          {`+${amountFormatted}`}
        </Text>
        <Text
          x={0}
          y={11}
          fill={colors[color]}
          textAnchor="middle"
          fontSize={8}
          fontFamily={fonts.normal.regular}
          fontWeight={400}
        >
          {`${tickerFormatted} ${t('surface.miner.hexagon.earned')}`}
        </Text>
      </G>
    );
  }
);
