import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ReopenIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <G fill={color}>
          <Path d="M20 4.5a15.5 15.5 0 0 0-7.75 2.077 1.5 1.5 0 1 0 1.5 2.598A12.5 12.5 0 0 1 32.5 20h-2.086c-.89 0-1.337 1.077-.707 1.707l3.586 3.586a1 1 0 0 0 1.414 0l3.586-3.586c.63-.63.184-1.707-.707-1.707H35.5A15.5 15.5 0 0 0 20 4.5M4.5 20H2.414c-.89 0-1.337-1.077-.707-1.707l3.586-3.586a1 1 0 0 1 1.414 0l3.586 3.586c.63.63.184 1.707-.707 1.707H7.5a12.5 12.5 0 0 0 18.75 10.825 1.5 1.5 0 1 1 1.5 2.598A15.5 15.5 0 0 1 4.5 20" />
        </G>
      </Svg>
    );
  }
);
