import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CollapseIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M4.625 11.375 9.47 6.53a.75.75 0 0 1 1.06 0l4.845 4.845a.884.884 0 1 1-1.25 1.25L10 8.5l-4.125 4.125a.884.884 0 1 1-1.25-1.25"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
