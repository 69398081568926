import Big from 'big.js';
import {observable, computed} from 'mobx';
import {serializable, primitive, list, object} from 'serializr';
import {number, bigNumber} from '@youtoken/ui.utils-serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {MinerBlock} from './MinerBlock';

export type ProposedAction =
  | 'TAP_TO_COLLECT'
  | 'TAP_TO_MINE'
  | 'WAIT_FOR_MINE'
  | 'GET_SPARKS'
  | 'UNBLOCK_BLOCKS'
  | 'GET_SPARKS_TO_UNBLOCK'
  | 'GET_SPARKS_TO_MINE';

export class SettingsResponse {
  @serializable(number())
  @observable
  hodlSparksAmount!: number;

  @serializable(primitive())
  @observable
  hodlSparksTicker!: string;

  @computed get hodlSparkTickerFormatted() {
    return this.hodlSparksTicker.toUpperCase();
  }

  @serializable(number())
  hodlSparksRequiredVolume!: number;
}

export interface MinerOverviewArgs {}

export class MinerOverviewResponse {
  @serializable(object(SettingsResponse))
  @observable
  settings!: SettingsResponse;

  @serializable(list(object(MinerBlock)))
  @observable
  blocks!: MinerBlock[];

  // Optionals

  @serializable(primitive())
  @observable
  timeLeftUntilNextFreeSparksDrop: number | null = null;

  @serializable(primitive())
  @observable
  totalMinedTicker: string = 'btc';

  @computed
  get totalMinedTickerFormatted(): string {
    return this.totalMinedTicker.toUpperCase();
  }

  @serializable(bigNumber())
  @observable
  totalMinedAmount: Big = Big(0);

  @computed
  get totalMinedAmountFormatted(): string {
    return this.totalMinedAmount.toFixed(
      getCoinDecimalPrecision(this.totalMinedTicker)
    );
  }

  @serializable(bigNumber())
  @observable
  totalMinedAmountUSD: Big = Big(0);

  @computed
  public get totalMinedAmountUSDFormatted(): string {
    return formatByTicker(this.totalMinedAmountUSD, 'usd');
  }

  @serializable(number())
  @observable
  sparkBalance: number = 0;

  @serializable(number())
  @observable
  resetCost: number = 0;

  @serializable(primitive())
  @observable
  proposedAction?: ProposedAction;
}
