import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {LaptopIcon as laptop} from './Laptop';
import {PhoneIcon as phone} from './Phone';
import {TabletIcon as tablet} from './Tablet';

export type IconName = 'laptop' | 'phone' | 'tablet';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  laptop,
  phone,
  tablet,
};
