import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const VerifiedIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M14.03 8.53a.75.75 0 0 0-1.06-1.06L9.5 10.94 8.03 9.47a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2 4.671a1.5 1.5 0 0 1 1.136-1.455L9.515 1.62a2 2 0 0 1 .97 0l6.379 1.595A1.5 1.5 0 0 1 18 4.67v6.504a6 6 0 0 1-2.82 5.087l-4.385 2.741a1.5 1.5 0 0 1-1.59 0l-4.385-2.74A6 6 0 0 1 2 11.173zm14.5 0v6.504a4.5 4.5 0 0 1-2.115 3.816L10 17.73l-4.385-2.74A4.5 4.5 0 0 1 3.5 11.175V4.67l6.379-1.595a.5.5 0 0 1 .242 0z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
