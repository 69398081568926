import * as React from 'react';
import {observer} from 'mobx-react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {NotificationsResource} from '@youtoken/ui.resource-notifications';
import {type BoxProps} from '@youtoken/ui.primitives';
import {PromoBannerContent} from './PromoBannerContent';

export const WalletsItemPromoBanner: React.FC<BoxProps> = observer(boxProps => {
  const {
    notifications: {minerPromo, close},
  } = useResources({
    notifications: getResourceDescriptor(NotificationsResource, {}),
  });

  if (!minerPromo) {
    return null;
  }

  return (
    <PromoBannerContent minerPromo={minerPromo} close={close} {...boxProps} />
  );
});
