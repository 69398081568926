import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const UsdcIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Circle cx={16} cy={16} r={16} fill="#2775CA" />
        <Path
          fill="#fff"
          fillRule="evenodd"
          d="M13.067 27.067c0 .4-.267.533-.667.533C7.467 26 4 21.467 4 16.133 4 10.8 7.467 6.267 12.4 4.667c.4-.134.667.133.667.533v.933a.73.73 0 0 1-.4.667C8.8 8.267 6.133 11.867 6.133 16.133c0 4.267 2.8 8 6.534 9.334.266.133.4.4.4.666zm4-3.334c0 .267-.267.534-.534.534h-1.066c-.267 0-.534-.267-.534-.534v-1.6c-2.133-.266-3.2-1.466-3.6-3.2 0-.266.134-.533.4-.533h1.2c.267 0 .4.133.534.4.266.933.8 1.733 2.533 1.733 1.333 0 2.267-.666 2.267-1.733s-.534-1.467-2.4-1.733c-2.8-.4-4.134-1.2-4.134-3.467 0-1.733 1.334-3.067 3.2-3.333v-1.6c0-.267.267-.534.534-.534h1.066c.267 0 .534.267.534.534v1.6c1.6.266 2.666 1.2 2.933 2.666 0 .267-.133.534-.4.534h-1.067c-.266 0-.4-.134-.533-.4-.267-.934-.933-1.334-2.133-1.334-1.334 0-2 .667-2 1.6 0 .934.4 1.467 2.4 1.734 2.8.4 4.133 1.2 4.133 3.466 0 1.734-1.333 3.2-3.333 3.6zm1.866 3.334c0 .4.267.666.667.533C24.533 26 28 21.467 28 16.133c0-5.333-3.6-9.866-8.4-11.466-.4 0-.667.133-.667.533v.933c0 .267.134.534.4.667 3.734 1.333 6.534 5.067 6.534 9.333 0 4.267-2.667 7.867-6.534 9.334-.266.133-.4.4-.4.666z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
