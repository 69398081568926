import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {getTitle} from './utils';
import {
  type MinerResetBlocksContentProps,
  MinerResetBlocksContent,
} from './Content';

export const MinerResetBlocksModal: React.FC<MinerResetBlocksContentProps> =
  cell(({onClose, ...modalProps}) => {
    return (
      <Modal
        isOpen
        title={getTitle()}
        onClose={onClose}
        webMinHeight={0}
        {...modalProps}
      >
        <MinerResetBlocksContent onClose={onClose} />
      </Modal>
    );
  });
