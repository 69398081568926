import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const YoutubeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          fill={color}
          d="M17.376 5.444c-.577-.67-1.644-.944-3.68-.944H6.304c-2.083 0-3.168.291-3.743 1.005C2 6.201 2 7.227 2 8.647v2.706c0 2.75.666 4.147 4.304 4.147h7.392c1.766 0 2.745-.241 3.378-.833.649-.607.926-1.598.926-3.314V8.647c0-1.497-.043-2.53-.624-3.203m-5.104 4.93-3.357 1.713a.53.53 0 0 1-.506-.015.5.5 0 0 1-.249-.431V8.224c0-.176.094-.34.248-.431a.53.53 0 0 1 .506-.017l3.357 1.703c.17.087.278.26.278.447 0 .188-.106.36-.277.448"
        />
      </Svg>
    );
  }
);
