import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ChevronRightIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="m18.35 9.25 9.69 9.69a1.5 1.5 0 0 1 0 2.12l-9.69 9.69a1.768 1.768 0 0 1-2.5-2.5L24.1 20l-8.25-8.25a1.768 1.768 0 1 1 2.5-2.5"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
