import * as React from 'react';
import {observer} from 'mobx-react';
import {G} from 'react-native-svg';
import {type HexGridItemProps} from '../../../../types';
import {MinerOverviewResourceContext} from '../../../../../../../../context';
import {FilledGroup, Background, Border, Timer} from '../../elements';

export const HexGridItemStateMining: React.FC<HexGridItemProps> = observer(
  ({
    hexagon: {
      block: {id, timeLeft},
    },
  }) => {
    const {
      hexagonalGrid: {miningEnd},
    } = React.useContext(MinerOverviewResourceContext).use({});

    const handleFinish = React.useCallback(() => {
      miningEnd(id);
    }, [id, miningEnd]);

    return (
      <G testID="HEX_GRID_ITEM_STATE_MINING">
        <FilledGroup>
          <Background color="$success-02" />
          <Border color="$success-03" />
        </FilledGroup>
        <Timer
          color="$success-01"
          timeLeft={timeLeft}
          onFinish={handleFinish}
        />
      </G>
    );
  }
);
