import * as React from 'react';
import {observer} from 'mobx-react';
import {G} from 'react-native-svg';
import {type HexGridItemProps} from '../../../../types';
import {useHexGridItemStateClaimingEndingAnimation} from './hooks';
import {AnimatedGroup, FilledGroup, Background, Ticker} from '../../elements';

export const HexGridItemStateClaimingEndingAnimated: React.FC<HexGridItemProps> =
  observer(({hexagon}) => {
    return (
      <G testID="HEX_GRID_ITEM_STATE_CLAIMING_ENDING">
        <FilledGroup>
          <Background color="$ui-01" />
        </FilledGroup>
        <AnimatedGroup
          animatedProps={useHexGridItemStateClaimingEndingAnimation(hexagon)}
        >
          <FilledGroup>
            <Ticker color="$attention-01" />
          </FilledGroup>
        </AnimatedGroup>
      </G>
    );
  });
