import * as React from 'react';
import {observer} from 'mobx-react';
import {
  ClaimingCountdown,
  SavingsClaimEarnedButton,
  SavingsStartEarningButton,
} from '@youtoken/ui.surface-savings';
import {
  SavingsOverviewStatus,
  SavingsWidgetResource,
} from '@youtoken/ui.resource-savings';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TouchableWidgetBox} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {type BoxProps} from '@youtoken/ui.primitives';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

const handlePress = () => {
  DATA_LAYER.trackStrict('growth-account-widget-click', {});
  SHARED_ROUTER_SERVICE.navigate('WalletsSavings');
};

export const GrowthAccountWidget: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    data: {showWidget, status, timeLeft},
    earnedInQuoteTickerFormatted,
    tickerSymbol,
    refetchWithDelay,
  } = SavingsWidgetResource.use({});

  if (!showWidget || status === SavingsOverviewStatus.WAITING) {
    return null;
  }

  return (
    <TouchableWidgetBox
      title={t('surface.savings.widget.growth_account')}
      iconName="growth"
      onPress={handlePress}
      testID="GROWTH_ACCOUNT_WIDGET"
      {...boxProps}
    >
      <>
        {(status === SavingsOverviewStatus.NEW ||
          status === SavingsOverviewStatus.CLAIMED) && (
          <SavingsStartEarningButton type="secondary" size="small" mt={12} />
        )}
        {status === SavingsOverviewStatus.READY && (
          <SavingsClaimEarnedButton
            // NOTE: we already have warning for tickerSymbol if it's undefined
            tickerSymbol={tickerSymbol!}
            value={earnedInQuoteTickerFormatted}
            mt={12}
          />
        )}
        {status === SavingsOverviewStatus.OPEN && (
          <ClaimingCountdown
            tickerSymbol={tickerSymbol!}
            amountFormatted={earnedInQuoteTickerFormatted}
            // NOTE: ClaimingCountdown has warning for timeLeft if it's undefined
            timeLeft={timeLeft!}
            onTimerEnd={refetchWithDelay}
          />
        )}
      </>
    </TouchableWidgetBox>
  );
});
