import React from 'react';
import {type GestureResponderEvent} from 'react-native';
import {observer} from 'mobx-react';
import {Button, type IButtonProps} from '@youtoken/ui.buttons';
import {useForm} from '../Form';

export const SubmitButton: React.FC<React.ComponentProps<typeof Button>> =
  observer(
    ({disabled, loading, testID = 'SUBMIT_BUTTON', onPress, ...props}) => {
      const {
        form: {instance},
      } = useForm();

      const handlePress: IButtonProps['onPress'] = React.useCallback(
        (event?: GestureResponderEvent) => {
          onPress?.();
          instance.onSubmit(event);
        },
        [instance, onPress]
      );

      return (
        <Button
          disabled={disabled || instance.submitting}
          loading={loading || instance.submitting}
          onPress={handlePress}
          size="large"
          testID={testID}
          domType="submit"
          {...props}
        />
      );
    }
  );
