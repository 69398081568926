import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {UmaWelcomeSurface} from '../UmaWelcomeSurface';
import {UmaInfo, TickerSelector} from './components';
import {LightSparkLogo} from '../../components';

const handlePressCreate = () => {
  SHARED_ROUTER_SERVICE.navigate('UmaCreate');
};

export const UmaSettingsSurface: React.FC = observer(() => {
  const {t} = useTranslation();

  const {isUmaOpened} = UmaInfoResource.use({});

  React.useEffect(() => {
    const {
      data: {
        products: {
          uma: {
            available,
            settings: {enable},
          },
        },
      },
    } = AuthMeResource.getInstance({});

    if (!enable || !available) {
      SHARED_ROUTER_SERVICE.navigate('More', {});
    }
  }, []);

  if (!isUmaOpened) {
    return <UmaWelcomeSurface onPress={handlePressCreate} />;
  }

  return (
    <>
      <Box
        justifyContent={{default: 'space-between', desktop: undefined}}
        flexGrow={{default: 1, desktop: undefined}}
        pb={24}
      >
        <Box zIndex={1}>
          <Text color="$text-02" variant="$body-02" py={20} px={24}>
            {t('surface.wallets.uma.create.explanation')}
          </Text>
          <Separator />
          <UmaInfo />
          <Separator />
          <TickerSelector />
        </Box>
        <LightSparkLogo />
      </Box>
    </>
  );
});
