import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const VideoIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M26 8a4 4 0 0 1 4 4v2.398l4.75-3.798A2 2 0 0 1 38 12.16V27.84a2 2 0 0 1-3.25 1.562L30 25.6V28a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V12a4 4 0 0 1 4-4zm0 3H6a1 1 0 0 0-.984.82L5 12v16a1 1 0 0 0 .82.984L6 29h20a1 1 0 0 0 .984-.82L27 28V12a1 1 0 0 0-.82-.984zm9 14.756V14.24l-7.2 5.758z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
