import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CryptoWithdrawIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          fill="#2979FF"
          fillRule="evenodd"
          d="M21.215 2.605a.6.6 0 0 1 .405.654l-.025.105-5.926 17.777a.6.6 0 0 1-1.062.153l-.053-.095-3.518-7.738a.6.6 0 0 0-.204-.244l-.094-.054L3 9.646a.6.6 0 0 1-.04-1.072l.099-.043 17.777-5.926a.6.6 0 0 1 .38 0zm-3.747 2.853L7.071 9.105a.3.3 0 0 0-.024.556l4.291 1.927zm1.273 1.273-6.13 6.13 1.928 4.292a.3.3 0 0 0 .556-.024z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
