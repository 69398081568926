import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {getTitle} from './utils';
import {
  type MinerGetMoreSparksContentProps,
  MinerGetMoreSparksContent,
} from './Content';

export const MinerGetMoreSparksModal: React.FC<MinerGetMoreSparksContentProps> =
  cell(({noticeText, onClose, ...modalProps}) => {
    return (
      <Modal
        isOpen
        title={getTitle()}
        shouldShowHeaderSeparator={false}
        onClose={onClose}
        {...modalProps}
      >
        <MinerGetMoreSparksContent noticeText={noticeText} onClose={onClose} />
      </Modal>
    );
  });
