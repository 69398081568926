import {computedFn} from 'mobx-utils';
import big, {type BigSource} from 'big.js';
import {createResource} from '@youtoken/ui.data-storage';
import {deserialize} from 'serializr';
import {FeeItemResponse} from './FeeResponse';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import {parseDateForLokalise} from '@youtoken/ui.formatting-utils';

export {FeeItemResponse};

export class FeeAllResource extends createResource({
  getKey: () => `FeeAllResource`,
  getData: () =>
    TRANSPORT.API.get('/v1/fee/all').then(res => {
      return deserialize(FeeItemResponse, res.data as any[]);
    }),
}) {
  getTemplate = computedFn((feeString: string): string => {
    return i18n.t('surface.fee_string.template', {feeString});
  });

  //+
  getFee = computedFn(
    (
      method: string,
      provider: string | undefined,
      ticker: string,
      conversionTicker: string
    ) => {
      return this.data.find(
        fee =>
          fee.method === method &&
          fee.provider === provider &&
          fee.ticker === ticker &&
          fee.conversionTicker === conversionTicker
      );
    }
  );

  getFeeEstimationPeriodLocalize = computedFn((fee: FeeItemResponse) => {
    if (!fee?.estimationPeriod) {
      return '';
    }

    const [amount, unit] = parseDateForLokalise(fee?.estimationPeriod) || [];

    if (!amount || !unit) {
      return '';
    }

    return [
      i18n.t('surface.wallets.withdraw_crypto.eta'),
      // @ts-ignore
      i18n.t(`common.period.${unit}`, {count: +amount}),
    ].join(' ');
  });

  //+
  getFeeWithoutProvider = computedFn(
    (method: string, ticker: string, conversionTicker: string) => {
      return this.data.find(
        fee =>
          fee.method === method &&
          fee.ticker === ticker &&
          fee.conversionTicker === conversionTicker
      );
    }
  );

  getFeeForProviderDeposit = computedFn(
    (
      provider:
        | 'unlimintApplePay'
        | 'unlimintBankCard'
        | 'checkoutBankCard'
        | 'unlimintSPEI'
        | 'unlimintCoDi'
        | 'uma',
      ticker: string,
      conversionTicker: string
    ) => {
      return this.getFee('deposit', provider, ticker, conversionTicker);
    }
  );

  //+
  getFeeForDeposit = computedFn((provider: string | 'auto', ticker: string) => {
    const feeWithProvider = this.getFee('deposit', provider, ticker, ticker);

    if (feeWithProvider) {
      return feeWithProvider;
    }

    return this.getFeeWithoutProvider('deposit', ticker, ticker);
  });

  //+
  getFeeForWithdraw = computedFn(
    (ticker: string, provider?: string | 'auto') => {
      const feeWithProvider = this.getFee('withdraw', provider, ticker, ticker);

      if (feeWithProvider) {
        return feeWithProvider;
      }

      return this.getFeeWithoutProvider('withdraw', ticker, ticker);
    }
  );

  //+
  getFeeForExchange = computedFn((ticker: string, conversionTicker: string) => {
    return this.getFeeWithoutProvider('exchange', ticker, conversionTicker);
  });

  //+
  feeInfo = computedFn(
    (ticker: string, percent: BigSource, min: BigSource, max: BigSource) => {
      let percentPrepared;
      try {
        percentPrepared = big(percent).mul(100);
      } catch (e) {
        percentPrepared = big(0);
      }

      const minLabel = i18n.t('surface.fee_string.template.min');
      let minPrepared;
      try {
        minPrepared = big(min);
      } catch (e) {
        minPrepared = big(0);
      }

      const maxLabel = i18n.t('surface.fee_string.template.max');
      let maxPrepared;
      try {
        maxPrepared = big(max);
      } catch (e) {
        maxPrepared = big(0);
      }

      if (percentPrepared.gt(0)) {
        if (minPrepared.gt(0) || maxPrepared.gt(0)) {
          if (minPrepared.gt(0) && maxPrepared.gt(0)) {
            return this.getTemplate(
              `${percentPrepared}%, ${minLabel} ${minPrepared} ${ticker.toUpperCase()}, ${maxLabel} ${maxPrepared} ${ticker.toUpperCase()}`
            );
          }
          if (minPrepared.gt(0)) {
            return this.getTemplate(
              `${percentPrepared}%, ${minLabel} ${minPrepared} ${ticker.toUpperCase()}`
            );
          }
          if (maxPrepared.gt(0)) {
            return this.getTemplate(
              `${percentPrepared}%, ${maxLabel} ${maxPrepared} ${ticker.toUpperCase()}`
            );
          }
        } else {
          return this.getTemplate(`${percentPrepared}%`);
        }
      } else {
        if (minPrepared.gt(0) && maxPrepared.gt(0)) {
          return this.getTemplate(
            `${minLabel} ${minPrepared} ${ticker.toUpperCase()}, ${maxLabel} ${maxPrepared} ${ticker.toUpperCase()}`
          );
        }
        if (minPrepared.gt(0)) {
          return this.getTemplate(`${minPrepared} ${ticker.toUpperCase()}`);
        }
        if (maxPrepared.gt(0)) {
          return this.getTemplate(`${maxPrepared} ${ticker.toUpperCase()}`);
        }
      }

      return this.getTemplate('0%');
    }
  );

  //+
  calculateFee = computedFn(
    (
      method: string,
      provider: string,
      ticker: string,
      conversionTicker: string,
      amount: BigSource
    ) => {
      const fee = this.getFee(method, provider, ticker, conversionTicker);

      if (!fee) {
        return big(0);
      }

      let amountPrepared;
      try {
        amountPrepared = big(amount);
      } catch (e) {
        amountPrepared = big(0);
      }

      const feeAmount = big(amountPrepared).times(fee.percent || 0);

      if (fee.min.gt(0) && feeAmount.lt(fee.min)) {
        return fee.min;
      }

      if (fee.max.gt(0) && feeAmount.gt(fee.max)) {
        return fee.max;
      }

      return feeAmount;
    }
  );
}
