import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ExternalLinkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M3.75 5c0-.69.56-1.25 1.25-1.25h2a.75.75 0 0 0 0-1.5H5A2.75 2.75 0 0 0 2.25 5v10A2.75 2.75 0 0 0 5 17.75h10A2.75 2.75 0 0 0 17.75 15v-2a.75.75 0 0 0-1.5 0v2c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25z"
        />
        <Path
          fill={color}
          d="M12 2.25a.75.75 0 0 0 0 1.5h3.19L9.47 9.47a.75.75 0 1 0 1.06 1.06l5.72-5.72V8a.75.75 0 0 0 1.5 0V3a.75.75 0 0 0-.75-.75z"
        />
      </Svg>
    );
  }
);
