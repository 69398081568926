import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const LinkedinIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          fill={color}
          fillRule="evenodd"
          d="M13.894 6.884c3.618 0 4.106 2.621 4.106 5.185v5.672a.26.26 0 0 1-.258.259h-3.206a.26.26 0 0 1-.258-.259v-5.028c0-1.407-.104-2.484-1.41-2.484-1.2 0-1.667.671-1.667 2.396v5.116a.26.26 0 0 1-.258.259H7.738a.26.26 0 0 1-.258-.259V7.4c0-.143.116-.259.258-.259h3.076c.143 0 .258.116.258.259v.671c.579-.631 1.532-1.187 2.822-1.187m-8.17.257c.143 0 .258.116.258.259v10.34a.26.26 0 0 1-.258.259H2.512a.26.26 0 0 1-.258-.259V7.4c0-.143.116-.259.258-.259zM4.12 2a2.122 2.122 0 0 1 0 4.245A2.124 2.124 0 0 1 4.12 2"
        />
      </Svg>
    );
  }
);
