import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const WalletsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M18 3a3 3 0 0 1 3 3v2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2v2a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 2H6a1 1 0 0 0-.993.883L5 6v12a1 1 0 0 0 .883.993L6 19h12a1 1 0 0 0 .993-.883L19 18v-2.001L16 16a4 4 0 0 1 0-8l3-.001V6a1 1 0 0 0-.883-.993zm3 5h-5a2 2 0 0 0-.15 3.995L16 14h5zm-5 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
