import React, {useState, useRef} from 'react';
import {ScrollView, LayoutChangeEvent} from 'react-native';
import {Box, useIsMobile} from '@youtoken/ui.primitives';

type AppliedFiltersWrapperProps = {
  scrollOffset: number;
  children: React.ReactNode;
};

export const AppliedFiltersWrapper: React.FC<AppliedFiltersWrapperProps> = ({
  scrollOffset,
  children,
}) => {
  const isMobile = useIsMobile();
  const [isScrollable, setIsScrollable] = useState(false);
  const containerWidthRef = useRef<number>(0);
  const contentWidthRef = useRef<number>(0);

  const checkScrollable = () => {
    if (isMobile) {
      setIsScrollable(contentWidthRef.current > containerWidthRef.current);
    }
  };

  const handleContainerLayout = (event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout;
    containerWidthRef.current = width;
    checkScrollable();
  };

  const handleContentLayout = (event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout;
    contentWidthRef.current = width;
    checkScrollable();
  };

  if (isMobile) {
    return (
      <Box width="100%" onLayout={handleContainerLayout}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEnabled={isScrollable}
        >
          <Box
            flexDirection="row"
            pl={scrollOffset}
            pr={scrollOffset - 8}
            onLayout={handleContentLayout}
          >
            {children}
          </Box>
        </ScrollView>
      </Box>
    );
  }

  return (
    <Box
      flex={1}
      flexDirection="row"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      {children}
    </Box>
  );
};
