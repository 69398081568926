import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CurrencyIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M8.214 13.246q.486.15 1.265.205v1.32h1.125v-1.353q1.016-.15 1.46-.67.443-.519.443-1.233 0-.475-.173-.822a1.75 1.75 0 0 0-.454-.594 2.8 2.8 0 0 0-.693-.444 10 10 0 0 0-.832-.367q-.315-.107-.563-.206a3 3 0 0 1-.421-.216.9.9 0 0 1-.26-.26.73.73 0 0 1-.086-.367q0-.367.292-.573.291-.216.876-.217.551 0 .973.109.432.107.746.227l.27-1.07a4.6 4.6 0 0 0-.649-.196 5.5 5.5 0 0 0-.93-.15V5.2H9.48v1.211q-.92.151-1.363.682-.443.519-.443 1.265 0 .454.162.768.162.313.422.54.27.216.595.368.335.15.67.27.357.13.649.249a3 3 0 0 1 .519.26q.216.14.335.324t.12.432a.72.72 0 0 1-.293.595q-.281.216-1.027.216-.627 0-1.125-.14a5 5 0 0 1-.832-.314L7.5 12.953q.238.13.714.293"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M18.5 10a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
