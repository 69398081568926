import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const VisaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="m18.186 13.06-.184-.921h-2.058l-.327.914-1.65.003q1.179-2.832 2.361-5.662c.134-.32.372-.483.724-.481q.402.003 1.314 0l1.275 6.144zm-1.779-2.183h1.326l-.495-2.308zM5.777 6.913l1.659.002-2.563 6.146-1.679-.001a778 778 0 0 1-1.25-4.875c-.082-.324-.244-.55-.556-.658Q.698 7.295 0 7.082v-.167h2.649c.458 0 .726.221.812.676q.13.684.655 3.482zm3.937.002-1.311 6.145-1.578-.002 1.31-6.145zm3.2-.114c.472 0 1.067.147 1.409.282l-.277 1.275c-.309-.125-.818-.292-1.246-.287-.622.01-1.007.272-1.007.522 0 .408.668.613 1.356 1.059.785.507.888.963.878 1.458-.01 1.028-.878 2.042-2.708 2.042-.835-.012-1.136-.083-1.817-.324l.288-1.33c.693.29.987.383 1.58.383.542 0 1.008-.22 1.012-.602.003-.271-.164-.406-.773-.742-.608-.336-1.463-.802-1.451-1.737.014-1.196 1.147-1.999 2.757-1.999z"
        />
      </Svg>
    );
  }
);
