import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

export type AccountLevelProgressBarSparksProps = {
  value: number;
};

export const AccountLevelProgressBarSparks: React.FC<
  AccountLevelProgressBarSparksProps & BoxProps
> = observer(({value, ...boxProps}) => {
  const {t} = useTranslation();

  const isActive = value > 0;

  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_SPARKS"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      px={16}
      py={5}
      bg={isActive ? '$attention-02' : '$ui-01'}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Text
          variant="$body-02-medium-accent"
          color={isActive ? '$attention-01' : '$text-03'}
        >
          {t('surface.loyalty.progress_bar.you_will_get_sparks_prefix')}
        </Text>
        <Icon
          name="spark"
          size={16}
          color={isActive ? '$attention-01' : '$text-03'}
        />
      </Box>
      <Text
        variant="$body-02-medium-accent"
        color={isActive ? '$attention-01' : '$text-03'}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        <Trans
          i18nKey="surface.loyalty.progress_bar.sparks"
          components={{
            Strong: (
              <Text
                variant="$body-02-medium-accent"
                testID="ACCOUNT_LEVEL_FORECAST_SPARKS_COUNT"
              />
            ),
          }}
          values={{
            count: value,
          }}
        />
      </Text>
    </Box>
  );
});
