import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DualIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M14.097 10.281q.487.27.658.677.17.408-.05 1.016-.114.31-.29.522-.177.211-.391.32t-.5.144q-.284.035-.579.006a6 6 0 0 1-.664-.111L11.984 14l-.684-.189.293-1.127-.243-.068-.298-.086-.294 1.131-.684-.189.297-1.145-.3-.086L9 11.944l.351-.784.493.136q.222.061.317-.158l.47-1.805.07.02-.019-.008-.05-.016.335-1.29q.021-.32-.316-.414l-.493-.136.191-.737.94.265.22.06.213.055L12.016 6l.684.189-.289 1.11.172.042.373.098.286-1.1.683.189-.294 1.131q.343.129.596.273t.45.34q.195.197.275.452.079.255.028.56-.132.838-.883.997M12.215 8.11l-.359 1.379.318.09.075.02.262.059a2 2 0 0 0 .233.032l.188.01a.8.8 0 0 0 .237-.024q.098-.029.196-.076a.44.44 0 0 0 .165-.14.7.7 0 0 0 .1-.218.6.6 0 0 0 .012-.278.7.7 0 0 0-.086-.226.7.7 0 0 0-.18-.185 1.6 1.6 0 0 0-.226-.141 3 3 0 0 0-.325-.134l-.106-.035-.087-.025zm1.073 3.574a.7.7 0 0 1-.156.15.6.6 0 0 1-.21.086 1.5 1.5 0 0 1-.499.03l-.141-.014-.122-.017-.122-.023-.142-.03-.2-.05-.189-.052-.225-.066.395-1.518.343.087.156.042.102.03.128.042.073.026q.192.067.319.131.126.064.269.163a.712.712 0 0 1 .33.46.6.6 0 0 1-.008.306.6.6 0 0 1-.101.217"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M8 18c.69 0 1.36-.087 2-.252a8 8 0 1 0 0-15.496A8 8 0 1 0 8 18M7.281 3.54a6.501 6.501 0 0 0 0 12.92A7.99 7.99 0 0 1 4 10a7.99 7.99 0 0 1 3.281-6.46M12 16.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
