import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ApplePayIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 49 20"
        {...props}
      >
        <Path
          fill={color}
          d="M9.678 3.45c-.542.641-1.41 1.148-2.277 1.075-.109-.868.316-1.79.813-2.359.542-.66 1.491-1.13 2.26-1.166.09.904-.262 1.79-.796 2.45m.787 1.247c-1.257-.072-2.332.714-2.929.714-.605 0-1.518-.678-2.513-.66-1.292.018-2.494.75-3.154 1.916-1.356 2.333-.353 5.785.958 7.684.642.94 1.41 1.97 2.422 1.934.958-.036 1.338-.624 2.495-.624 1.166 0 1.5.624 2.513.606 1.048-.018 1.708-.94 2.35-1.88.732-1.067 1.03-2.106 1.049-2.16-.019-.019-2.025-.787-2.043-3.1-.018-1.935 1.582-2.857 1.654-2.911-.904-1.338-2.314-1.483-2.802-1.519m7.258-2.621v14.092h2.188V11.35h3.028c2.765 0 4.709-1.899 4.709-4.646 0-2.748-1.907-4.628-4.637-4.628zm2.188 1.844h2.521c1.899 0 2.983 1.012 2.983 2.793 0 1.78-1.084 2.802-2.992 2.802h-2.512zm11.732 12.356c1.374 0 2.649-.696 3.227-1.799h.045v1.69h2.025V9.154c0-2.034-1.627-3.344-4.13-3.344-2.324 0-4.041 1.328-4.105 3.154h1.971c.163-.867.967-1.437 2.07-1.437 1.338 0 2.088.624 2.088 1.772v.777l-2.73.163c-2.54.154-3.914 1.193-3.914 3 0 1.827 1.42 3.038 3.453 3.038m.588-1.672c-1.166 0-1.908-.56-1.908-1.42 0-.885.715-1.4 2.08-1.482l2.43-.153v.795c0 1.32-1.12 2.26-2.602 2.26M39.643 20c2.133 0 3.136-.814 4.013-3.281l3.842-10.775h-2.224l-2.576 8.325h-.045l-2.576-8.325H37.79l3.706 10.26-.2.623c-.334 1.058-.876 1.465-1.843 1.465-.172 0-.506-.018-.642-.037v1.69c.127.037.669.055.832.055"
        />
      </Svg>
    );
  }
);
