import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M17 3.5a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2zm.5 5h-15v6a.5.5 0 0 0 .41.492L3 15h14a.5.5 0 0 0 .492-.41l.008-.09zM5.75 10a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1 0-1.5zm6 0a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5zM17 5H3a.5.5 0 0 0-.492.41L2.5 5.5V7h15V5.5a.5.5 0 0 0-.41-.492z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
