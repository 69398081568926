import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FriendsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.667}
          d="M13.333 3.697a3.75 3.75 0 0 1 0 6.72M15 14.78c1.26.57 2.394 1.499 3.333 2.695m-16.666 0c1.622-2.065 3.824-3.334 6.25-3.334 2.425 0 4.628 1.269 6.25 3.334m-2.5-10.417a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0"
        />
      </Svg>
    );
  }
);
