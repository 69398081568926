import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ConvertIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <G fill={color}>
          <Path d="m32.063 13.257-6.394-5.755C25.025 6.922 24 7.38 24 8.245V12H7.5a1.5 1.5 0 0 0 0 3h23.894c.916 0 1.35-1.13.669-1.743M5.937 26.743l6.394 5.755c.644.58 1.669.122 1.669-.743V28h16.5a1.5 1.5 0 0 0 0-3H6.606c-.917 0-1.35 1.13-.669 1.743" />
        </G>
      </Svg>
    );
  }
);
