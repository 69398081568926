import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const IncreasePositionIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 12.293a1 1 0 0 0 .993-.883l.007-.117v-5h1.293a.5.5 0 0 0 .411-.784l-.058-.07-2.292-2.293a.5.5 0 0 0-.638-.057l-.07.057L7.354 5.44a.5.5 0 0 0 .263.846l.09.008H9v5a1 1 0 0 0 1 1m-5.5 4a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2zm4 0a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2zm4 0a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2zm4 0a1 1 0 1 0 0-2h-1a1 1 0 1 0 0 2z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
