import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DiscountIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16M6.971 7.69a.508.508 0 1 1 .72-.719.508.508 0 0 1-.72.72m-1.06-1.78a2.008 2.008 0 1 0 2.84 2.84 2.008 2.008 0 0 0-2.84-2.84m0 7.118a.75.75 0 0 0 1.06 1.061l7.118-7.118a.75.75 0 1 0-1.06-1.06zm6.399 0a.508.508 0 1 1 .718-.718.508.508 0 0 1-.718.718m-1.061-1.779a2.008 2.008 0 1 0 2.84 2.84 2.008 2.008 0 0 0-2.84-2.84"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
