import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const AntiPhishingIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 21"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M13.5 8.792a2.5 2.5 0 1 0-1.5.158V12a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2v-2h2L6 7v4.75a3.75 3.75 0 1 0 7.5 0zm-1-1.292a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2 3.671a1.5 1.5 0 0 1 1.136-1.455L9.515.62a2 2 0 0 1 .97 0l6.379 1.595A1.5 1.5 0 0 1 18 3.671v8.504a6 6 0 0 1-2.82 5.087l-4.385 2.741a1.5 1.5 0 0 1-1.59 0l-4.385-2.74A6 6 0 0 1 2 12.173zm14.5 0v8.504a4.5 4.5 0 0 1-2.115 3.816L10 18.73l-4.385-2.74A4.5 4.5 0 0 1 3.5 12.175V3.67l6.379-1.595a.5.5 0 0 1 .242 0z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
