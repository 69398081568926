import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const IdVerificationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M6.25 1.75A.75.75 0 0 0 5.5 1H3a2 2 0 0 0-2 2v2.5a.75.75 0 0 0 1.5 0v-2l.007-.117A1 1 0 0 1 3.5 2.5h2l.102-.007a.75.75 0 0 0 .648-.743m-4.5 12a.75.75 0 0 1 .743.648l.007.102v2a1 1 0 0 0 .883.993l.117.007h2a.75.75 0 0 1 0 1.5H3a2 2 0 0 1-2-2v-2.5a.75.75 0 0 1 .75-.75m16.5 0a.75.75 0 0 1 .743.648L19 14.5V17a2 2 0 0 1-2 2h-2.5a.75.75 0 0 1 0-1.5h2a1 1 0 0 0 .993-.883l.007-.117v-2a.75.75 0 0 1 .75-.75M17 1a2 2 0 0 1 2 2v2.5a.75.75 0 0 1-1.5 0v-2a1 1 0 0 0-1-1h-2a.75.75 0 0 1 0-1.5z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M13 7.5c0 .956-.447 1.808-1.144 2.357A5 5 0 0 1 15 14.5a.75.75 0 0 1-1.5 0 3.5 3.5 0 1 0-7 0 .75.75 0 0 1-1.5 0 5 5 0 0 1 3.144-4.643A3 3 0 1 1 13 7.5M10 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
