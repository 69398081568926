import {
  currencyName,
  getCoinDecimalPrecision,
  getCoinSymbol,
} from '@youtoken/ui.coin-utils';
import {formatToNSignificantDigits, toBig} from '@youtoken/ui.formatting-utils';
import {type AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type RatesResource} from '@youtoken/ui.resource-rates';
import {type WalletsResource} from '@youtoken/ui.resource-wallets';
import {i18n} from '@youtoken/ui.service-i18n';
import {Big, type BigSource} from 'big.js';

const AMOUNTS = [100, 200, 500];

const formatLabel = (_amount: BigSource, currencyName: currencyName) => {
  const symbol = getCoinSymbol(currencyName);
  const amount = toBig(_amount).toString();

  return `${symbol}${amount}`;
};

const formatEquivalent = (amountEquivalent: Big, equivalentTicker: string) => {
  if (getCoinDecimalPrecision(equivalentTicker) === 2) {
    return amountEquivalent.toFixed(2);
  }
  if (amountEquivalent.lt(1)) {
    return formatToNSignificantDigits({value: amountEquivalent, digits: 3});
  }
  return amountEquivalent.toFixed(amountEquivalent.lt(100) ? 2 : 1);
};

export type PredefinedAmountButtonData = {
  label: string;
  amountBaseCurrency: string;
  amountEquivalent: string;
};

export const getAmountButtons = ({
  rates,
  wallets,
  authMe,
  equivalentTicker,
}: {
  rates: RatesResource;
  wallets: WalletsResource;
  authMe: AuthMeResource;
  equivalentTicker: string;
}): Array<PredefinedAmountButtonData> => {
  const {getRate} = rates;
  const {mainCurrency} = authMe;
  const {getByTicker} = wallets;

  const wallet = getByTicker(equivalentTicker);
  const equivalentTickerBalance = wallet?.amount;
  const equivalentTickerBalanceBig = toBig(equivalentTickerBalance);

  const allItem = equivalentTickerBalance
    ? {
        label: i18n.t('common.actions.all_smth', {
          text: equivalentTicker.toUpperCase(),
        }),
        amountBaseCurrency: equivalentTickerBalanceBig
          .div(getRate(mainCurrency, equivalentTicker))
          .toFixed(getCoinDecimalPrecision(mainCurrency)),
        amountEquivalent: equivalentTickerBalanceBig.toFixed(
          getCoinDecimalPrecision(equivalentTicker)
        ),
      }
    : null;

  const constantItems = AMOUNTS.map(amount => {
    const rate = getRate(mainCurrency, equivalentTicker);
    const amountEquivalent = toBig(amount).mul(rate);

    return {
      label: formatLabel(amount, mainCurrency),
      amountBaseCurrency: String(amount),
      amountEquivalent: formatEquivalent(amountEquivalent, equivalentTicker),
    };
  });

  if (!allItem) {
    return constantItems;
  }

  return [...constantItems, allItem];
};
