import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FilterIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M8 9a3 3 0 0 0 2.906-2.25H17.5a.75.75 0 0 0 0-1.5h-6.594a3.001 3.001 0 0 0-5.811 0H2.5a.75.75 0 0 0 0 1.5h2.595A3 3 0 0 0 8 9m0-1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M14.906 14.75a3.001 3.001 0 0 1-5.811 0H2.5a.75.75 0 0 1 0-1.5h6.595a3.001 3.001 0 0 1 5.81 0H17.5a.75.75 0 0 1 0 1.5zM13.5 14a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
