import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const HideIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M34.142 5.858a1 1 0 0 1 0 1.414l-26.87 26.87a1 1 0 0 1-1.414-1.414l26.87-26.87a1 1 0 0 1 1.414 0M31 12.544q3.242 2.648 6.524 6.84a1 1 0 0 1 .104 1.07l-.104.163-.54.68Q28.345 32 20 32q-3.344 0-6.735-1.718l2.268-2.267Q17.807 29 20 29q6.627 0 14-9-2.636-3.218-5.176-5.285zM20 8q3.351 0 6.749 1.726l-2.266 2.266Q22.2 11 20 11q-6.627 0-14 9 2.641 3.224 5.186 5.293l-2.175 2.171q-3.246-2.65-6.534-6.847a1 1 0 0 1-.104-1.07l.104-.164.54-.68Q11.655 8 20 8m6 12a6 6 0 0 1-8.082 5.629l2.689-2.69a3 3 0 0 0 2.33-2.328l.607-.612zm-6-6-.002 2.475-.578.581a3 3 0 0 0-2.363 2.36l-2.68 2.68a6 6 0 0 1 5.271-8.086z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
