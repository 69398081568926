import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const GainIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 12 12"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="m3.954 8.243 2.117-3.528a.5.5 0 0 1 .858 0l2.117 3.528a.5.5 0 0 1-.43.757H4.384a.5.5 0 0 1-.429-.757"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
