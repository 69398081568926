import * as React from 'react';
import {observer} from 'mobx-react';
import {AccountLevelProgressWidgetUnauthorized} from '@youtoken/ui.surface-account-levels';
import {MinerOverviewUnauthorisedResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {MinerOverviewResourceContext} from '../context';
import {
  HexGrid,
  HowItWorksBanner,
  SparkForVolumeWithContext,
} from '../components';

export const MinerUnauthorizedSurfaceCell: React.FC = cell(() => {
  return (
    <MinerOverviewResourceContext.Provider
      value={MinerOverviewUnauthorisedResource}
    >
      <Box
        pb={16}
        borderWidth={1}
        borderRadius={16}
        borderColor="$ui-01"
        overflow="hidden"
      >
        <Box flexDirection="row" justifyContent="center">
          <HexGrid />
        </Box>

        <Box px={40}>
          <HowItWorksBanner />
        </Box>

        <Separator my={24} />

        <Box px={40}>
          <AccountLevelProgressWidgetUnauthorized />
          <ActionButtonWithContext />
          <SparkForVolumeWithContext />
        </Box>
      </Box>
    </MinerOverviewResourceContext.Provider>
  );
});

export const ActionButtonWithContext: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    hexagonalGrid: {miningStart},
  } = React.useContext(MinerOverviewResourceContext).use({});

  return (
    <Button my={16} size="large" onPress={miningStart}>
      {t('surface.welcome.ledger_miner_action_button')}
    </Button>
  );
});
