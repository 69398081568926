import {iconSet, type IconName} from '../__generated/Loyalty';
import {createIconComponent} from '../createIcon';

export {iconSet};
export type {IconName};

export const LoyaltyIcon = createIconComponent<IconName, typeof iconSet>({
  componentName: 'LoyaltyIcon',
  iconSet: iconSet,
  defaultSize: 20,
  defaultColor: '$text-01',
});
