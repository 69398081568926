import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DemoFundsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M6.801 11.318q.226-.562.876-.931-1.001-.219-1.177-1.373a1.7 1.7 0 0 1 .038-.768q.105-.35.367-.62.263-.27.6-.47.337-.198.794-.375l-.392-1.556.912-.26.38 1.513.498-.134.228-.059-.384-1.525.912-.26.392 1.556.285-.076.291-.081 1.254-.364.255 1.012-.657.188q-.45.128-.421.57l.446 1.772-.068.022-.025.011.095-.027.625 2.482q.128.301.423.217l.657-.187.469 1.078-1.429.409-.4.118.397 1.575-.912.26-.392-1.557-.397.12-.324.092.39 1.55-.911.26-.397-1.575a7 7 0 0 1-.885.153q-.393.04-.773-.008a1.9 1.9 0 0 1-.666-.197q-.285-.15-.52-.441a2.4 2.4 0 0 1-.387-.717q-.294-.836-.067-1.397m3.864-2.021L10.188 7.4l-.556.149q-.052.013-.116.034l-.143.049-.081.03a3 3 0 0 0-.352.153 2 2 0 0 0-.3.195 1 1 0 0 0-.24.254.9.9 0 0 0-.116.31.9.9 0 0 0 .017.383.9.9 0 0 0 .133.3q.09.126.22.192.13.065.26.104a1 1 0 0 0 .317.032l.25-.013.072-.005q.102-.01.239-.038l.35-.082.1-.026zm-1.7 3.224a.88.88 0 0 1-.344-.504.9.9 0 0 1-.01-.42 1 1 0 0 1 .147-.347q.102-.149.293-.286.19-.137.36-.224a3.6 3.6 0 0 1 .522-.216l.17-.058.136-.041.209-.058.457-.12.526 2.088-.3.09-.25.073-.268.067-.19.042-.163.032-.162.024-.189.02a2 2 0 0 1-.35.007 2 2 0 0 1-.314-.05.8.8 0 0 1-.28-.119"
          clipRule="evenodd"
        />
        <Path
          stroke={color}
          strokeWidth={1.5}
          d="M17.75 10a7.75 7.75 0 1 1-15.5 0 7.75 7.75 0 0 1 15.5 0Z"
        />
      </Svg>
    );
  }
);
