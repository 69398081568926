import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const StarIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10.295 2.577a.6.6 0 0 1 .228.228l2.199 3.902a.6.6 0 0 0 .404.294l4.39.885a.6.6 0 0 1 .323.995l-3.031 3.297a.6.6 0 0 0-.154.475l.514 4.449a.6.6 0 0 1-.846.614l-4.072-1.864a.6.6 0 0 0-.5 0l-4.072 1.864a.6.6 0 0 1-.846-.614l.514-4.45a.6.6 0 0 0-.154-.474L2.161 8.88a.6.6 0 0 1 .323-.995l4.39-.885a.6.6 0 0 0 .404-.294l2.2-3.901a.6.6 0 0 1 .817-.229m-.197 2.784a.2.2 0 0 0-.232.026l-.04.05-1.606 2.85a.2.2 0 0 1-.08.078l-.055.02-3.206.646a.2.2 0 0 0-.143.278l.036.054 2.213 2.407a.2.2 0 0 1 .05.101l.001.058-.375 3.249a.2.2 0 0 0 .23.22l.052-.016 2.974-1.36a.2.2 0 0 1 .111-.017l.055.016 2.974 1.361a.2.2 0 0 0 .281-.15l.001-.054-.376-3.25a.2.2 0 0 1 .02-.11l.032-.048 2.213-2.407a.2.2 0 0 0-.057-.314l-.05-.018-3.206-.647a.2.2 0 0 1-.1-.052l-.035-.046-1.606-2.849a.2.2 0 0 0-.076-.076"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
