import * as React from 'react';
import {observer} from 'mobx-react';
import {type HexGridItemProps} from './types';
import {
  HexGridItemWrap,
  HexGridItemStateAnimated,
  HexGridItemTrigger,
} from './components';

export const HexGridItem: React.FC<HexGridItemProps> = observer(({hexagon}) => {
  return (
    <HexGridItemWrap hexagon={hexagon}>
      <HexGridItemStateAnimated hexagon={hexagon} />
      <HexGridItemTrigger hexagon={hexagon} />
    </HexGridItemWrap>
  );
});
