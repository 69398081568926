import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const LinkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M11.136 3.179a4.04 4.04 0 0 1 2.85-1.18 4.04 4.04 0 0 1 2.85 1.18l.05.05-.014.027A4.03 4.03 0 0 1 18 6.057a4.05 4.05 0 0 1-1.177 2.852l-3.092 3.084a4.04 4.04 0 0 1-2.85 1.179 4.04 4.04 0 0 1-2.85-1.18 4 4 0 0 1-.414-.488c-.277-.385-.172-.896.148-1.216l.359-.358a.24.24 0 0 1 .243-.058.28.28 0 0 1 .175.17c.124.325.315.623.562.87a2.53 2.53 0 0 0 3.568 0l3.09-3.084a2.5 2.5 0 0 0 .73-1.77 2.52 2.52 0 0 0-.73-1.772 2.53 2.53 0 0 0-3.567 0l-1.66 1.658-.055-.015a5.2 5.2 0 0 0-1.358-.182.232.232 0 0 1-.163-.396z"
        />
        <Path
          fill={color}
          d="M6.278 8.007a4.04 4.04 0 0 1 2.85-1.179 4.04 4.04 0 0 1 3.264 1.667c.278.386.173.896-.148 1.216l-.355.355a.23.23 0 0 1-.237.074.27.27 0 0 1-.183-.167 2.5 2.5 0 0 0-.557-.859 2.53 2.53 0 0 0-3.568 0l-3.09 3.084a2.5 2.5 0 0 0-.73 1.771 2.52 2.52 0 0 0 .73 1.771 2.53 2.53 0 0 0 3.566 0l1.232-1.229c.267-.267.649-.362 1.01-.31q.41.059.824.052h.002c.208 0 .312.253.164.4L8.88 16.822A4.04 4.04 0 0 1 6.03 18a4.04 4.04 0 0 1-2.85-1.179A4.02 4.02 0 0 1 2 13.969a4.04 4.04 0 0 1 1.18-2.851z"
        />
      </Svg>
    );
  }
);
