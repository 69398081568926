import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const AppleIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M13.66 0a3.97 3.97 0 0 1-.944 2.885 3.5 3.5 0 0 1-2.74 1.265 3.78 3.78 0 0 1 .968-2.778A4.23 4.23 0 0 1 13.659 0M17.024 6.14a4.07 4.07 0 0 0-1.996 3.432c.002 1.587.975 3.019 2.472 3.637a9 9 0 0 1-1.286 2.556c-.757 1.106-1.55 2.186-2.811 2.206-.6.014-1.004-.154-1.425-.33-.44-.183-.898-.373-1.615-.373-.76 0-1.24.197-1.701.386-.4.164-.785.323-1.33.345-1.2.043-2.117-1.18-2.902-2.276-1.568-2.237-2.79-6.305-1.152-9.073.768-1.349 2.206-2.205 3.787-2.255.68-.013 1.333.243 1.906.467.438.172.829.325 1.149.325.281 0 .661-.147 1.104-.319.697-.27 1.55-.6 2.42-.511a4.32 4.32 0 0 1 3.38 1.783"
        />
      </Svg>
    );
  }
);
