import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {invariant} from '@youtoken/ui.utils';
import {Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {cell} from '@youtoken/ui.cell';
import {WithdrawalMethodsFeature} from './state/WithdrawalMethodsFeature';
import {FiatWithdrawalWizardScreenName} from '../../../../smart';
import {type MethodData} from '../../types';
import {MethodItem} from '../MethodItem';

interface WithdrawalMethodsProps {
  ticker: string;
  onChange: (method: FiatWithdrawalWizardScreenName) => void;
}

export const WithdrawalMethods: React.FC<WithdrawalMethodsProps> = cell(
  ({ticker, onChange}) => {
    const {t} = useTranslation();

    const {
      methodsList,
      creditCardFeeFormatted,
      bankWireFeeFormatted,
      umaFeeFormatted,
      createHandleMethodPress,
    } = WithdrawalMethodsFeature.use({ticker});

    const methodsData: {[key in FiatWithdrawalWizardScreenName]?: MethodData} =
      React.useMemo(
        () => ({
          [FiatWithdrawalWizardScreenName.CREDIT_CARD]: {
            title: t('surface.wallets.fiat_withdrawal.credit_card'),
            fee: creditCardFeeFormatted,
            description: t(
              'surface.wallets.fiat_withdrawal.credit_card_duration'
            ),
            // NOTE: color is $text-03 because of method is disabled
            icon: <Icon name="card" color="$text-05" />,
            testID: 'FIAT_WITHDRAWAL_METHOD_CREDIT_CARDS',
          },
          [FiatWithdrawalWizardScreenName.BANK_WIRE]: {
            title: t('surface.wallets.fiat_withdrawal.bank_wire'),
            fee: bankWireFeeFormatted,
            description: t(
              'surface.wallets.fiat_withdrawal.bank_wire_duration'
            ),
            icon: <Icon name="bank_wire" color="$text-05" />,
            testID: 'FIAT_WITHDRAWAL_METHOD_BANK_WIRE',
          },
          [FiatWithdrawalWizardScreenName.UMA_WITHDRAWAL]: {
            title: t('surface.wallets.uma'),
            description: t('surface.wallets.uma.withdrawal.duration'),
            fee: umaFeeFormatted,
            icon: <Icon name="uma" color="$interactive-01" />,
            testID: 'FIAT_WITHDRAWAL_METHOD_UMA',
          },
        }),
        [t, creditCardFeeFormatted, bankWireFeeFormatted]
      );

    return (
      <>
        {methodsList.map(({method, disabled}) => {
          invariant(
            methodsData[method],
            `Method ${method} is not supported`,
            {},
            {methodsData, method}
          );

          const methodItemData = methodsData[method]!;

          return (
            <MethodItem
              key={method}
              disabled={disabled}
              onPress={
                !disabled ? createHandleMethodPress(method, onChange) : () => {}
              }
              {...methodItemData}
            />
          );
        })}
      </>
    );
  },
  {
    SuspenseFallback: () => (
      <Box minHeight={80} justifyContent="center" alignItems="center">
        <ActivityIndicator size="large" />
      </Box>
    ),
  }
);
