import * as React from 'react';
import Svg, {Path, Defs, LinearGradient, Stop} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ManaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          fill="url(#a)"
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16"
        />
        <Path
          fill="url(#b)"
          fillRule="evenodd"
          d="M11.336 10.4v12h10z"
          clipRule="evenodd"
        />
        <Path
          fill="#fff"
          fillRule="evenodd"
          d="M1.336 22.4h10v-12z"
          clipRule="evenodd"
        />
        <Path
          fill="#FC9965"
          fillRule="evenodd"
          d="M3.2 25.6a16.4 16.4 0 0 0 3.2 3.2h19.2a16.4 16.4 0 0 0 3.2-3.2z"
          clipRule="evenodd"
        />
        <Path
          fill="#FF2D55"
          fillRule="evenodd"
          d="M6.4 28.8c2.672 2.008 6 3.2 9.6 3.2s6.928-1.192 9.6-3.2z"
          clipRule="evenodd"
        />
        <Path
          fill="url(#c)"
          fillRule="evenodd"
          d="M21.464 16.8v8.8H28.8z"
          clipRule="evenodd"
        />
        <Path
          fill="#FFBC5B"
          fillRule="evenodd"
          d="M21.464 22.4H1.336A16 16 0 0 0 3.2 25.6h18.272v-3.2z"
          clipRule="evenodd"
        />
        <Path
          fill="#fff"
          fillRule="evenodd"
          d="M14.136 25.6h7.328v-8.8z"
          clipRule="evenodd"
        />
        <Path
          fill="#FFC95B"
          d="M21.464 14.4a4 4 0 1 0 0-8 4 4 0 0 0 0 8M11.336 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
        />
        <Defs>
          <LinearGradient
            id="a"
            x1={27.314}
            x2={4.686}
            y1={4.686}
            y2={27.314}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FF2D55" />
            <Stop offset={1} stopColor="#FFBC5B" />
          </LinearGradient>
          <LinearGradient
            id="b"
            x1={16.332}
            x2={16.332}
            y1={10.4}
            y2={22.4}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#A524B3" />
            <Stop offset={1} stopColor="#FF2D55" />
          </LinearGradient>
          <LinearGradient
            id="c"
            x1={25.129}
            x2={25.129}
            y1={16.8}
            y2={25.6}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#A524B3" />
            <Stop offset={1} stopColor="#FF2D55" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
