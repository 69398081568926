import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Polygon} from 'react-native-svg';
import {MinerBlockState} from '@youtoken/ui.resources-miner';
import {type HexGridItemProps} from '../../types';
import {useLayoutContext} from '../../../../lib';
import {MinerOverviewResourceContext} from '../../../../../../context';

const getPressable = (state: MinerBlockState) => {
  return ['INSUFFICIENT_SPARKS', 'AVAILABLE', 'READY', 'CLAIMED'].includes(
    state
  );
};

export const HexGridItemTrigger: React.FC<HexGridItemProps> = observer(
  ({
    hexagon: {
      state,
      block: {id},
    },
  }) => {
    const overview = React.useContext(MinerOverviewResourceContext).use({});

    const {
      hexagonalGrid: {
        setHexagonPressing,
        insufficientSparks,
        miningStart,
        claimingStart,
        showInfoStart,
      },
    } = overview;

    const {points} = useLayoutContext();

    const isPressable = getPressable(state);

    const style = React.useMemo(() => {
      return {
        cursor: isPressable ? 'pointer' : 'default',
        userSelect: 'none',
      };
    }, [isPressable]);

    const handlePressIn = React.useCallback(() => {
      if (isPressable) {
        setHexagonPressing(id, 1);
      }
    }, [isPressable, id, state, setHexagonPressing]);

    const handlePressOut = React.useCallback(() => {
      setHexagonPressing(id, 0);
    }, [id, setHexagonPressing]);

    const handlePress = React.useCallback(() => {
      if (state === 'INSUFFICIENT_SPARKS') {
        insufficientSparks(id);
      }

      if (state === 'AVAILABLE') {
        miningStart(id);
      }

      if (state === 'READY') {
        claimingStart(id);
      }

      if (state === 'CLAIMED') {
        showInfoStart(id);
      }
    }, [id, state, miningStart, claimingStart, showInfoStart]);

    const handlers = Platform.select({
      web: {
        onClick: handlePress,
        //
        onTouchStart: handlePressIn,
        onTouchEnd: handlePressOut,
        //
        onMouseDown: handlePressIn,
        onMouseUp: handlePressOut,
        onMouseOut: handlePressOut,
      },
      default: {
        onPress: handlePress,
        onPressIn: handlePressIn,
        onPressOut: handlePressOut,
      },
    })!;

    return (
      <Polygon
        testID={id}
        fill="transparent"
        points={points}
        // @ts-expect-error
        style={style}
        {...handlers}
      />
    );
  }
);
