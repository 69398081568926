import * as React from 'react';
import {Box, Text, ReanimatedBox} from '@youtoken/ui.primitives';
import {useAnimatedStyle, withSpring} from 'react-native-reanimated';
import {useDebounce} from '@youtoken/ui.hooks';
import {LayoutChangeEvent} from 'react-native';

export const FieldErrorMessage: React.FC<{
  testID?: string;
  visible?: boolean;
  placement?: 'left' | 'right';
  children?: React.ReactNode;
}> = ({testID, visible, children, placement = 'left'}) => {
  const [_children, setChildren] = React.useState(children);
  const [size, setSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    if (children) {
      setChildren(children);
    }
  }, [children]);

  const handleLayout = React.useCallback((event: LayoutChangeEvent) => {
    setSize(event.nativeEvent.layout);
  }, []);

  const [visibleDebounced] = useDebounce(visible, 10);

  const errorStyle = useAnimatedStyle(() => {
    return {
      opacity: withSpring(visibleDebounced ? 1 : 0, {
        overshootClamping: true,
      }),
      height: withSpring(visibleDebounced ? size.height + 5 : 0, {
        overshootClamping: true,
      }),
    };
  }, [visibleDebounced, size.height]);

  const messageStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      transform: [
        {
          translateY: withSpring(visibleDebounced ? 0 : -size.height - 5, {
            overshootClamping: true,
          }),
        },
      ],
    };
  }, [visibleDebounced, size.height]);

  return (
    <ReanimatedBox
      testID={testID}
      style={errorStyle}
      // overflow="hidden"
      justifyContent="flex-start"
      alignItems={placement === 'left' ? 'flex-start' : 'flex-end'}
      position="relative"
      pointerEvents="none"
      accessible={false}
      zIndex={-1}
    >
      <ReanimatedBox style={messageStyle}>
        <Box
          height={5}
          width={5}
          bg="$danger-01"
          position="absolute"
          top={3}
          left={placement === 'left' ? 16 : undefined}
          right={placement === 'right' ? 16 : undefined}
          style={{
            transform: [{rotate: '45deg'}],
          }}
          borderRadius={1}
        />
        <Box
          backgroundColor="$danger-01"
          px={8}
          py={4}
          borderRadius={5}
          mt={5}
          onLayout={handleLayout}
        >
          <Text
            color="$text-04"
            variant="$body-02-medium-accent"
            accessible={false}
          >
            {_children}
          </Text>
        </Box>
      </ReanimatedBox>
    </ReanimatedBox>
  );
};
