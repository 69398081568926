import * as React from 'react';
import {observer} from 'mobx-react';
import {type HexGridItemProps} from '../../types';
import {useHexGridItemStatePressingAnimation} from './hooks';
import {AnimatedGroup} from './elements';
import {
  HexGridItemStateInsufficientSparks,
  HexGridItemStateAvailable,
  HexGridItemStateMiningStartingAnimated,
  HexGridItemStateMining,
  HexGridItemStateReadyAnimated,
  HexGridItemStateClaimingStartingAnimated,
  HexGridItemStateClaimingAnimated,
  HexGridItemStateClaimingEndingAnimated,
  HexGridItemStateClaimedInfoAnimated,
  HexGridItemStateClaimed,
  HexGridItemStateDisabled,
} from './components';

const HexGridItemState: React.FC<HexGridItemProps> = observer(({hexagon}) => {
  const {state} = hexagon;

  if (state === 'INSUFFICIENT_SPARKS') {
    return <HexGridItemStateInsufficientSparks hexagon={hexagon} />;
  }

  if (state === 'AVAILABLE') {
    return <HexGridItemStateAvailable hexagon={hexagon} />;
  }

  if (state === 'MINING_STARTING') {
    return <HexGridItemStateMiningStartingAnimated hexagon={hexagon} />;
  }

  if (state === 'MINING') {
    return <HexGridItemStateMining hexagon={hexagon} />;
  }

  if (state === 'READY') {
    return <HexGridItemStateReadyAnimated hexagon={hexagon} />;
  }

  if (state === 'CLAIMING_STARTING') {
    return <HexGridItemStateClaimingStartingAnimated hexagon={hexagon} />;
  }

  if (state === 'CLAIMING') {
    return <HexGridItemStateClaimingAnimated hexagon={hexagon} />;
  }

  if (state === 'CLAIMING_ENDING') {
    return <HexGridItemStateClaimingEndingAnimated hexagon={hexagon} />;
  }

  if (state === 'CLAIMED') {
    return <HexGridItemStateClaimed hexagon={hexagon} />;
  }

  if (state === 'CLAIMED_INFO') {
    return <HexGridItemStateClaimedInfoAnimated hexagon={hexagon} />;
  }

  return <HexGridItemStateDisabled hexagon={hexagon} />;
});

export const HexGridItemStateAnimated: React.FC<HexGridItemProps> = observer(
  ({hexagon}) => {
    return (
      <AnimatedGroup
        animatedProps={useHexGridItemStatePressingAnimation(hexagon)}
      >
        <HexGridItemState hexagon={hexagon} />
      </AnimatedGroup>
    );
  }
);
