import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const HexagonIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          stroke={color}
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m4.407 4.507 4-2.25a3.25 3.25 0 0 1 3.186 0l4 2.25a3.25 3.25 0 0 1 1.657 2.832v5.322a3.25 3.25 0 0 1-1.657 2.832l-4 2.25a3.25 3.25 0 0 1-3.186 0l-4-2.25a3.25 3.25 0 0 1-1.657-2.832V7.339a3.25 3.25 0 0 1 1.657-2.832Z"
        />
      </Svg>
    );
  }
);
