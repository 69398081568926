import i18next from 'i18next';
import {reaction, IReactionDisposer, action, observable} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize, primitive, createSimpleSchema} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';

export enum LocaliseResourceNamespace {
  NOTIFICATION = 'notification',
  HODL = 'hodl',
}

export const LocaliseResponse = createSimpleSchema<Record<string, string>>({
  '*': primitive(),
});

export interface LocaliseResourceArgs {
  namespace: string;
  language?: string; // NOTE: the language param is needed only in unauthorized mode, in authorized mode the backend knows the language from the user settings
}

export class LocaliseResource extends createStaticResource<
  LocaliseResourceArgs,
  Record<string, string>
>({
  getKey: ({namespace, language}) =>
    `LocaliseResource(${namespace}, ${language})`,
  getData: ({namespace, language}) => {
    return TRANSPORT.API.get(`/v1/i18n/${namespace}`, {
      params: {
        language,
      },
    }).then(res => {
      return deserialize(LocaliseResponse, res.data);
    });
  },
}) {
  @observable
  i18next!: typeof i18next;

  @action
  i18nextInit = () => {
    const instance = i18next.createInstance({
      lng: 'default',
      debug: false,
      resources: {
        default: {
          translation: this.data,
        },
      },
    });

    instance.init();

    this.i18next = instance;
  };

  disposers: Array<IReactionDisposer> = [];

  translate = computedFn((i18nKey, params = {}) => {
    return this.i18next.exists(i18nKey) ? this.i18next.t(i18nKey, params) : '';
  });

  constructor(args: LocaliseResourceArgs, result: Record<string, string>) {
    super(args, result);

    this.i18nextInit();

    this.disposers.push(
      reaction(
        () => i18n.language,
        () => {
          this.refetchSilently();
        },
        // NOTE: a short delay to be sure that backend received the request to change the language
        {
          delay: 500,
        }
      ),
      reaction(
        () => this.data,
        () => {
          this.i18nextInit();
        }
      )
    );
  }

  onDestroy(): void {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
}
