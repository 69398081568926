import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SignIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M4 2.25A1.75 1.75 0 0 0 2.25 4v12c0 .966.784 1.75 1.75 1.75h4a.75.75 0 0 0 0-1.5H4a.25.25 0 0 1-.25-.25V4A.25.25 0 0 1 4 3.75h9a.25.25 0 0 1 .25.25v4a.75.75 0 0 0 1.5 0V4A1.75 1.75 0 0 0 13 2.25z"
        />
        <Path
          fill={color}
          d="M5.25 6.5A.75.75 0 0 1 6 5.75h3.5a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M15.763 8.677a1.75 1.75 0 0 1 2.474 0l.586.586a1.75 1.75 0 0 1 0 2.474l-5.5 5.5a1.75 1.75 0 0 1-1.237.513H10.5a.75.75 0 0 1-.75-.75v-1.586c0-.464.184-.909.513-1.237zm1.414 1.06a.25.25 0 0 0-.354 0l-5.5 5.5a.25.25 0 0 0-.073.177v.836h.836a.25.25 0 0 0 .177-.073l5.5-5.5a.25.25 0 0 0 0-.354z"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M6 8.75a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 0-1.5zM5.25 12.5a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75"
        />
      </Svg>
    );
  }
);
