import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TopGainersIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M13 5.25h4l.07.003.101.017.068.019.06.023.07.035.082.054.08.07a.7.7 0 0 1 .096.118l.05.09.04.103q.017.056.026.116L17.75 6v4a.75.75 0 0 1-1.493.102L16.25 10V7.81l-4.72 4.72a.75.75 0 0 1-.887.13l-.093-.06-3.48-2.61-4.54 4.54a.75.75 0 0 1-.976.073l-.084-.073a.75.75 0 0 1-.073-.976l.073-.084 5-5a.75.75 0 0 1 .887-.13l.093.06 3.479 2.609 4.259-4.259H13a.75.75 0 0 1-.743-.648L12.25 6a.75.75 0 0 1 .648-.743z"
        />
      </Svg>
    );
  }
);
