import * as React from 'react';
import {observer} from 'mobx-react';
import {TradeToUpgradeModalContent} from '@youtoken/ui.surface-account-levels';
import {ModalNotice} from '../ModalNotice';

export interface MinerGetMoreSparksContentProps {
  noticeText?: string;
  onClose: () => void;
}

export const MinerGetMoreSparksContent: React.FC<MinerGetMoreSparksContentProps> =
  observer(({noticeText, onClose}) => {
    return (
      <>
        {Boolean(noticeText) && (
          <ModalNotice text={noticeText!} mt={-1} mb={16} />
        )}
        <TradeToUpgradeModalContent
          variant="sparks"
          px={24}
          pb={24}
          onClose={onClose}
        />
      </>
    );
  });
