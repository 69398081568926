import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ExtendIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 8a1 1 0 0 1 .993.883L11 9v5h1.293a.5.5 0 0 1 .411.784l-.058.07-2.292 2.292a.5.5 0 0 1-.638.058l-.07-.058-2.292-2.292a.5.5 0 0 1 .263-.846l.09-.008H9V9a1 1 0 0 1 1-1M4.5 4a1 1 0 0 1 0 2h-1a1 1 0 0 1 0-2zm4 0a1 1 0 0 1 0 2h-1a1 1 0 0 1 0-2zm4 0a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2zm4 0a1 1 0 1 1 0 2h-1a1 1 0 1 1 0-2z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
