import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TurboIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M6.446 17.572c.689.688.817 1.361.818 1.763l-.008.178-.034.2q-1.242 1.242-3.494 1.474l-.382.032a.516.516 0 0 1-.548-.482v-.065q.161-2.531 1.507-3.877l.13-.025.152-.014c.385-.02 1.113.07 1.859.816M20.075 3.41c.324.08.587.312.709.617l.043.135q1.302 5.24-1.526 8.068a53 53 0 0 1-2.983 2.733l-.05 4.26a1.03 1.03 0 0 1-.198.596l-.104.122-2.146 2.145a.516.516 0 0 1-.79-.073l-.05-.092-1.336-3.188-.566.434a1.03 1.03 0 0 1-1.25 0l-.105-.09-4.562-4.563a1.03 1.03 0 0 1-.09-1.355l.433-.565-3.187-1.336a.516.516 0 0 1-.166-.84l2.146-2.146c.19-.19.448-.299.717-.302l4.261-.05a53 53 0 0 1 2.733-2.983q2.828-2.828 8.067-1.527m-1.424 1.75q-3.393-.555-4.811.862l-.364.376-.613.67-.973 1.107-1.203 1.408-1.433 1.708L7.592 13.3a.516.516 0 0 0 .033.692l2.625 2.625a.516.516 0 0 0 .687.038l1.746-1.408.791-.648 1.42-1.183.92-.788.8-.704.463-.422.41-.384.355-.347q1.669-1.668 1.29-4.787l-.055-.395a.52.52 0 0 0-.426-.43m-2.28 2.646c.845.846.83 2.234-.037 3.1-.865.865-2.253.882-3.099.036s-.83-2.234.036-3.1c.866-.865 2.254-.881 3.1-.036"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
