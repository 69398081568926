import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TwofaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path fill={color} d="M15 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M7.25 7a5.75 5.75 0 1 1 4 5.479V13c0 .69-.56 1.25-1.25 1.25h-.75V15c0 .69-.56 1.25-1.25 1.25h-.75v1.25c0 .69-.56 1.25-1.25 1.25H2.5c-.69 0-1.25-.56-1.25-1.25v-1.793c0-.331.132-.65.366-.884l5.947-5.947A5.7 5.7 0 0 1 7.25 7M13 2.75a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0-8.5m-4.722 7.532L2.75 15.811v1.439h3V16c0-.69.56-1.25 1.25-1.25h.75V14c0-.69.56-1.25 1.25-1.25h.75v-1.006a5.8 5.8 0 0 1-1.472-1.462"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
