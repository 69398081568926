import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DocumentIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M7.25 6a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 0-1.5zM6.5 9.75A.75.75 0 0 1 7.25 9h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75M7.25 12a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M3.5 4a2 2 0 0 1 2-2h6.257a3 3 0 0 1 2.122.879L15.62 4.62a3 3 0 0 1 .879 2.122V16a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2zM5 4a.5.5 0 0 1 .5-.5h6.172a2 2 0 0 1 1.414.586l1.328 1.328A2 2 0 0 1 15 6.828V16a.5.5 0 0 1-.5.5h-9A.5.5 0 0 1 5 16z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
