import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const EmailIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M1 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2zm15.29-.5H3.71l5.467 4.783a1.25 1.25 0 0 0 1.646 0zM2.5 5.434v9.005l4.803-4.802zM3.56 15.5h12.88l-4.888-4.887a2.75 2.75 0 0 1-3.104 0zm13.94-1.06V5.433l-4.803 4.203z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
