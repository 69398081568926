import {computed} from 'mobx';
import {
  VerificationResource,
  VerificationItemAccountCode,
} from '@youtoken/ui.resource-verification';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  OnboardingWidgetResource,
  type StepItemType,
} from '@youtoken/ui.resource-onboarding-widget';
import type {ActionName, StepItemStatus} from './types';

export class OnboardingWidgetFeature extends createFeature({
  getKey: () => {
    return 'feature:onboardingWidget';
  },
  getResources: () => {
    return {
      onboardingWidget: getResourceDescriptor(OnboardingWidgetResource, {}),
      authme: getResourceDescriptor(AuthMeResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed get hasPromo() {
    return Boolean(this.resources.onboardingWidget.promo);
  }

  // NOTE: Are bonuses, incentives and other rewards legally available
  @computed get hasPerks() {
    return !this.resources.authme.isGbr;
  }

  @computed get verificationsStatus(): StepItemStatus {
    return this.resources.verification.accountVerificationComplete
      ? 'FINISHED'
      : 'ACTIVE';
  }

  @computed get depositStatus(): StepItemStatus {
    return this.resources.onboardingWidget.data.deposited
      ? 'FINISHED'
      : this.verificationsStatus === 'FINISHED'
      ? 'ACTIVE'
      : 'PENDING';
  }

  @computed.struct get steps(): {
    type: StepItemType;
    status: StepItemStatus;
  }[] {
    return [
      {
        type: 'SIGNUP',
        status: 'FINISHED',
      },
      {
        type: 'VERIFICATIONS',
        status: this.verificationsStatus,
      },
      {
        type: 'DEPOSIT',
        status: this.depositStatus,
      },
    ];
  }

  @computed get isCompleted() {
    return this.steps.every(step => step.status === 'FINISHED');
  }

  @computed get activeStep() {
    return this.steps.find(step => step.status === 'ACTIVE');
  }

  @computed get currentAccountVerification() {
    return this.resources.verification.accountVerificationCurrent;
  }

  @computed get currentVerificationStatus() {
    if (!this.currentAccountVerification) {
      return undefined;
    }

    return this.resources.verification.getVerificationStatusByCode(
      this.currentAccountVerification
    );
  }

  @computed get actionName(): ActionName | undefined {
    if (this.activeStep?.type === 'VERIFICATIONS') {
      return this.getVerificationActionName();
    }

    if (this.activeStep?.type === 'DEPOSIT') {
      return 'TOP_UP_BALANCE_BUTTON';
    }

    return undefined;
  }

  private getVerificationActionName(): ActionName | undefined {
    if (!this.currentVerificationStatus) {
      return undefined;
    }

    const {currentVerificationStatus, currentAccountVerification} = this;

    if (
      ['NOT_SET', 'NONE'].includes(currentVerificationStatus) &&
      currentAccountVerification === VerificationItemAccountCode.IDENTITY
    ) {
      return 'VERIFY_IDENTITY_BUTTON';
    }

    if (
      ['IN_PROGRESS'].includes(currentVerificationStatus) ||
      (['NOT_SET', 'NONE'].includes(currentVerificationStatus) &&
        currentAccountVerification !== VerificationItemAccountCode.IDENTITY)
    ) {
      return 'CONTINUE_VERIFICATION_BUTTON';
    }

    if (['SEND', 'MANUAL_REVIEW'].includes(currentVerificationStatus)) {
      return 'DOCS_UNDER_REVIEW_BANNER';
    }

    if (
      ['DENY', 'ADDITIONAL_DOCS_REQUIRED', 'RE_VERIFY'].includes(
        currentVerificationStatus
      )
    ) {
      return 'VERIFICATION_FAILED_BANNER';
    }

    return undefined;
  }
}
