import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const BankWireIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M8 6.05a.75.75 0 0 1 .75.75v7a.75.75 0 0 1-1.5 0v-7A.75.75 0 0 1 8 6.05M12.75 6.8a.75.75 0 0 0-1.5 0v7a.75.75 0 0 0 1.5 0z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="m9.757 2.06-7 1.75A1 1 0 0 0 2 4.78V6.8a.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2.2a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5V4.78a1 1 0 0 0-.758-.97l-7-1.75a1 1 0 0 0-.485 0M3.5 5.172v.897a2 2 0 0 1 1 1.733v6a2 2 0 0 1-1 1.732v.967h13v-.967a2 2 0 0 1-1-1.732v-6a2 2 0 0 1 1-1.733v-.897L10 3.546z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
