import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const PinIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M5 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m1.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M10 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m1.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m1.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m3.5-12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m1.5 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
