import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const OsOtherIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16M8.694 7.306c.26.26.26.683 0 .943l-1.751 1.75 1.75 1.752a.667.667 0 1 1-.942.943L5.529 10.47a.667.667 0 0 1 0-.943L7.75 7.306c.26-.26.682-.26.943 0m2.612.943a.667.667 0 0 1 .943-.943l2.222 2.222c.26.26.26.683 0 .943l-2.222 2.223a.667.667 0 0 1-.943-.943L13.057 10z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
