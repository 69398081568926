import * as React from 'react';
import {G} from 'react-native-svg';
import {type HexGridItemProps} from '../../../../types';
import {
  FilledGroup,
  Background,
  Border,
  InnerHexagon,
  Price,
} from '../../elements';

export const HexGridItemStateInsufficientSparks: React.FC<HexGridItemProps> = ({
  hexagon: {
    block: {miningPriceFormatted},
  },
}) => {
  return (
    <G testID="HEX_GRID_ITEM_STATE_INSUFFICIENT_SPARKS">
      <FilledGroup>
        <Background color="$ui-01" />
        <Border color="$ui-03" />
        <InnerHexagon color="$text-02" />
      </FilledGroup>
      <Price color="$text-02" priceFormatted={miningPriceFormatted} />
    </G>
  );
};
