import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {Level1GrayscaleIcon as level_1_grayscale} from './Level1Grayscale';
import {Level1Icon as level_1} from './Level1';
import {Level2GrayscaleIcon as level_2_grayscale} from './Level2Grayscale';
import {Level2Icon as level_2} from './Level2';
import {Level3GrayscaleIcon as level_3_grayscale} from './Level3Grayscale';
import {Level3Icon as level_3} from './Level3';
import {Level4GrayscaleIcon as level_4_grayscale} from './Level4Grayscale';
import {Level4Icon as level_4} from './Level4';
import {Level5GrayscaleIcon as level_5_grayscale} from './Level5Grayscale';
import {Level5Icon as level_5} from './Level5';
import {Level6GrayscaleIcon as level_6_grayscale} from './Level6Grayscale';
import {Level6Icon as level_6} from './Level6';
import {Level7GrayscaleIcon as level_7_grayscale} from './Level7Grayscale';
import {Level7Icon as level_7} from './Level7';
import {Level8GrayscaleIcon as level_8_grayscale} from './Level8Grayscale';
import {Level8Icon as level_8} from './Level8';

export type IconName =
  | 'level_1_grayscale'
  | 'level_1'
  | 'level_2_grayscale'
  | 'level_2'
  | 'level_3_grayscale'
  | 'level_3'
  | 'level_4_grayscale'
  | 'level_4'
  | 'level_5_grayscale'
  | 'level_5'
  | 'level_6_grayscale'
  | 'level_6'
  | 'level_7_grayscale'
  | 'level_7'
  | 'level_8_grayscale'
  | 'level_8';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  level_1_grayscale,
  level_1,
  level_2_grayscale,
  level_2,
  level_3_grayscale,
  level_3,
  level_4_grayscale,
  level_4,
  level_5_grayscale,
  level_5,
  level_6_grayscale,
  level_6,
  level_7_grayscale,
  level_7,
  level_8_grayscale,
  level_8,
};
