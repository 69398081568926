import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FailedContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M12.26 2.954a7.4 7.4 0 1 0-4.52 14.092 7.4 7.4 0 0 0 4.52-14.092m-2.885-.528a7.6 7.6 0 1 1 1.25 15.148 7.6 7.6 0 0 1-1.25-15.148M16.4 10a6.4 6.4 0 1 1-12.8 0 6.4 6.4 0 0 1 12.8 0m-9.083 2.117L9.434 10 7.317 7.883l.566-.566L10 9.434l2.117-2.117.566.566L10.566 10l2.117 2.117-.566.566L10 10.566l-2.117 2.117z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
