import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CloseNowIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M10 1.5a8.5 8.5 0 1 1 0 17 8.5 8.5 0 0 1 0-17M10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14m3.25 6a1 1 0 1 1 0 2h-6.5a1 1 0 1 1 0-2z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
