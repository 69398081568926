import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const IncreaseIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M15.5 6h1.043a.5.5 0 0 0 .354-.854l-1.793-1.792a.5.5 0 0 0-.707 0l-1.793 1.792a.5.5 0 0 0 .353.854H14v10.25a.75.75 0 0 0 1.5 0zM11.5 10.25a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0zM7.5 12.25a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0zM3.5 13.25a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0z"
        />
      </Svg>
    );
  }
);
