import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const QrIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M5.5 5a.5.5 0 0 0-.5.5V6a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 .5-.5v-.5A.5.5 0 0 0 6 5z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M4 2a2 2 0 0 0-2 2v3.5a2 2 0 0 0 2 2h3.5a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm3.5 1.5H4a.5.5 0 0 0-.5.5v3.5A.5.5 0 0 0 4 8h3.5a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M5.5 13.5a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 .5-.5V14a.5.5 0 0 0-.5-.5z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M4 10.5a2 2 0 0 0-2 2V16a2 2 0 0 0 2 2h3.5a2 2 0 0 0 2-2v-3.5a2 2 0 0 0-2-2zM7.5 12H4a.5.5 0 0 0-.5.5V16a.5.5 0 0 0 .5.5h3.5A.5.5 0 0 0 8 16v-3.5a.5.5 0 0 0-.5-.5"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M12.25 12.5a.25.25 0 0 1 .25-.25h4a.75.75 0 0 0 0-1.5h-4a1.75 1.75 0 0 0-1.75 1.75V16c0 .966.784 1.75 1.75 1.75h1c.69 0 1.25-.56 1.25-1.25v-1.25h1.5v1.25a.75.75 0 0 0 1.5 0V15c0-.69-.56-1.25-1.25-1.25h-2c-.69 0-1.25.56-1.25 1.25v1.25h-.75a.25.25 0 0 1-.25-.25zM13.5 5.5A.5.5 0 0 1 14 5h.5a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-.5.5H14a.5.5 0 0 1-.5-.5z"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M12.5 2a2 2 0 0 0-2 2v3.5a2 2 0 0 0 2 2H16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM16 3.5h-3.5a.5.5 0 0 0-.5.5v3.5a.5.5 0 0 0 .5.5H16a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
