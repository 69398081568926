export enum DepositMethodEnum {
  APPLE_PAY = 'applePay',
  BANK_CARD = 'bankCard',
  BANK_CARD_IFRAME = 'bankCardIframe',
  BANK_WIRE = 'bankWire',
  CODI = 'coDi',
  SPEI = 'spei',
  ADV_CASH = 'advCash',
  UMA = 'uma',
}
