import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const HeartBrokenIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="m4 3.5-.31-.683zm6 15-.405.63a.75.75 0 0 0 .811 0zM9 8l-.735-.147a.75.75 0 0 0 .44.836zm7-4.5.31-.683zm-3.5 6 .48.576a.75.75 0 0 0-.184-1.266zm-3 2.5-.48-.576a.75.75 0 0 0-.144.992zm.376 1.916a.75.75 0 1 0 1.249-.832zm-2.263-9.46a.75.75 0 1 0 .775-1.285zM3.69 2.816C2.12 3.531 1.194 4.684.9 6.114.612 7.5.938 9.044 1.63 10.56c1.386 3.037 4.375 6.263 7.964 8.57l.811-1.262c-3.411-2.193-6.172-5.217-7.41-7.93-.62-1.359-.826-2.564-.628-3.522.189-.914.763-1.698 1.943-2.234zm6.046 5.33c.214-1.07.998-2.436 2.122-3.341 1.1-.885 2.425-1.263 3.832-.623l.62-1.366c-2.059-.936-3.983-.314-5.392.82C9.533 4.752 8.55 6.422 8.265 7.853zm5.954-3.964c1.18.536 1.754 1.32 1.943 2.234.198.958-.007 2.163-.627 3.521-1.239 2.714-4 5.738-7.411 7.931l.811 1.262c3.589-2.307 6.578-5.533 7.964-8.57.693-1.517 1.019-3.061.732-4.447-.295-1.43-1.22-2.583-2.791-3.297zM8.705 8.689l3.5 1.5.59-1.379-3.5-1.5zm3.315.235-3 2.5.96 1.152 3-2.5zm-3.144 3.492 1 1.5 1.249-.832-1-1.5zM8.388 3.17c-1.305-.787-2.965-1.142-4.698-.354l.62 1.366c1.182-.537 2.316-.323 3.303.272z"
        />
      </Svg>
    );
  }
);
