import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {TouchableWidgetBox} from '@youtoken/ui.elements';
import {IncentivesResource} from '@youtoken/ui.resource-incentives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handleOpenModal = () => {
  DATA_LAYER.trackStrict('incentives-widget-click', {});

  SHARED_ROUTER_SERVICE.navigate('IncentivesDetailsModal');
};

export const BonusesWidget: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    incentivesEnabled,
    incentivesBalanceFormatted,
    incentivesTickerSymbol,
    incentivesBurningIn,
    hasIncentivesBalance,
  } = IncentivesResource.use({});

  if (!incentivesEnabled) {
    return null;
  }

  return (
    <>
      <TouchableWidgetBox
        title={t('surface.incentives.bonuses.title')}
        iconName="bonuses"
        onPress={handleOpenModal}
        testID="BONUSES_WIDGET"
        {...boxProps}
      >
        <Text variant="$heading-02" fontWeight={500} mb={4}>
          {incentivesTickerSymbol}
          {incentivesBalanceFormatted}
        </Text>
        <Text variant="$body-02" color="$text-02">
          {hasIncentivesBalance && Boolean(incentivesBurningIn)
            ? t('surface.incentives.expiration_button.expires_in', {
                period: incentivesBurningIn,
              })
            : t('surface.incentives.text.balance')}
        </Text>
      </TouchableWidgetBox>
    </>
  );
});
