import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const OsAndroidIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="m13.386.751-.807 1.496A5 5 0 0 1 14.523 4q.727 1.125.727 2.46H4.739q0-1.335.727-2.46a5 5 0 0 1 1.955-1.753L6.614.75q-.08-.148.056-.228.148-.069.228.068L7.716 2.1q1.08-.48 2.284-.48t2.284.48L13.102.59q.08-.136.228-.068.136.08.056.228M7.602 4.554a.43.43 0 0 0 .313-.132.43.43 0 0 0 .13-.314.43.43 0 0 0-.13-.314.43.43 0 0 0-.313-.13.44.44 0 0 0-.432.445q0 .182.125.313a.4.4 0 0 0 .307.132m4.796 0q.18 0 .306-.132a.44.44 0 0 0 .125-.314.44.44 0 0 0-.431-.445.43.43 0 0 0-.313.131.43.43 0 0 0-.13.314q0 .183.13.314a.43.43 0 0 0 .313.132"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M2.34 6.997q.342-.342.83-.343.478 0 .819.343.34.342.34.822v4.91q0 .491-.335.833a1.1 1.1 0 0 1-.824.343q-.488 0-.83-.343A1.14 1.14 0 0 1 2 12.73v-4.91q0-.48.34-.822M4.784 14.476q0 .525.363.89.364.366.887.366h.84l.012 2.592q0 .49.34.833.342.343.819.343.489 0 .83-.343.34-.342.34-.833v-2.592h1.569v2.592q0 .49.34.833.341.343.83.343t.83-.343.34-.833v-2.592h.852q.512 0 .876-.365.363-.365.363-.891V6.871H4.784zM16.012 6.991q.34-.337.818-.337.488 0 .83.337.34.337.34.828v4.91q0 .491-.34.833a1.13 1.13 0 0 1-.83.343q-.477 0-.818-.343a1.14 1.14 0 0 1-.341-.833v-4.91q0-.49.34-.828"
        />
      </Svg>
    );
  }
);
