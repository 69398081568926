import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SparkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M8.58 18a.5.5 0 0 1-.494-.582l.806-4.836A.5.5 0 0 0 8.4 12H5.49q-.375 0-.463-.2-.087-.2.113-.55l5.703-9.017A.5.5 0 0 1 11.265 2h.134a.5.5 0 0 1 .493.582l-.806 4.836A.5.5 0 0 0 11.58 8h2.91q.374 0 .461.2.089.2-.112.55l-5.702 9.017a.5.5 0 0 1-.423.233z"
        />
      </Svg>
    );
  }
);
