import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level3GrayscaleIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#8E9FB3"
          fillRule="evenodd"
          d="M6.277 15.584a.5.5 0 0 1 .139.693l-1 1.5a.5.5 0 1 1-.832-.554l1-1.5a.5.5 0 0 1 .693-.139M5.277 10.584a.5.5 0 0 1 .139.693l-1 1.5a.5.5 0 1 1-.832-.554l1-1.5a.5.5 0 0 1 .693-.139M11.777 15.084a.5.5 0 0 1 .139.693l-1 1.5a.5.5 0 1 1-.832-.554l1-1.5a.5.5 0 0 1 .693-.139"
          clipRule="evenodd"
        />
        <Path
          fill="#8E9FB3"
          d="M10.04 3.566a.5.5 0 0 1 .92 0l1.315 3.056a.5.5 0 0 0 .413.3l3.313.308a.5.5 0 0 1 .284.873l-2.5 2.196a.5.5 0 0 0-.157.486l.731 3.246a.5.5 0 0 1-.743.54l-2.86-1.7a.5.5 0 0 0-.511 0l-2.861 1.7a.5.5 0 0 1-.743-.54l.731-3.246a.5.5 0 0 0-.157-.486l-2.5-2.196a.5.5 0 0 1 .284-.873l3.313-.308a.5.5 0 0 0 .413-.3z"
        />
        <Path
          fill="#CBD2DA"
          fillRule="evenodd"
          d="M10.5 3.264c-.186 0-.373.101-.46.302L8.726 6.622a.5.5 0 0 1-.413.3l-3.313.308a.5.5 0 0 0-.284.873l2.5 2.196a.5.5 0 0 1 .157.486l-.731 3.246a.5.5 0 0 0 .743.54l2.86-1.7a.5.5 0 0 1 .256-.07z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
