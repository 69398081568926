import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TopLoosersIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M1.554 5.397a.75.75 0 0 1 .882-.007l.094.08 4.54 4.54 3.48-2.61.093-.06a.75.75 0 0 1 .804.058l.083.072 4.72 4.719V10l.007-.102a.75.75 0 0 1 1.486 0l.007.102v4l-.007.102-.01.06-.021.074-.04.099-.045.076-.026.038-.07.08-.111.092-.095.055-.086.035-.103.027-.085.01-.051.002h-4l-.102-.007a.75.75 0 0 1-.641-.641L12.25 14l.007-.102a.75.75 0 0 1 .641-.641L13 13.25h2.189l-4.26-4.259L7.45 11.6l-.093.06a.75.75 0 0 1-.804-.058l-.083-.072-5-5-.073-.084a.75.75 0 0 1-.007-.882l.08-.094z"
        />
      </Svg>
    );
  }
);
