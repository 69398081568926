import {action, computed, observable} from 'mobx';
import {TFuncKey} from 'react-i18next';
import {helperToGetIsFormAgreementsPreAccepted} from '@youtoken/ui.form-utils';
import {isWithinInterval, setDay, startOfWeek} from '@youtoken/ui.date-fns';
import {TradingMode} from '@youtoken/ui.resource-hodl-tariffs';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {
  formatAndLocalizeWeekDay,
  formatByTicker,
  formatPercentTillPrecision,
  toBig,
  formatBigNumber,
} from '@youtoken/ui.formatting-utils';
import {
  getPercentByAmount,
  getSettlementFeePercentFormatted,
  getSettlementFeeSign,
  getSettlementFeeTickerComposite,
} from '@youtoken/ui.hodls-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {additionalInputTickerUIFormatted} from '@youtoken/ui.incentives-utils';
import {CreateHODLForm} from './Form';

export class CreateHODLFormView {
  @observable
  public form: CreateHODLForm;

  constructor(form: CreateHODLForm) {
    this.form = form;
  }

  @computed get calculatedData() {
    return this.form.calculatedData;
  }

  @computed.struct get chartData() {
    if (!this.calculatedData) {
      return null;
    }

    return {
      marginCall: Number(this.calculatedData.slPrice),
      takeProfit: Number(this.calculatedData.tpPrice),
      maxLoss: Number(this.calculatedData.maxLoss),
      maxProfit: this.calculatedData.maxProfit
        ? Number(this.calculatedData.maxProfit)
        : undefined, // NOTE: for v2
      maxLossTicker: this.calculatedData?.inputTicker,
      maxProfitTicker: this.calculatedData?.inputTicker,
      triggerPrice: Number(this.form.triggerPrice),
      reversed: this.calculatedData.isShort,
      pending: this.form.isPending,
    };
  }

  @computed get inputTickerFormatted() {
    return this.form.inputTicker.toUpperCase();
  }

  @computed get sourceWallets() {
    return this.form.sourceWallets;
  }

  @computed get collateralTicker() {
    return this.form.calculatedData?.collateralTicker ?? '';
  }

  @computed get collateralTickerFormatted() {
    return this.collateralTicker.toUpperCase();
  }

  @computed get collateralValueFormatted() {
    if (!this.form.calculatedData?.inputCollateralAmount) {
      return '';
    }

    if (!this.form.isAmountPositive) {
      return '0';
    }

    return formatByTicker(
      this.calculatedData?.inputCollateralAmount,
      this.collateralTicker
    );
  }

  @computed get showCollateral() {
    if (!this.collateralTicker) {
      return false;
    }

    return this.collateralTicker !== this.form.inputTicker;
  }

  @computed get loansInChain() {
    if (!this.calculatedData?.loan?.loans) {
      return undefined;
    }
    return Number(this.calculatedData?.loan?.loans?.length);
  }

  @computed get leverageMin() {
    return Number(this.form.currentTariff?.minMultiplier);
  }

  @computed get leverageMax() {
    return Number(this.form.currentTariff?.maxMultiplier);
  }

  @computed get triggerPriceHasError() {
    return Boolean(this.form.instance.$('triggerPrice').get('error'));
  }

  @computed get maxProfit() {
    return toBig(this.form.calculatedData?.maxProfit || 0);
  }

  @computed get maxProfitIsPositive() {
    return this.maxProfit.gte(0);
  }

  @computed get maxProfitFormatted() {
    const sign = this.maxProfitIsPositive ? '+' : '-';
    const number = formatByTicker(this.maxProfit.abs(), this.form.inputTicker);

    return `≈ ${sign}${number}`;
  }

  @computed get maxProfitTickerFormatted() {
    return this.inputTickerFormatted;
  }

  @computed get showEquivalentAmount() {
    return this.form.inputTicker !== this.mainCurrencyTicker;
  }

  @computed
  get mainCurrencyTicker() {
    const {mainCurrency} = AuthMeResource.__DANGEROUSLY__getInstanceStatically(
      {}
    );

    return mainCurrency;
  }

  @computed
  get rate() {
    const {getRate} = RatesResource.__DANGEROUSLY__getInstanceStatically({});

    return getRate(this.form.inputTicker, this.mainCurrencyTicker);
  }

  @computed
  get inputAmountEquivalentFormatted() {
    const inputAmountEquivalent = toBig(this.form.inputAmount).mul(this.rate);

    return formatByTicker(inputAmountEquivalent, this.form.inputTicker);
  }

  @computed
  get mainCurrencyTickerFormatted() {
    return this.mainCurrencyTicker.toUpperCase();
  }

  @computed get takeProfitValue() {
    return this.form.takeProfit;
  }

  @computed get takeProfitPercent() {
    return this.form.takeProfitPercent;
  }

  @computed get takeProfitValueMin() {
    if (this.form.takeProfitValueMin === undefined) {
      return '';
    }

    return this.form.takeProfitValueMin;
  }

  @computed get takeProfitValueMax() {
    if (this.form.takeProfitValueMax === undefined) {
      return '';
    }

    return this.form.takeProfitValueMax;
  }

  @computed get takeProfitPercentMin() {
    return getPercentByAmount(
      this.form.initialPriceOrder,
      this.takeProfitValueMin
    );
  }

  @computed get takeProfitPercentMax() {
    return getPercentByAmount(
      this.form.initialPriceOrder,
      this.takeProfitValueMax
    );
  }

  @computed get takeProfitLimitMessage() {
    if (this.form.isShort) {
      return {
        errorKey: 'surface.hodls.validation.should_be_gte' as TFuncKey,
        amount: `${this.takeProfitValueMin} (${this.takeProfitPercentMin}%)`,
      };
    }
    return {
      errorKey: 'surface.hodls.validation.should_be_lte' as TFuncKey,
      amount: `${this.takeProfitValueMax} (${this.takeProfitPercentMax}%)`,
    };
  }

  @action
  setTakeProfitLimit = () => {
    this.form.updateTakeProfitValue(
      this.form.isShort ? this.takeProfitValueMin : this.takeProfitValueMax
    );
  };

  @computed get tpError() {
    return this.form.instance.$('takeProfit').get('error');
  }

  @computed get tpErrorIsClickable() {
    return this.tpError === 'TAKE_PROFIT_LIMIT';
  }

  @computed get maxLoss() {
    return toBig(this.form.calculatedData?.maxLoss || 0);
  }

  @computed get maxLossIsPositive() {
    return this.maxLoss.gte(0);
  }

  @computed get maxLossFormatted() {
    const sign = this.maxLossIsPositive ? '+' : '-';
    const number = formatByTicker(this.maxLoss.abs(), this.form.inputTicker);

    return `≈ ${sign}${number}`;
  }

  @computed get maxLossTickerFormatted() {
    return this.inputTickerFormatted;
  }

  @computed get stopLossValue() {
    return this.form.stopLoss;
  }

  @computed get stopLossPercent() {
    return this.form.stopLossPercent;
  }

  @computed get stopLossValueMin() {
    if (this.form.stopLossValueMin === undefined) {
      return '';
    }

    return this.form.stopLossValueMin;
  }

  @computed get stopLossValueMax() {
    if (this.form.stopLossValueMax === undefined) {
      return '';
    }

    return this.form.stopLossValueMax;
  }

  @computed get stopLossPercentMin() {
    return getPercentByAmount(
      this.form.initialPriceOrder,
      this.stopLossValueMin
    );
  }

  @computed get stopLossPercentMax() {
    return getPercentByAmount(
      this.form.initialPriceOrder,
      this.stopLossValueMax
    );
  }

  @computed get stopLossLimitMessage() {
    if (this.form.isShort) {
      return {
        errorKey: 'surface.hodls.validation.should_be_lte' as TFuncKey,
        amount: `${this.stopLossValueMax} (${this.stopLossPercentMax}%)`,
      };
    }
    return {
      errorKey: 'surface.hodls.validation.should_be_gte' as TFuncKey,
      amount: `${this.stopLossValueMin} (${this.stopLossPercentMin}%)`,
    };
  }

  @action setStopLossLimit = () => {
    this.form.updateStopLossValue(
      this.form.isShort ? this.stopLossValueMax : this.stopLossValueMin
    );
  };

  @computed get slError() {
    return this.form.instance.$('stopLoss').get('error');
  }

  @computed get slErrorIsClickable() {
    return this.slError === 'MARGIN_CALL_LIMIT';
  }

  @computed get amountFormatted() {
    return this.form.inputAmount;
  }

  @computed get settlementFeeSign() {
    return getSettlementFeeSign(this.calculatedData?.settlementFee);
  }

  @computed get settlementFeeAmountFormatted() {
    const precision =
      getCoinDecimalPrecision(this.calculatedData?.borrowedTicker) + 2;

    const amount = formatBigNumber(
      this.calculatedData?.settlementFeeAmount.abs(),
      precision
    );

    return `${this.settlementFeeSign}${amount}`;
  }

  @computed get settlementFeePercentFormatted() {
    return getSettlementFeePercentFormatted(this.calculatedData?.settlementFee);
  }

  @computed.struct get loansSequence() {
    return (
      this.calculatedData?.loan.loans.map(loan => ({
        collateralAmountFormatted: formatByTicker(
          loan.collateral,
          this.calculatedData?.collateralTicker
        ),
        collateralTickerFormatted:
          this.calculatedData?.collateralTicker.toUpperCase() ?? '',
        feePercentFormatted: getSettlementFeePercentFormatted(
          loan.settlementFee
        ),
        borrowedAmountFormatted: formatByTicker(
          loan.borrowed,
          this.calculatedData?.borrowedTicker
        ),
        borrowedTickerFormatted:
          this.calculatedData?.borrowedTicker.toUpperCase() ?? '',
        ltvValueFormatted: formatPercentTillPrecision(
          this.calculatedData?.loan.ltv,
          2
        ),
      })) ?? []
    );
  }

  @computed get hasConversion() {
    return Boolean(
      this.collateralTickerFormatted !== this.inputTickerFormatted &&
        this.loansSequence &&
        this.loansSequence[0]
    );
  }

  @computed get borrowedTickerFormatted() {
    return this.calculatedData?.borrowedTicker.toUpperCase() ?? '';
  }

  @computed get rolloverTickerFormatted() {
    return getSettlementFeeTickerComposite(
      this.borrowedTickerFormatted,
      this.settlementFeePercentFormatted,
      this.calculatedData?.settlementPeriod ?? ''
    );
  }

  @computed.struct get detailsData() {
    return {
      inputAmountFormatted: this.amountFormatted,
      isInputAmountPositive: this.form.isAmountPositive,
      inputTickerFormatted: this.inputTickerFormatted,
      additionalInputAmountFormatted: this.form.useBonusesActive
        ? this.additionalInputAmountFormatted
        : undefined,
      additionalInputTickerFormatted: additionalInputTickerUIFormatted,
      hasConversion: this.hasConversion,
      loansSequence: this.loansSequence,
      settlementPeriodFormatted: this.calculatedData?.settlementPeriod ?? '',
      settlementFeePercentFormatted: this.settlementFeePercentFormatted,
      totalCollateralAmountFormatted: formatByTicker(
        this.calculatedData?.totalAmount,
        this.calculatedData?.collateralTicker
      ),
      totalCollateralTickerFormatted: this.collateralTickerFormatted,
      totalBorrowedAmountFormatted: formatByTicker(
        this.calculatedData?.borrowedAmount,
        this.calculatedData?.borrowedTicker
      ),
      totalBorrowedTickerFormatted: this.borrowedTickerFormatted,
      rolloverAmountFormatted: this.settlementFeeAmountFormatted,
      rolloverTickerFormatted: this.rolloverTickerFormatted,
      repaymentDueAmountFormatted: formatByTicker(
        this.calculatedData?.loan.totalRepayment,
        this.calculatedData?.borrowedTicker
      ),
      repaymentDueTickerFormatted: this.borrowedTickerFormatted,
    };
  }

  // NOTE: step 1 - find if the current date is within some day-off period
  @computed get activeDaysOffPeriod() {
    if (
      !this.form.currentTariff?.daysOff ||
      this.form.currentTariff?.daysOff?.length === 0
    ) {
      return undefined;
    }

    const currentDate = new Date();
    const currentWeekStartDate = startOfWeek(currentDate);

    return this.form.currentTariff?.daysOff.find(period => {
      const startDay = period.dayFrom;
      const endDay = period.dayTo;
      let startDateWeekdayDate;
      let endDateWeekdayDate;

      if (endDay >= startDay) {
        startDateWeekdayDate = setDay(currentWeekStartDate, startDay);
        endDateWeekdayDate = setDay(currentWeekStartDate, endDay);
      } else {
        // NOTE: if endDay is less than startDay, add 7 to endDay to ensure that the end date is after the start date
        startDateWeekdayDate = setDay(currentWeekStartDate, startDay);
        endDateWeekdayDate = setDay(currentWeekStartDate, endDay + 7);
      }

      const [timeFromHours, timeFromMinutes] = period.timeFrom.split(':');
      const startDate = new Date(
        startDateWeekdayDate.getFullYear(),
        startDateWeekdayDate.getMonth(),
        startDateWeekdayDate.getDate(),
        Number(timeFromHours),
        Number(timeFromMinutes)
      );

      const [timeToHours, timeToMinutes] = period.timeTo.split(':');
      const endDate = new Date(
        endDateWeekdayDate.getFullYear(),
        endDateWeekdayDate.getMonth(),
        endDateWeekdayDate.getDate(),
        Number(timeToHours),
        Number(timeToMinutes)
      );

      return isWithinInterval(currentDate, {
        start: startDate,
        end: endDate,
      });
    });
  }

  // NOTE: step 2 - show the end of the active days-off period
  @computed get tradingHoursStartDay() {
    if (!this.activeDaysOffPeriod) {
      return undefined;
    }
    return formatAndLocalizeWeekDay(this.activeDaysOffPeriod?.dayTo);
  }

  @computed get tradingHoursStartTime() {
    return this.activeDaysOffPeriod?.timeTo;
  }

  @computed get isDayOffNow() {
    return (
      this.form.currentTariff?.isDayOff &&
      this.tradingHoursStartDay !== undefined &&
      this.tradingHoursStartTime !== undefined
    );
  }

  @computed get isCloseOnlyMode() {
    return this.form.currentTariff?.tradingMode === TradingMode.CLOSE_ONLY;
  }

  @computed get submitButtonIsDisabled() {
    return (
      !this.form.resources.authMeResource.enableHodl ||
      this.isDayOffNow ||
      this.isCloseOnlyMode ||
      !this.form.isAgreementsAccepted
    );
  }

  @computed get needDeposit() {
    const inputAmountErrorLabel = this.form.inputAmountError?.i18n?.label;

    return (
      Number(this.form.allSourceAmount) === 0 ||
      inputAmountErrorLabel === 'FUNDS_INSUFFICIENT'
    );
  }

  @computed get shouldShowAgreementsSwitcher() {
    return !helperToGetIsFormAgreementsPreAccepted();
  }

  @computed get additionalInputAmountFormatted() {
    if (!this.form.additionalInputAmount) {
      return '';
    }
    const precision = getCoinDecimalPrecision(
      this.form.additionalInputTickerBE
    );
    return normalizeAmountWithPrecision(
      this.form.additionalInputAmount.toString(),
      precision
    );
  }
}
