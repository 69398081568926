import {serializable, primitive, optional} from 'serializr';
import {observable} from 'mobx';
import {number} from '@youtoken/ui.utils-serializr';
import {SavingsOverviewStatus} from '../SavingsOverview';

export class SavingsWidgetResponse {
  @observable
  @serializable(primitive())
  status!: SavingsOverviewStatus;

  @observable
  @serializable(primitive())
  quoteTicker!: string;

  @observable
  @serializable(optional(number()))
  timeLeft?: number;

  @observable
  @serializable(optional(primitive()))
  earnedAmount?: string;

  @observable
  @serializable(optional(primitive()))
  showWidget?: boolean;
}
