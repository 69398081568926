import * as React from 'react';
import {observer} from 'mobx-react';
import {
  LoyaltyProgressBarLevel,
  LoyaltyResource,
} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {invariant} from '@youtoken/ui.utils';
import {MarkIconWithAchieved} from '../MarkIcon';

export type AccountLevelProgressBarContentProps = {
  value: number;
  volume?: number;
  progressBarLevels: LoyaltyProgressBarLevel[];
};

export const AccountLevelProgressBarContent: React.FC<
  AccountLevelProgressBarContentProps & BoxProps
> = observer(({value, volume, progressBarLevels, ...boxProps}) => {
  const {currentLevel, lastLevelData} = LoyaltyResource.use({});

  // progress bar should be greyed out if no amount is provided, except when max level reached
  const progressBarActive = volume || currentLevel === lastLevelData.level;

  const firstProgressBarLevel = progressBarLevels[0];
  const lastProgressBarLevel = progressBarLevels[1];

  invariant(
    firstProgressBarLevel && lastProgressBarLevel,
    'Levels are not defined'
  );

  const progressBarColor = progressBarActive
    ? firstProgressBarLevel.isAchieved
      ? 'success'
      : 'interactive'
    : 'inactive';

  const progressBarValue = progressBarActive ? value : 0;

  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_CONTENT"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <MarkIconWithAchieved progressBarLevel={firstProgressBarLevel!} />
      <Box flex={1} mx={7}>
        <ProgressBar
          size="small"
          color={progressBarColor}
          progress={progressBarValue}
        />
      </Box>
      <MarkIconWithAchieved progressBarLevel={lastProgressBarLevel} />
    </Box>
  );
});
