import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CryptoDepositIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M9 5h6.5a1.5 1.5 0 0 1 0 3H9a1 1 0 0 0-1 1v6.5a1.5 1.5 0 0 1-3 0V9a4 4 0 0 1 4-4M6.5 23a1.5 1.5 0 0 1 1.486 1.297L8 24.5V31a1 1 0 0 0 .82.984L9 32h6.5a1.5 1.5 0 0 1 .204 2.986L15.5 35H9a4 4 0 0 1-3.989-3.701L5 31v-6.5A1.5 1.5 0 0 1 6.5 23m27 0a1.5 1.5 0 0 1 1.486 1.297L35 24.5V31a4 4 0 0 1-3.701 3.989L31 35h-6.5a1.5 1.5 0 0 1-.203-2.986L24.5 32H31a1 1 0 0 0 .984-.82L32 31v-6.5a1.5 1.5 0 0 1 1.5-1.5M17 21a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2zm0-10a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2zm8 2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2zm6-8a4 4 0 0 1 4 4v6.5a1.5 1.5 0 0 1-3 0V9a1 1 0 0 0-1-1h-6.5a1.5 1.5 0 0 1 0-3zm-4 16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2zm-1 2h-2a1 1 0 0 0-.984.82L23 24v2a1 1 0 0 0 .82.984L24 27h2a1 1 0 0 0 .984-.82L27 26v-2a1 1 0 0 0-.82-.984z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
