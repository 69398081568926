import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TradingViewIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M8.023 14.5H4.518V9.005H1.012V5.5h6.995v9zm7.01 0H11.04l3.758-9h3.994zm-4.01-9c1.105 0 2.005.9 2.005 2.005 0 1.106-.9 2.006-2.005 2.006s-2.005-.9-2.005-2.006c.015-1.105.9-2.005 2.005-2.005"
        />
      </Svg>
    );
  }
);
