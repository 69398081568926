import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const ShareIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M12.648 8.493a.75.75 0 0 1 0-1.486L12.75 7h1.75a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h1.75a.75.75 0 0 1 .102 1.493L7.25 8.5H5.5a.5.5 0 0 0-.492.41L5 9v7a.5.5 0 0 0 .41.492l.09.008h9a.5.5 0 0 0 .492-.41L15 16V9a.5.5 0 0 0-.41-.492L14.5 8.5h-1.75zM10 12.996a.75.75 0 0 0 .75-.75V3.793L12 5.043a.707.707 0 1 0 1-1l-2.47-2.47a.75.75 0 0 0-1.06 0L7 4.043a.707.707 0 0 0 1 1l1.25-1.25v8.453c0 .414.336.75.75.75"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
