import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const UpIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M4.625 15.375a.884.884 0 0 0 1.25 0L14.25 7v5.875a.875.875 0 1 0 1.75 0V4.75a.75.75 0 0 0-.75-.75H7.125a.875.875 0 1 0 0 1.75H13l-8.375 8.375a.884.884 0 0 0 0 1.25"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
