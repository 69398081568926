import * as React from 'react';
import Svg, {Path, Ellipse} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level8Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#F29D4B"
          d="M15.183 4.916 16 9h-6l4.34-4.34a.5.5 0 0 1 .843.256"
        />
        <Path
          fill="#FFC757"
          d="M5.66 4.66 10 9H4l.817-4.084a.5.5 0 0 1 .844-.255"
        />
        <Path
          fill="#FFC757"
          fillRule="evenodd"
          d="M4 16 2.2 7.003a.5.5 0 0 1 .715-.546L5.577 7.79a.5.5 0 0 0 .658-.2l3.331-5.83a.5.5 0 0 1 .868 0l3.332 5.83a.5.5 0 0 0 .657.2l2.663-1.332a.5.5 0 0 1 .714.546L16 16c0-.552-2.686-1-6-1s-6 .448-6 1"
          clipRule="evenodd"
        />
        <Path
          fill="#FFE252"
          fillRule="evenodd"
          d="M10 1.508a.5.5 0 0 0-.434.252L6.235 7.59a.5.5 0 0 1-.658.199L2.915 6.457a.5.5 0 0 0-.714.546L4 16c0-.552 2.685-1 5.999-1z"
          clipRule="evenodd"
        />
        <Ellipse cx={10} cy={16} fill="#F29D4B" rx={6} ry={1} />
        <Path
          fill="#FFC757"
          d="M8.185 9.723 10 7l2 3-2 3-1.815-2.723a.5.5 0 0 1 0-.554"
        />
        <Path
          fill="#F29D4B"
          d="M11.815 10.277 10 13V7l1.815 2.723a.5.5 0 0 1 0 .554"
        />
      </Svg>
    );
  }
);
