import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const CollateralInfo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    view: {collateralValueFormatted, collateralTickerFormatted},
  } = useForm<CreateHODLFormClass>();

  return (
    <Text color="$text-02" variant="$body-02">
      {t('surface.hodls.hodl_form.collateral_amount_label') +
        ': ' +
        collateralValueFormatted +
        ' ' +
        collateralTickerFormatted}
    </Text>
  );
});
