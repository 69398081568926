import * as React from 'react';
import Svg, {Circle} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const NotificationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 12 12"
        {...props}
      >
        <Circle cx={6} cy={6} r={3} fill={color} />
      </Svg>
    );
  }
);
