import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const StarFilledIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="m9.75 15.852-4.072 1.864a.6.6 0 0 1-.846-.614l.514-4.45a.6.6 0 0 0-.154-.474L2.161 8.88a.6.6 0 0 1 .323-.995l4.39-.885a.6.6 0 0 0 .404-.294l2.2-3.901a.6.6 0 0 1 1.045 0l2.199 3.901a.6.6 0 0 0 .404.294l4.39.885a.6.6 0 0 1 .323.995l-3.031 3.297a.6.6 0 0 0-.154.475l.514 4.449a.6.6 0 0 1-.846.614l-4.072-1.864a.6.6 0 0 0-.5 0"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
