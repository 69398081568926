import * as React from 'react';
import {type HexGridItemProps} from '../../../../types';
import {FilledGroup, Border, Ticker} from '../../elements';

export const HexGridItemStateClaimed: React.FC<HexGridItemProps> = () => {
  return (
    <FilledGroup testID="HEX_GRID_ITEM_STATE_CLAIMED">
      <Border color="$ui-03" />
      <Ticker color="$ui-02" />
    </FilledGroup>
  );
};
