import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TwitterIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          fill={color}
          fillRule="evenodd"
          d="M17.637 5q-.378.165-.773.28c.312-.35.55-.764.696-1.215a.255.255 0 0 0-.085-.279.26.26 0 0 0-.292-.02 6.1 6.1 0 0 1-1.8.706 3.47 3.47 0 0 0-2.405-.972c-1.894 0-3.435 1.53-3.435 3.409q0 .222.029.44A8.81 8.81 0 0 1 3.536 4.17a.26.26 0 0 0-.423.033 3.39 3.39 0 0 0 .352 3.924 3 3 0 0 1-.46-.204.26.26 0 0 0-.253.002.26.26 0 0 0-.13.216v.045c0 1.226.665 2.33 1.681 2.932a3 3 0 0 1-.261-.038.26.26 0 0 0-.242.082.25.25 0 0 0-.052.248 3.42 3.42 0 0 0 2.516 2.284 6.1 6.1 0 0 1-3.248.92q-.366 0-.728-.042a.26.26 0 0 0-.273.168.255.255 0 0 0 .104.302A9.3 9.3 0 0 0 7.128 16.5c3.497 0 5.684-1.636 6.904-3.01 1.52-1.711 2.392-3.977 2.392-6.216q0-.14-.004-.282a6.7 6.7 0 0 0 1.536-1.615.255.255 0 0 0-.017-.307.26.26 0 0 0-.302-.07"
        />
      </Svg>
    );
  }
);
