import {observable} from 'mobx';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {
  type MinerOverviewArgs,
  MinerOverviewResponse,
} from './MinerOverviewResponse';
import {generateData} from '../utils';
import {MinerOverviewHexagonalGridUnаuthorized} from './HexagonalGrid';

export class MinerOverviewUnauthorisedResource extends createStaticResource<
  MinerOverviewArgs,
  MinerOverviewResponse
>({
  getKey: () => 'minerOverviewUnauthorised',
  getData: () => {
    return Promise.resolve(deserialize(MinerOverviewResponse, generateData()));
  },
}) {
  @observable
  public hexagonalGrid!: MinerOverviewHexagonalGridUnаuthorized;

  constructor(args: MinerOverviewArgs, data: MinerOverviewResponse) {
    super(args, data);

    this.hexagonalGrid = new MinerOverviewHexagonalGridUnаuthorized(this);
  }
}
