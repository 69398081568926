import * as React from 'react';
import {Platform} from 'react-native';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {i18n} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {UmaWelcomeSurface, UmaSettingsSurface} from '@youtoken/ui.surfaces-uma';
import {UmaCellsScrollViewWrapper} from './components';
import {HeaderBase} from '../components';
import {
  FiatDepositWizardNavigator,
  FiatDepositWizardScreenName,
} from '../smart/FiatDepositWizard';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getUmaSettingsTitle = () => {
  return i18n.t('surface.wallets.uma');
};

export const UmaSettingsCell: React.FC = cell(
  () => {
    const {isUmaOpened} = UmaInfoResource.use({});

    const {navigate, goBack} = useWizard<
      FiatDepositWizardNavigator,
      FiatDepositWizardScreenName.UMA
    >();

    const handlePressCreate = React.useCallback(() => {
      navigate(FiatDepositWizardScreenName.UMA_CREATE);
    }, [navigate]);

    return (
      <>
        {showHeader && (
          <>
            <HeaderBase onPressBack={goBack}>
              {getUmaSettingsTitle()}
            </HeaderBase>
            <Separator />
          </>
        )}

        {isUmaOpened ? (
          <UmaSettingsSurface />
        ) : (
          <UmaWelcomeSurface onPress={handlePressCreate} />
        )}
      </>
    );
  },
  {
    CellWrap: UmaCellsScrollViewWrapper,
  }
);
