import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const GetLoanIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 25 24"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="m8.078 8.35 5.13 1.69a2.7 2.7 0 0 1 1.849 2.764l-.045.596 5.468-1.148a3 3 0 0 1 3.406 1.832l.076.216a2.764 2.764 0 0 1-1.74 3.477l-9.045 3.063a3 3 0 0 1-1.556.099L3 19.199v.101a1 1 0 1 1-2 0v-10a1 1 0 0 1 2 0v.385l2.978-1.25a3 3 0 0 1 2.1-.084m.209 2.008a3 3 0 0 0-1.914.006l-.204.077-2.819 1.193a1 1 0 0 1-.35.078v5.594a1.2 1.2 0 0 1 .374-.024l.128.018 8.118 1.638a3 3 0 0 0 1.341-.035l.217-.065 8.602-2.921.076-.03a.9.9 0 0 0 .456-1.187 1.19 1.19 0 0 0-1.23-.698l-.129.022-7.648 1.79a3 3 0 0 1-1.35.005l-.22-.06-3.842-1.187a1 1 0 0 1-.68-1.172l.012-.05a.87.87 0 0 1 .967-.62l.107.023 3.754 1.072a.997.997 0 0 0 1.21-.725 1.11 1.11 0 0 0-.614-1.28l-.114-.046zM17.512 1a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9m0 2a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
