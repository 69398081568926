import * as React from 'react';
import {observer} from 'mobx-react';
import {
  PredefinedAmountButtons,
  TickerAndAmountInput,
} from '@youtoken/ui.ticker-and-amount-input';
import {useForm, Label} from '@youtoken/ui.form-elements';
import {Box, BoxProps, Text, useResponsive} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {CreateHODLFormClass} from '../../CreateHODLForm';

interface AmountSectionProps extends BoxProps {}

export const AmountSection: React.FC<AmountSectionProps> = observer(
  ({...boxProps}) => {
    const {
      form: {
        inputAmount,
        setInputAmount,
        inputAmountError,
        inputTicker,
        setInputTicker,
        hodlInstrument,
        predefinedAmountsData,
      },
      view: {
        sourceWallets,
        showEquivalentAmount,
        inputAmountEquivalentFormatted,
        mainCurrencyTickerFormatted,
      },
    } = useForm<CreateHODLFormClass>();

    const {t} = useTranslation();

    const buttonsSize = useResponsive({default: 'tiny', tablet: 'small'});

    const handlePressAmount = React.useCallback(
      (amount: string, index?: number) => {
        DATA_LAYER.trackStrict('hodl-input-preset-click', {
          orderNumber: index ?? 'not_set',
          instrument: hodlInstrument.value,
          amount,
          inputTicker,
        });

        setInputAmount(amount);
      },
      [setInputAmount, inputTicker]
    );

    return (
      <Box {...boxProps} zIndex={10}>
        <Box zIndex={50}>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
            mb={8}
          >
            <Label>{t('surface.hodls.hodl_form.amount_label')}</Label>
            {showEquivalentAmount && (
              <Text variant="$body-01" color="$text-02">
                {inputAmountEquivalentFormatted +
                  ' ' +
                  mainCurrencyTickerFormatted}
              </Text>
            )}
          </Box>

          <TickerAndAmountInput
            focusAfterChangeTicker={false}
            wallets={sourceWallets}
            ticker={inputTicker}
            amount={inputAmount}
            onChangeTicker={setInputTicker}
            onChangeAmount={setInputAmount}
            setCaretPositionToEndOnFocus
          />
          {Boolean(inputAmountError) && (
            <Box mt={10}>
              <Text color="$danger-01" variant="$body-02">
                <ValidationMessageComponent error={inputAmountError} />
              </Text>
            </Box>
          )}
        </Box>

        <PredefinedAmountButtons
          data={predefinedAmountsData}
          currentAmount={inputAmount}
          onPressAmount={handlePressAmount}
          buttonsSize={buttonsSize}
          mt={8}
        />
      </Box>
    );
  }
);
