import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarSparks} from './Sparks';
import {invariant} from '@youtoken/ui.utils';

export type AccountLevelProgressBarFooterProps = {
  sparks?: number;
  progressBarLevels: LoyaltyProgressBarLevel[];
};

export const AccountLevelProgressBarFooter: React.FC<
  AccountLevelProgressBarFooterProps & BoxProps
> = observer(({sparks, progressBarLevels, ...boxProps}) => {
  const firstLevel = progressBarLevels[0]?.level;
  const lastLevel = progressBarLevels[1]?.level;

  invariant(firstLevel && lastLevel, 'Levels are not defined');

  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_FOOTER"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      minHeight={22}
      {...boxProps}
    >
      <Box flex={1} alignItems="flex-start">
        <Text
          testID="ACCOUNT_LEVEL_FORECAST_FIRST_LEVEL"
          variant="$body-03"
          color="$text-02"
          textAlign="left"
          m={2}
        >
          {firstLevel.name}
        </Text>
      </Box>
      {/* NOTE: NEED to show 0 and more, undefined is hidden */}
      {sparks !== undefined && sparks >= 0 && (
        <AccountLevelProgressBarSparks value={sparks} minWidth={180} mt={-4} />
      )}
      <Box flex={1} alignItems="flex-end">
        <Text
          testID="ACCOUNT_LEVEL_FORECAST_LAST_LEVEL"
          variant="$body-03"
          color="$text-02"
          textAlign="right"
          m={2}
        >
          {lastLevel.name}
        </Text>
      </Box>
    </Box>
  );
});
