import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const Level6Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill="#968FF6"
          d="M9.627 2.416a.5.5 0 0 1 .745 0l1.733 1.936a.5.5 0 0 0 .4.165l2.594-.143a.5.5 0 0 1 .527.527l-.143 2.594a.5.5 0 0 0 .165.4l1.936 1.732a.5.5 0 0 1 0 .745l-1.936 1.733a.5.5 0 0 0-.165.4l.143 2.594a.5.5 0 0 1-.527.527l-2.594-.143a.5.5 0 0 0-.4.165l-1.732 1.936a.5.5 0 0 1-.746 0l-1.732-1.936a.5.5 0 0 0-.4-.165l-2.594.143a.5.5 0 0 1-.527-.527l.143-2.594a.5.5 0 0 0-.165-.4l-1.936-1.732a.5.5 0 0 1 0-.746l1.936-1.732a.5.5 0 0 0 .165-.4L4.374 4.9a.5.5 0 0 1 .527-.527l2.594.143a.5.5 0 0 0 .4-.165z"
        />
        <Path
          fill="#C7ADFE"
          fillRule="evenodd"
          d="M10 4 8.3 5.898l-2.543-.14.14 2.543L4 10l1.898 1.7-.14 2.543 2.543-.141L10 16z"
          clipRule="evenodd"
        />
        <Path
          fill="#968FF6"
          d="m2.657 6.03.153-.46a.2.2 0 0 1 .38 0l.153.46a.2.2 0 0 0 .127.127l.46.153a.2.2 0 0 1 0 .38l-.46.153a.2.2 0 0 0-.127.127l-.153.46a.2.2 0 0 1-.38 0l-.153-.46a.2.2 0 0 0-.127-.127l-.46-.153a.2.2 0 0 1 0-.38l.46-.153a.2.2 0 0 0 .127-.127"
        />
        <Path
          fill="#C7ADFE"
          d="m12.532 10.405.278-.836a.2.2 0 0 1 .38 0l.278.836a.2.2 0 0 0 .127.127l.836.278a.2.2 0 0 1 0 .38l-.836.278a.2.2 0 0 0-.127.127l-.278.836a.2.2 0 0 1-.38 0l-.278-.836a.2.2 0 0 0-.127-.127l-.836-.278a.2.2 0 0 1 0-.38l.836-.278a.2.2 0 0 0 .127-.127"
        />
      </Svg>
    );
  }
);
