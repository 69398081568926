import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const LanguageIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          stroke="#70798B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.667}
          d="M3.333 4.167h5.834M7.5 2.5v1.667c0 3.681-1.866 6.666-4.167 6.666"
        />
        <Path
          stroke="#70798B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.667}
          d="M4.167 7.5c-.003 1.787 2.46 3.257 5.583 3.333M10.667 16.667 14 9.167l3.333 7.5M16.5 15h-5"
        />
      </Svg>
    );
  }
);
