import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const BonusesIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M13.5 7.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0m-6.434 2.889a4.5 4.5 0 0 1-1.001-.163 4 4 0 0 1-.565-.23l.29-.814q.275.137.66.249.393.11.89.11.59 0 .812-.17a.57.57 0 0 0 .231-.47.6.6 0 0 0-.094-.343.9.9 0 0 0-.265-.257 2.3 2.3 0 0 0-.41-.205 15 15 0 0 0-.514-.197 7 7 0 0 1-.53-.214 2.3 2.3 0 0 1-.471-.29 1.4 1.4 0 0 1-.334-.428 1.3 1.3 0 0 1-.128-.608q0-.59.35-1 .352-.42 1.079-.54v-.958h.89v.924q.41.034.735.12.325.076.514.154l-.214.847a5 5 0 0 0-.59-.18 3 3 0 0 0-.77-.086q-.463 0-.694.172a.52.52 0 0 0-.23.453q0 .171.068.29a.7.7 0 0 0 .205.206q.137.086.334.171.197.078.445.163.35.137.658.29a2.2 2.2 0 0 1 .548.352q.231.196.36.47.136.275.136.65 0 .565-.35.976-.351.41-1.156.53v1.07h-.89v-1.044zM15 7.5q0 .611-.095 1.196a4.5 4.5 0 1 1-6.21 6.21 7.6 7.6 0 0 1-1.657.08 6.001 6.001 0 1 0 7.948-7.948q.014.23.014.462"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
