import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const TradingIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M4.25 3c0 .414.336.75.75.75h3.19L1.97 9.97a.75.75 0 1 0 1.06 1.06l6.22-6.22V8a.75.75 0 0 0 1.5 0V3a.75.75 0 0 0-.75-.75H5a.75.75 0 0 0-.75.75M15.75 17a.75.75 0 0 0-.75-.75h-3.19l6.22-6.22a.75.75 0 1 0-1.06-1.06l-6.22 6.22V12a.75.75 0 0 0-1.5 0v5c0 .414.336.75.75.75h5a.75.75 0 0 0 .75-.75"
        />
      </Svg>
    );
  }
);
