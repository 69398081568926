import React from 'react';
import {Icon, IconPaymentMethod} from '@youtoken/ui.icons';
import {Box, TColorTokens, Text} from '@youtoken/ui.primitives';
import {PaymentSystem} from '@youtoken/ui.resource-bank-cards';
import {type BankCardType} from '../../types';
import {BaseBankCard} from '../BaseBankCard';

interface SmallBankCardProps {
  type: BankCardType;
  cardNumber?: string;
  paymentSystem?: PaymentSystem;
  secondaryColor?: keyof TColorTokens;
  testID?: string;
}

export const SmallBankCard: React.FC<SmallBankCardProps> = ({
  type,
  cardNumber,
  paymentSystem = PaymentSystem.VISA,
  testID,
}) => {
  const color = type === 'virtual' ? '$text-01' : '$text-04';

  const cardNumberFormatted = React.useMemo(() => {
    return cardNumber && /\d/g.test(cardNumber)
      ? `• ${cardNumber}`
      : cardNumber;
  }, [cardNumber]);

  const paymentSystemIcon =
    paymentSystem === PaymentSystem.MASTERCARD ? 'mastercard' : 'visa';

  return (
    <BaseBankCard type={type} size="small" testID={testID}>
      <Box height="100%" pt={3} px={5} justifyContent="flex-end">
        <Box
          flexDirection="row"
          flexGrow={1}
          alignItems="center"
          justifyContent="flex-end"
          height={10}
        >
          {type === 'virtual' && (
            <Box flexGrow={1}>
              <Icon name="cloud" color={color} size={10} />
            </Box>
          )}
          {paymentSystem && (
            <Box>
              <IconPaymentMethod
                name={paymentSystemIcon}
                color={color}
                size={16}
              />
            </Box>
          )}
        </Box>

        {Boolean(cardNumber) && (
          <Box flexGrow={1}>
            <Text variant="$body-03" color={color}>
              {cardNumberFormatted}
            </Text>
          </Box>
        )}
      </Box>
    </BaseBankCard>
  );
};
