import * as React from 'react';
import {Path} from 'react-native-svg';
import {type TColorTokens, useTheme} from '@youtoken/ui.primitives';

type BackgroundProps = {
  color: keyof TColorTokens;
};

export const Background: React.FC<BackgroundProps> = ({color}) => {
  const {colors} = useTheme();

  return (
    <Path
      d="M4.42623 16.5349L28.4262 2.28491C30.6292 0.976892 33.3708 0.976892 35.5738 2.28491L59.5738 16.5349C61.6979 17.7961 63 20.0836 63 22.5539V51.4461C63 53.9164 61.6979 56.2039 59.5738 57.4651L35.5738 71.7151C33.3708 73.0231 30.6292 73.0231 28.4262 71.7151L4.42623 57.4651C2.30209 56.2039 1 53.9164 1 51.4461V22.5539C1 20.0836 2.30209 17.7961 4.42623 16.5349Z"
      fill={colors[color]}
    />
  );
};
