import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const LocationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M11 10.9A5.002 5.002 0 0 0 10 1a5 5 0 0 0-1 9.9v7.6a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5zm2-6.4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
