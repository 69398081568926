import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FtpIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M15.454 4.875a2.5 2.5 0 1 1-.853 4.85l-.022.025-4.344 4.345a.75.75 0 0 1-.977.072l-.084-.072-1.97-1.81-3.375 3.374A.884.884 0 0 1 2.5 14.5l.08-.09 4.094-4.095a.75.75 0 0 1 .976-.073l.084.073 1.97 1.81 3.554-3.555a2.5 2.5 0 0 1 2.196-3.695m0 1.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2M10.25 6.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1 0-1.5zm-4.5 0a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1 0-1.5z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
