import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const HodlIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M3.9 3.6a.9.9 0 0 0-1.8 0V18a2.7 2.7 0 0 0 2.7 2.7H21a.9.9 0 0 0 0-1.8H4.8a.9.9 0 0 1-.9-.9zm10.8 1.8a.9.9 0 0 0 .9.9h2.627L13.8 10.727l-1.94-1.94a1.5 1.5 0 0 0-2.12 0l-3.776 3.777a.9.9 0 1 0 1.272 1.272l3.564-3.563 1.94 1.94a1.5 1.5 0 0 0 2.12 0l4.64-4.64V10.2a.9.9 0 0 0 1.8 0V5.4a.9.9 0 0 0-.9-.9h-4.8a.9.9 0 0 0-.9.9"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
