import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const VolumeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M4 5.667V8.25a.5.5 0 0 0 .692.462L9.808 6.58a.5.5 0 0 1 .384 0l5.116 2.132A.5.5 0 0 0 16 8.25V5.667a1 1 0 0 0-.615-.923L10.192 2.58a.5.5 0 0 0-.384 0L4.615 4.744A1 1 0 0 0 4 5.667m0 5.5v2.083a.5.5 0 0 0 .692.462l5.116-2.132a.5.5 0 0 1 .384 0l5.116 2.132A.5.5 0 0 0 16 13.25v-2.083a1 1 0 0 0-.615-.923L10.192 8.08a.5.5 0 0 0-.384 0l-5.193 2.164a1 1 0 0 0-.615.923m0 6.083v-1.083a1 1 0 0 1 .615-.923l5.193-2.164a.5.5 0 0 1 .384 0l5.193 2.164a1 1 0 0 1 .615.923v1.083a.5.5 0 0 1-.692.462l-5.116-2.132a.5.5 0 0 0-.384 0l-5.116 2.132A.5.5 0 0 1 4 17.25"
        />
      </Svg>
    );
  }
);
