import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const PassportIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M15 2.5H5a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5M5 1a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2z"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M7 15.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5M10 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8m1.527-1.417A3 3 0 0 0 12.96 8.5h-.974c-.05.788-.213 1.506-.458 2.083M10.982 8.5c-.054.767-.232 1.42-.462 1.881-.14.279-.28.45-.387.541a.4.4 0 0 1-.128.077L10 11h-.005l-.02-.007a.4.4 0 0 1-.108-.07c-.107-.092-.247-.263-.387-.542-.23-.46-.408-1.114-.462-1.881zm1.003-1h.974a3 3 0 0 0-1.432-2.083c.245.577.408 1.295.458 2.083M10 5h.005l.02.007c.019.008.056.027.108.07.108.092.248.263.387.542.23.46.408 1.114.462 1.881H9.018c.054-.767.232-1.42.462-1.881.14-.279.28-.45.387-.541A.4.4 0 0 1 9.995 5zm-1.527.417A3 3 0 0 0 7.04 7.5h.974c.05-.788.214-1.506.458-2.083M8.015 8.5h-.974a3 3 0 0 0 1.432 2.083c-.244-.577-.408-1.295-.458-2.083"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
