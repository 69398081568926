import React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  Notification as NotificationClass,
  NotificationPayloadLevel,
} from '@youtoken/ui.resource-notifications';
import {
  type TouchableBoxProps,
  TouchableBox,
  Box,
  Text,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {getData} from './utils';
import {NotificationIcon} from './Icon';

export interface NotificationProps {
  data: NotificationClass;
  onPressClose?: TouchableBoxProps['onPress'];
}

export const Notification: React.FC<NotificationProps & TouchableBoxProps> =
  observer(
    ({
      data: {
        type,
        payload,
        design,
        texts: {body, button},
        conditions: {canClose, url},
      },
      onPressClose,
      ...boxProps
    }) => {
      const designData = getData(
        type,
        payload as NotificationPayloadLevel,
        design
      );

      const handlePress = React.useCallback(() => {
        if (!url) {
          return;
        }

        const [name, params, query] = SHARED_ROUTER_SERVICE.urlToRoute(url);

        SHARED_ROUTER_SERVICE.navigate(name, params, query);
      }, [url]);

      const hasIcon = Boolean(designData?.iconName);
      const hasButton = Boolean(button && handlePress);
      const hasCloseButton = Boolean(canClose && onPressClose);

      return (
        <TouchableBox
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          px={12}
          py={8}
          mx={{default: 12, tablet: 0}}
          bg={designData?.bodyBackground}
          borderRadius={12}
          onPress={handlePress}
          {...boxProps}
        >
          <Box
            testID="NOTIFICATION_BODY"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
          >
            {hasIcon && (
              <Box flexShrink={0} mr={8}>
                <NotificationIcon
                  type={type}
                  name={designData?.iconName}
                  color={designData?.iconColor}
                />
              </Box>
            )}
            <Box flex={1}>
              <Text variant="$body-02" color={designData?.bodyColor}>
                {body}
              </Text>
            </Box>
          </Box>
          {(hasButton || hasCloseButton) && (
            <Box
              testID="NOTIFICATION_ACTIONS"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              ml={8}
              mr={-4}
            >
              {hasButton && (
                <Button
                  testID="NOTIFICATION_ACTIONS_MAIN_BUTTON"
                  type={designData?.buttonType}
                  color={designData?.buttonColor}
                  size="small"
                  maxWidth={85}
                  onPress={handlePress}
                >
                  {button}
                </Button>
              )}
              {hasCloseButton && (
                <TouchableBox
                  testID="NOTIFICATION_ACTIONS_CLOSE_BUTTON"
                  justifyContent="center"
                  alignItems="center"
                  width={32}
                  height={32}
                  mr={-4}
                  onPress={onPressClose}
                >
                  <Icon name="close" size={16} color={designData?.closeColor} />
                </TouchableBox>
              )}
            </Box>
          )}
        </TouchableBox>
      );
    }
  );
