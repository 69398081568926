import * as React from 'react';
import {
  withSequence,
  withTiming,
  withSpring,
  withDelay,
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
} from 'react-native-reanimated';

export const useHexGridItemStateBounceAnimation = (onFinished: () => void) => {
  const scale = useSharedValue(1);

  React.useEffect(() => {
    scale.value = withSequence(
      withSpring(1.5, {
        damping: 10,
        stiffness: 300,
        mass: 0.9,
        restSpeedThreshold: 0.001,
      }),
      withDelay(
        700,
        withSequence(
          withTiming(1.55, {
            duration: 175,
          }),
          withTiming(
            1,
            {
              duration: 175,
            },
            finished => {
              if (finished) {
                runOnJS(onFinished)();
              }
            }
          )
        )
      )
    );
  }, [onFinished]);

  return useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: scale.value,
        },
      ],
    };
  });
};
