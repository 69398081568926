import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const MessageIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2.25 9.5c0-3.497 3.504-6.25 7.75-6.25s7.75 2.753 7.75 6.25c0 3.399-3.317 6.108-7.435 6.245L7.53 18.53a.75.75 0 0 1-1.273-.427L6.25 18v-3.026l-.269-.125c-2.189-1.068-3.626-2.963-3.725-5.11zm14 0c0-2.578-2.764-4.75-6.25-4.75S3.75 6.922 3.75 9.5c0 1.805 1.363 3.466 3.513 4.268a.75.75 0 0 1 .487.703v1.717l1.72-1.718a.75.75 0 0 1 .404-.21l.115-.01.246-.003c3.385-.096 6.015-2.234 6.015-4.747"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
