import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const BackspaceIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          d="M14.03 6.97a.75.75 0 0 1 0 1.06L12.06 10l1.97 1.97a.75.75 0 1 1-1.06 1.06L11 11.06l-1.97 1.97a.75.75 0 0 1-1.06-1.06L9.94 10 7.97 8.03a.75.75 0 0 1 1.06-1.06L11 8.94l1.97-1.97a.75.75 0 0 1 1.06 0"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M17 3H5.972a2 2 0 0 0-1.573.764l-3.928 5a2 2 0 0 0 0 2.472l3.928 5A2 2 0 0 0 5.972 17H17a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 1.5H5.972a.5.5 0 0 0-.393.191l-3.929 5a.5.5 0 0 0 0 .618l3.929 5a.5.5 0 0 0 .393.191H17a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
