import * as React from 'react';
import {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
} from 'react-native-reanimated';
import {type MinerHexagon} from '@youtoken/ui.resources-miner';
import {HexUtils, useLayoutContext} from '../../../../../../../lib';
import {MinerOverviewResourceContext} from '../../../../../../../../../context';

export const useHexGridItemStateClaimingEndingAnimation = ({
  block: {id, coordinates},
}: MinerHexagon) => {
  const {
    hexagonalGrid: {showInfoEnd},
  } = React.useContext(MinerOverviewResourceContext).use({});

  const {layout} = useLayoutContext();

  const {x: x1, y: y1} = React.useMemo(() => {
    return HexUtils.hexToPixel(coordinates, layout);
  }, [coordinates, layout]);

  const tickerTranslateX = useSharedValue(0);
  const tickerTranslateY = useSharedValue(0);
  const opacity = useSharedValue(1);

  React.useEffect(() => {
    tickerTranslateX.value = withTiming(-x1, {
      duration: 300,
    });
    tickerTranslateY.value = withTiming(-y1, {
      duration: 300,
    });
    opacity.value = withTiming(
      0,
      {
        duration: 300,
      },
      finished => {
        if (finished) {
          runOnJS(showInfoEnd)(id);
        }
      }
    );
  }, [id, showInfoEnd]);

  return useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: tickerTranslateX.value,
        },
        {
          translateY: tickerTranslateY.value,
        },
      ],
      opacity: opacity.value,
    };
  }, []);
};
