import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage} from '@youtoken/ui.form-elements';
import {SubmitButton} from '@youtoken/ui.form-elements';
import type {FormContentProps} from '../types';

export const FormContent: React.FC<FormContentProps> = ({
  value,
  errorText,
  onSubmit,
  onChange,
  isLoading,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <TextInput
        autoFocus
        size="medium"
        placeholder={t('surface.2fa_form.code_placeholder')}
        value={value}
        onChangeText={onChange}
        hasError={Boolean(errorText)}
        maxLength={6}
        keyboardType="number-pad"
      />
      <FieldErrorMessage visible={Boolean(errorText)} placement="left">
        {errorText}
      </FieldErrorMessage>
      <SubmitButton onPress={onSubmit} mt={24} mb={24} loading={isLoading}>
        {t('surface.sign_in_confirm.continue')}
      </SubmitButton>
    </>
  );
};
