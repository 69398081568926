import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SecurityChangesIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M5 2.75A.75.75 0 0 1 5.75 2h9.5a.75.75 0 0 1 0 1.5h-9.5A.75.75 0 0 1 5 2.75m0 8a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75M2.75 10a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5zM5 6.75A.75.75 0 0 1 5.75 6h3.5a.75.75 0 0 1 0 1.5h-3.5A.75.75 0 0 1 5 6.75M2.75 2a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5zM2 6.75A.75.75 0 0 1 2.75 6h.5a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 2 6.75M15.5 9v1h-3V9a1.5 1.5 0 0 1 3 0m1.5 1V9a3 3 0 1 0-6 0v1a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2m-6.5 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm4.5 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
