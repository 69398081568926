import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const FiatWithdrawIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M32.5 25.5a1.5 1.5 0 0 1 1.486 1.296L34 27v4a4 4 0 0 1-3.701 3.989L30 35H10a4 4 0 0 1-3.989-3.701L6 31v-4l.014-.204a1.5 1.5 0 0 1 2.972 0L9 27v3.5a1.5 1.5 0 0 0 1.5 1.5h19a1.5 1.5 0 0 0 1.5-1.5V27l.014-.204A1.5 1.5 0 0 1 32.5 25.5M18.94 6.06a1.5 1.5 0 0 1 1.952-.145l.169.146L26 11c.497.497.547 1.272.15 1.825L26 13a1.415 1.415 0 0 1-1.825.15L24 13l-2.5-2.5v15a1.5 1.5 0 0 1-2.986.203L18.5 25.5v-15L16 13l-.175.15a1.415 1.415 0 0 1-1.974-1.975L14 11z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
