import * as React from 'react';
import {type HexGridItemProps} from '../../../../types';
import {FilledGroup, Background} from '../../elements';

export const HexGridItemStateDisabled: React.FC<HexGridItemProps> = () => {
  return (
    <FilledGroup testID="HEX_GRID_ITEM_STATE_DISABLED">
      <Background color="$ui-overflow" />
    </FilledGroup>
  );
};
