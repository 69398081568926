import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm, Label} from '@youtoken/ui.form-elements';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  AccountLevelProgressBarVariant,
  AccountLevelProgressWidgetV2,
} from '@youtoken/ui.surface-account-levels';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const AccountLevelSection: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {volumeForecast, sparksAmount},
  } = useForm<CreateHODLFormClass>();

  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Label>{t('surface.hodls.hodl_form.account_level.title')}</Label>

      <AccountLevelProgressWidgetV2
        variant={AccountLevelProgressBarVariant.HODL}
        volume={volumeForecast}
        sparks={sparksAmount}
        mt={8}
      />
    </Box>
  );
});
